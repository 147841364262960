<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import exportTxt from "../services/exportTxt"
  import { verifySession } from "../services/verifySession"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  let cliente = "persona"
  let tipo = "numeros"
  let formato = "vertical"
  let disponible = "0"
  let temp_archivo = []
  let statusMasivo = false
  let tipo_tope = "diario"

  const personas = [
    {
      key: "numeros",
      title: "Números",
      format: ["vertical", "horizontal"],
    },
    {
      key: "numeros_diferentes",
      title: "Números diferentes",
      format: ["vertical", "horizontal"],
    },
    {
      key: "direcciones",
      title: "Direcciones",
      format: ["vertical"],
    },
    {
      key: "correos",
      title: "Correos",
      format: ["vertical"],
    },
    {
      key: "laboral",
      title: "Laboral",
      format: ["vertical"],
    },
    {
      key: "RCC",
      title: "RCC",
      format: ["vertical"],
    },
    {
      key: "endeudamiento",
      title: "Endeudamiento",
      format: ["vertical"],
    },
    {
      key: "representantes_legales",
      title: "Representantes legales",
      format: ["vertical"],
    },
    {
      key: "deuda_entidad",
      title: "Deuda por entidad",
      format: ["vertical"],
    },
  ]

  const empresas = [
    {
      key: "numeros",
      title: "Números",
      format: ["vertical", "horizontal"],
    },
    {
      key: "numeros_diferentes",
      title: "Números diferentes",
      format: ["vertical", "horizontal"],
    },
    {
      key: "direcciones",
      title: "Direcciones",
      format: ["vertical"],
    },
    {
      key: "correos",
      title: "Correos",
      format: ["vertical"],
    },
    {
      key: "RCC",
      title: "RCC",
      format: ["vertical"],
    },
    {
      key: "endeudamiento",
      title: "Endeudamiento",
      format: ["vertical"],
    },
    {
      key: "representantes_legales",
      title: "Representantes legales",
      format: ["vertical"],
    },
  ]

  const getFormat = (cliente, key) => {
    let format = ["vertical"]
    cliente.forEach((item) => {
      if (item.key === key) {
        format = item.format
      }
    })
    return format
  }

  //Descargar archivos
  const personaNumerosVertical = (content) => {
    let texto = []
    texto.push(
      "Documento" +
        "\t" +
        "Nombre_Completo" +
        "\t" +
        "Fecha_Nacimiento" +
        "\t" +
        "Sexo" +
        "\t" +
        "Estado_Civil" +
        "\t" +
        "Departamento" +
        "\t" +
        "Provincia" +
        "\t" +
        "Distrito" +
        "\t" +
        "Departamento_Nacimiento" +
        "\t" +
        "Provincia_Nacimiento" +
        "\t" +
        "Distrito_Nacimiento" +
        "\t" +
        "Fecha_Actualizacion" +
        "\t" +
        "Telefono" +
        "\t" +
        "Fuente" +
        "\t" +
        "Plan" +
        "\t" +
        "Obs",
    )
    for (let d of content) {
      texto.push(
        "\n" +
          d.documento +
          "\t" +
          d.nombre_completo +
          "\t" +
          d.nacimiento +
          "\t" +
          d.sexo +
          "\t" +
          d.est_civil +
          "\t" +
          d.departamento +
          "\t" +
          d.provincia +
          "\t" +
          d.distrito +
          "\t" +
          d.nac_departamento +
          "\t" +
          d.nac_provincia +
          "\t" +
          d.nac_distrito +
          "\t" +
          d.fecha_actualizacion +
          "\t" +
          d.codigo +
          "\t" +
          d.empresa +
          "\t" +
          d.plan +
          "\t" +
          d.obs,
      )
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "persona_numeros_vertical")
  }

  const personaNumerosHorizontal = (content) => {
    let texto = []
    texto.push(
      "Documento" +
        "\t" +
        "Nombre_Completo" +
        "\t" +
        "Fecha_Nacimiento" +
        "\t" +
        "Sexo" +
        "\t" +
        "Estado_Civil" +
        "\t" +
        "Departamento" +
        "\t" +
        "Provincia" +
        "\t" +
        "Distrito" +
        "\t" +
        "Departamento_Nacimiento" +
        "\t" +
        "Provincia_Nacimiento" +
        "\t" +
        "Distrito_Nacimiento" +
        "\t" +
        "Numero1" +
        "\t" +
        "Numero2" +
        "\t" +
        "Numero3" +
        "\t" +
        "Numero4" +
        "\t" +
        "Numero5" +
        "\t" +
        "Numero6" +
        "\t" +
        "Numero7" +
        "\t" +
        "Numero8" +
        "\t" +
        "Numero9" +
        "\t" +
        "Numero10" +
        "\t" +
        "Fijo1" +
        "\t" +
        "Fijo2",
    )

    let linea = ""
    let cont_cel = 1
    let cont_fijo = 1

    // let ordenado = content.map((element) => {
    //   return {
    //     documento: element.documento,
    //     nombre_completo: element.nombre_completo,
    //     nacimiento: element.nacimiento,
    //     departamento: element.departamento,
    //     provincia: element.provincia,
    //     distrito: element.distrito,
    //     celulares:
    //       content
    //         .filter((x) => x.documento == element.documento && x.tipo === "C")
    //         .map((x) => x.codigo) || [],
    //     fijos:
    //       content
    //         .filter((x) => x.documento == element.documento && x.tipo === "F")
    //         .map((x) => x.codigo) || [],
    //   }
    // })

    let ordenado = content.reduce((acc, current) => {
      const foundItem = acc.find((it) => it.documento === current.documento)

      if (foundItem) {
        if (current.tipo == "C") {
          foundItem.celulares = foundItem.celulares ? [...foundItem.celulares, current.codigo] : [current.codigo]
        } else if (current.tipo == "F") {
          foundItem.fijos = foundItem.fijos ? [...foundItem.fijos, current.codigo] : [current.codigo]
        }
      } else {
        acc.push({
          documento: current.documento,
          nombre_completo: current.nombre_completo,
          nacimiento: current.nacimiento,
          sexo: current.sexo,
          est_civil: current.est_civil,
          departamento: current.departamento,
          provincia: current.provincia,
          distrito: current.distrito,
          nac_departamento: current.nac_departamento,
          nac_provincia: current.nac_provincia,
          nac_distrito: current.nac_distrito,
          celulares: current.tipo == "C" ? [current.codigo] : [],
          fijos: current.tipo == "F" ? [current.codigo] : [],
        })
      }
      return acc
    }, [])

    let hash = {}
    ordenado = ordenado.filter((o) => (hash[o.documento] ? false : (hash[o.documento] = true)))

    let ordenado_final = []
    for (let o of ordenado) {
      ordenado_final.push({
        documento: o.documento,
        nombre_completo: o.nombre_completo,
        nacimiento: o.nacimiento,
        sexo: o.sexo,
        est_civil: o.est_civil,
        departamento: o.departamento,
        provincia: o.provincia,
        distrito: o.distrito,
        nac_departamento: o.nac_departamento,
        nac_provincia: o.nac_provincia,
        nac_distrito: o.nac_distrito,
        celulares: o.celulares.concat(Array(10 - limitCelulares(o.celulares.length)).fill("")),
        fijos: o.fijos.concat(Array(2 - limitFijos(o.fijos.length)).fill("")),
      })
    }

    for (let d of ordenado_final) {
      linea = ""
      cont_cel = 1
      cont_fijo = 1
      linea +=
        "\n" +
        d.documento +
        "\t" +
        d.nombre_completo +
        "\t" +
        d.nacimiento +
        "\t" +
        d.sexo +
        "\t" +
        d.est_civil +
        "\t" +
        d.departamento +
        "\t" +
        d.provincia +
        "\t" +
        d.distrito +
        "\t" +
        d.nac_departamento +
        "\t" +
        d.nac_provincia +
        "\t" +
        d.nac_distrito
      for (let c of d.celulares) {
        if (cont_cel <= 10) {
          linea += "\t" + c
        }
        cont_cel++
      }
      for (let f of d.fijos) {
        if (cont_fijo <= 2) {
          linea += "\t" + f
        }
        cont_fijo++
      }
      texto.push(linea)
    }

    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "persona_numeros_horizontal")
  }

  const limitCelulares = (length) => {
    if (length > 10) {
      return 10
    }
    return length
  }

  const limitFijos = (length) => {
    if (length > 2) {
      return 2
    }
    return length
  }

  const empresaNumerosVertical = (content) => {
    let texto = []
    texto.push("RUC" + "\t" + "Razon_Social" + "\t" + "Fecha_Actualizacion" + "\t" + "Telefono" + "\t" + "Fuente" + "\t" + "Plan" + "\t" + "Obs")
    for (let d of content) {
      texto.push("\n" + d.documento + "\t" + d.razonsocial + "\t" + d.fecha_actualizacion + "\t" + d.codigo + "\t" + d.empresa + "\t" + d.plan + "\t" + d.obs)
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "empresa_numeros_vertical")
  }

  const empresaNumerosHorizontal = (content) => {
    let texto = []
    texto.push(
      "RUC" +
        "\t" +
        "Razon_Social" +
        "\t" +
        "Numero1" +
        "\t" +
        "Numero2" +
        "\t" +
        "Numero3" +
        "\t" +
        "Numero4" +
        "\t" +
        "Numero5" +
        "\t" +
        "Numero6" +
        "\t" +
        "Numero7" +
        "\t" +
        "Numero8" +
        "\t" +
        "Numero9" +
        "\t" +
        "Numero10" +
        "\t" +
        "Fijo1" +
        "\t" +
        "Fijo2",
    )

    let linea = ""
    let cont_cel = 1
    let cont_fijo = 1

    let ordenado = content.reduce((acc, current) => {
      const foundItem = acc.find((it) => it.documento === current.documento)

      if (foundItem) {
        if (current.tipo == "C") {
          foundItem.celulares = foundItem.celulares ? [...foundItem.celulares, current.codigo] : [current.codigo]
        } else if (current.tipo == "F") {
          foundItem.fijos = foundItem.fijos ? [...foundItem.fijos, current.codigo] : [current.codigo]
        }
      } else {
        acc.push({
          documento: current.documento,
          razonsocial: current.razonsocial,
          celulares: current.tipo == "C" ? [current.codigo] : [],
          fijos: current.tipo == "F" ? [current.codigo] : [],
        })
      }
      return acc
    }, [])

    let hash = {}
    ordenado = ordenado.filter((o) => (hash[o.documento] ? false : (hash[o.documento] = true)))

    let ordenado_final = []
    for (let o of ordenado) {
      ordenado_final.push({
        documento: o.documento,
        razonsocial: o.razonsocial,
        celulares: o.celulares.concat(Array(10 - limitCelulares(o.celulares.length)).fill("")),
        fijos: o.fijos.concat(Array(2 - limitFijos(o.fijos.length)).fill("")),
      })
    }

    for (let d of ordenado_final) {
      linea = ""
      cont_cel = 1
      cont_fijo = 1
      linea += "\n" + d.documento + "\t" + d.razonsocial

      for (let c of d.celulares) {
        if (cont_cel <= 10) {
          linea += "\t" + c
        }
        cont_cel++
      }
      for (let f of d.fijos) {
        if (cont_fijo <= 2) {
          linea += "\t" + f
        }
        cont_fijo++
      }
      texto.push(linea)
    }

    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "empresa_numeros_horizontal")
  }

  const personaDirecciones = (content) => {
    let texto = []
    texto.push("documento" + "\t" + "direccion" + "\t" + "distrito" + "\t" + "provincia" + "\t" + "departamento" + "\t" + "fecha" + "\t" + "tipo")
    for (let d of content) {
      texto.push("\n" + d.documento + "\t" + d.direccion + "\t" + d.distrito + "\t" + d.provincia + "\t" + d.departamento + "\t" + d.fecha + "\t" + d.tipo)
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "persona_direcciones")
  }

  const empresaDirecciones = (content) => {
    let texto = []
    texto.push("RUC" + "\t" + "direccion" + "\t" + "distrito" + "\t" + "provincia" + "\t" + "departamento" + "\t" + "fecha" + "\t" + "tipo")
    for (let d of content) {
      texto.push("\n" + d.ruc + "\t" + d.direccion + "\t" + d.distrito + "\t" + d.provincia + "\t" + d.departamento + "\t" + d.fecha + "\t" + d.tipo)
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "empresa_direcciones")
  }

  const personaCorreros = (content) => {
    let texto = []
    texto.push("documento" + "\t" + "correo")
    for (let d of content) {
      texto.push("\n" + d.documento + "\t" + d.correo)
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "persona_correos")
  }

  const empresaCorreros = (content) => {
    let texto = []
    texto.push("RUC" + "\t" + "correo")
    for (let d of content) {
      texto.push("\n" + d.ruc + "\t" + d.correo)
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "empresa_correos")
  }

  const personaRCC = (content) => {
    let texto = []
    texto.push("documento" + "\t" + "fecha" + "\t" + "entidades" + "\t" + "NOR" + "\t" + "CPP" + "\t" + "DEF" + "\t" + "DUD" + "\t" + "PER")
    for (let d of content) {
      let fecha = ""
      if (d.fechareporte != "") {
        fecha = d.fechareporte.substr(0, 4) + "-" + d.fechareporte.substr(4, 2) + "-" + d.fechareporte.substr(6, 2)
      }
      texto.push(
        "\n" +
          d.documento +
          "\t" +
          fecha +
          "\t" +
          d.cantidadempresas +
          "\t" +
          d.calificacion_0 +
          "\t" +
          d.calificacion_1 +
          "\t" +
          d.calificacion_2 +
          "\t" +
          d.calificacion_3 +
          "\t" +
          d.calificacion_4,
      )
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "persona_RCC")
  }

  const empresaRCC = (content) => {
    let texto = []
    texto.push("RUC" + "\t" + "fecha" + "\t" + "entidades" + "\t" + "NOR" + "\t" + "CPP" + "\t" + "DEF" + "\t" + "DUD" + "\t" + "PER")
    for (let d of content) {
      let fecha = ""
      if (d.fechareporte != "") {
        fecha = d.fechareporte.substr(0, 4) + "-" + d.fechareporte.substr(4, 2) + "-" + d.fechareporte.substr(6, 2)
      }
      texto.push(
        "\n" +
          d.documento +
          "\t" +
          fecha +
          "\t" +
          d.cantidadempresas +
          "\t" +
          d.calificacion_0 +
          "\t" +
          d.calificacion_1 +
          "\t" +
          d.calificacion_2 +
          "\t" +
          d.calificacion_3 +
          "\t" +
          d.calificacion_4,
      )
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "empresa_RCC")
  }

  const personaLaboral = (content) => {
    let texto = []
    texto.push(
      "documento" +
        "\t" +
        "ruc" +
        "\t" +
        "razonsocial" +
        "\t" +
        "fecha" +
        "\t" +
        "departamento" +
        "\t" +
        "provincia" +
        "\t" +
        "distrito" +
        "\t" +
        "direccion" +
        "\t" +
        "p1" +
        "\t" +
        "telefono1" +
        "\t" +
        "telefono2" +
        "\t" +
        "telefono3" +
        "\t" +
        "telefono4",
    )

    let documento_laboral = "00000000-00000000"
    let linea = ""
    let i = 0
    for (let d of content) {
      let tmp_documento_laboral = `${d.documento}-${d.ruc}`
      if (documento_laboral != tmp_documento_laboral) {
        documento_laboral = `${d.documento}-${d.ruc}`
        i = 0
        linea +=
          "\n" +
          d.documento +
          "\t" +
          d.ruc +
          "\t" +
          d.razonsocial +
          "\t" +
          d.fecha +
          "\t" +
          (d.departamento == null ? "" : d.departamento) +
          "\t" +
          (d.provincia == null ? "" : d.provincia) +
          "\t" +
          (d.distrito == null ? "" : d.distrito) +
          "\t" +
          (d.direccion == null ? "" : d.direccion) +
          "\t" +
          d.sueldo +
          "\t" +
          (d.codigo == null ? "" : d.codigo)
      } else {
        if (i < 3) {
          linea += "\t" + d.codigo
        }
        i++
      }
    }

    texto.push(linea)

    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "persona_laboral")
  }

  const personaEndeudamiento = (content) => {
    let texto = []
    texto.push(
      "documento" +
        "\t" +
        "nombre_completo" +
        "\t" +
        "nacimiento" +
        "\t" +
        "total_rcc" +
        "\t" +
        "linea_aprobada_tc" +
        "\t" +
        "linea_utilizada_tc" +
        "\t" +
        "linea_disponible_tc" +
        "\t" +
        "deuda_tc" +
        "\t" +
        "vehicular" +
        "\t" +
        "hipotecario" +
        "\t" +
        "comercial" +
        "\t" +
        "prestamo" +
        "\t" +
        "convenio" +
        "\t" +
        "otros" +
        "\t" +
        "ruc_empleador" +
        "\t" +
        "nombre_empleador" +
        "\t" +
        "p1",
    )
    for (let d of content) {
      texto.push(
        "\n" +
          d.documento +
          "\t" +
          d.nombre_completo +
          "\t" +
          d.nacimiento +
          "\t" +
          d.total_rcc +
          "\t" +
          d.linea_aprobada_tc +
          "\t" +
          d.linea_utilizada_tc +
          "\t" +
          d.linea_disponible_tc +
          "\t" +
          d.deuda_tc +
          "\t" +
          d.vehicular +
          "\t" +
          d.hipotecario +
          "\t" +
          d.comercial +
          "\t" +
          d.prestamo +
          "\t" +
          d.convenio +
          "\t" +
          d.otros +
          "\t" +
          d.ruc +
          "\t" +
          d.razonsocial +
          "\t" +
          d.ingreso,
      )
    }

    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "persona_endeudamiento")
  }

  const empresaEndeudamiento = (content) => {
    let texto = []
    texto.push("RUC" + "\t" + "razonsocial" + "\t" + "total_deuda" + "\t" + "total_disponible")

    for (let d of content) {
      let total_deuda = 0.0
      let total_disponible = 0.0
      if (d.total_deuda != null) {
        total_deuda = d.total_deuda
      }
      if (d.total_disponible != null) {
        total_disponible = d.total_disponible
      }
      texto.push("\n" + d.documento + "\t" + d.razonsocial + "\t" + total_deuda + "\t" + total_disponible)
    }

    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "empresa_endeudamiento")
  }

  const personaRepresentantesLegales = (content) => {
    let texto = []
    texto.push(
      "tipo_documento" +
        "\t" +
        "documento" +
        "\t" +
        "nombres" +
        "\t" +
        "cargo" +
        "\t" +
        "fecha_desde" +
        "\t" +
        "ruc" +
        "\t" +
        "razon_social" +
        "\t" +
        "estado" +
        "\t" +
        "ubigeo" +
        "\t" +
        "departamento" +
        "\t" +
        "provincia" +
        "\t" +
        "distrito" +
        "\t" +
        "giro",
    )
    for (let d of content) {
      texto.push(
        "\n" +
          d.tipo_documento +
          "\t" +
          d.documento +
          "\t" +
          d.nombres +
          "\t" +
          d.cargo +
          "\t" +
          d.fecha_desde +
          "\t" +
          d.ruc +
          "\t" +
          d.razonsocial +
          "\t" +
          d.estado +
          "\t" +
          d.ubigeo +
          "\t" +
          d.departamento +
          "\t" +
          d.provincia +
          "\t" +
          d.distrito +
          "\t" +
          d.giro,
      )
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "persona_representantes_legales")
  }

  const empresaRepresentantesLegales = (content) => {
    let texto = []
    texto.push(
      "tipo_documento" +
        "\t" +
        "documento" +
        "\t" +
        "nombres" +
        "\t" +
        "cargo" +
        "\t" +
        "fecha_desde" +
        "\t" +
        "ruc" +
        "\t" +
        "razon_social" +
        "\t" +
        "estado" +
        "\t" +
        "ubigeo" +
        "\t" +
        "departamento" +
        "\t" +
        "provincia" +
        "\t" +
        "distrito" +
        "\t" +
        "giro",
    )
    for (let d of content) {
      texto.push(
        "\n" +
          d.tipo_documento +
          "\t" +
          d.documento +
          "\t" +
          d.nombres +
          "\t" +
          d.cargo +
          "\t" +
          d.fecha_desde +
          "\t" +
          d.ruc +
          "\t" +
          d.razonsocial +
          "\t" +
          d.estado +
          "\t" +
          d.ubigeo +
          "\t" +
          d.departamento +
          "\t" +
          d.provincia +
          "\t" +
          d.distrito +
          "\t" +
          d.giro,
      )
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "empresa_representantes_legales")
  }

  const personaDeudaEntidad = (content) => {
    let texto = []
    texto.push("documento" + "\t" + "entidad" + "\t" + "tipo_credito" + "\t" + "calificacion" + "\t" + "monto" + "\t" + "dias_atraso" + "\t" + "fecha")
    for (let d of content) {
      texto.push("\n" + d.documento + "\t" + d.empresa + "\t" + d.tipo_credito + "\t" + d.calificacion + "\t" + d.monto + "\t" + d.dias_atraso + "\t" + d.fecha)
    }
    const txt = new Blob(texto, {
      type: "text/plain",
    })
    exportTxt(txt, "persona_deudas_entidad")
  }

  const getPuntos = async () => {
    const response = await fetch(`${BASE_URL}session.php?session_key=${$user.session_key}`)
    const resp = await response.json()

    if (resp.status === "success") {
      let temp_user = $user
      temp_user.consumo = resp.data.consumo
      temp_user.consultas = resp.data.consultas
      temp_user.inicio = resp.data.inicio
      temp_user.fin = resp.data.fin
      user.setUser(temp_user)
    } else {
      navigate("/", { replace: true })
    }
  }

  const getMasivo = async () => {
    reiniciarTiempo()
    statusMasivo = true

    let data = {
      session_key: $user.session_key,
      tipo: tipo,
      documentos: temp_archivo,
      cliente: cliente,
    }

    let url = `${BASE_URL}masivo.php`

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "aplication/json",
      },
    })

    const resp = await response.json()

    if (resp.status === "ok") {
      if (cliente === "persona") {
        switch (tipo) {
          case "numeros":
            if (formato === "vertical") {
              personaNumerosVertical(resp.data)
            } else {
              personaNumerosHorizontal(resp.data)
            }
            break
          case "numeros_diferentes":
            if (formato === "vertical") {
              personaNumerosVertical(resp.data)
            } else {
              personaNumerosHorizontal(resp.data)
            }
            break
          case "direcciones":
            personaDirecciones(resp.data)
            break
          case "correos":
            personaCorreros(resp.data)
            break
          case "laboral":
            personaLaboral(resp.data)
            break
          case "RCC":
            personaRCC(resp.data)
            break
          case "endeudamiento":
            personaEndeudamiento(resp.data)
            break
          case "representantes_legales":
            personaRepresentantesLegales(resp.data)
            break
          case "deuda_entidad":
            personaDeudaEntidad(resp.data)
            break
          default:
            break
        }
      } else {
        //empresa
        switch (tipo) {
          case "numeros":
            if (formato === "vertical") {
              empresaNumerosVertical(resp.data)
            } else {
              empresaNumerosHorizontal(resp.data)
            }
            break
          case "numeros_diferentes":
            if (formato === "vertical") {
              empresaNumerosVertical(resp.data)
            } else {
              empresaNumerosHorizontal(resp.data)
            }
            break
          case "direcciones":
            empresaDirecciones(resp.data)
            break
          case "correos":
            empresaCorreros(resp.data)
            break
          case "RCC":
            empresaRCC(resp.data)
            break
          case "endeudamiento":
            empresaEndeudamiento(resp.data)
            break
          case "representantes_legales":
            empresaRepresentantesLegales(resp.data)
            break
          default:
            break
        }
      }
    } else {
      alert(resp.message)
    }

    getPuntos()
    statusMasivo = false
    getTope()
    reiniciarTiempo()
  }

  const getTope = async () => {
    let tipo_cliente = "P"
    if (cliente === "empresa") {
      tipo_cliente = "E"
    }
    const response = await fetch(`${BASE_URL}consulta.php?session_key=${$user.session_key}&tipo=${tipo}&tipo_cliente=${tipo_cliente}`)

    const resp = await response.json()

    if (resp.status === "ok") {
      disponible = Number(resp.data.disponible).toLocaleString("es-PE")
      tipo_tope = resp.data.tipo == "M" ? "mensual" : "diario"
    } else {
      disponible = "0"
      tipo_tope = "diario"
    }
  }

  const setCliente = () => {
    if (cliente === "persona") {
      tipo = personas[0].key
    } else {
      tipo = empresas[0].key
    }
    getTope()
  }

  //Leer documentos
  const leerArchivo = (e) => {
    reiniciarTiempo()
    temp_archivo = []
    let tmp_txt = []
    let archivo = e.target.files[0]
    let lector = new FileReader()
    if (!archivo) {
      return
    }
    lector.onload = (e) => {
      let contenido = e.target.result
      //contenido = contenido.split("\r\n")
      contenido = contenido.split(/\r?\n/)
      contenido.map(function (item) {
        let tabs = item.split("\t")
        let tabs_num = tabs[1]
        if (!tabs_num) {
          tabs_num = ""
        }
        let col = {
          documento: tabs[0],
          numero: tabs_num,
        }
        tmp_txt.push(col)
      })
      const dataArr = new Set(tmp_txt)
      temp_archivo = [...dataArr]
    }
    lector.readAsText(archivo)
  }

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
      if (!$user.permisos.includes("masivo")) {
        user.setUser(null)
        navigate("/", { replace: true })
      }
      getPuntos()
      getTope()

      //Pass
      if ($user.actualizar_clave == "1") {
        navigate("/seguridad", { replace: true })
      }
    }
  })

  // Tiempo de sessión
  let original = 30 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />

    <div class="container-fluid py-3 bg-light">
      <div class="card shadow-sm bg-white mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 mb-3">
              <label for="cliente" class="form-label small text-secondary">Cliente</label>
              <select id="cliente" class="form-select form-select-sm" bind:value={cliente} on:change={setCliente}>
                <option value="persona">Personas</option>
                <option value="empresa">Empresas</option>
              </select>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 mb-3">
              <label for="tipo" class="form-label small text-secondary">Tipo</label>
              <select id="tipo" class="form-select form-select-sm" bind:value={tipo} on:change={getTope}>
                {#if cliente === "persona"}
                  {#each personas as item}
                    <option value={item.key}>{item.title}</option>
                  {/each}
                {:else}
                  {#each empresas as item}
                    <option value={item.key}>{item.title}</option>
                  {/each}
                {/if}
              </select>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 mb-3">
              <label for="formato" class="form-label small text-secondary">Formato</label>
              <select id="formato" class="form-select form-select-sm" bind:value={formato}>
                {#if cliente === "persona"}
                  {#each getFormat(personas, tipo) as item}
                    <option value={item}>{item}</option>
                  {/each}
                {:else}
                  {#each getFormat(empresas, tipo) as item}
                    <option value={item}>{item}</option>
                  {/each}
                {/if}
              </select>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 mb-3">
              <label for="tope" class="form-label small text-secondary">Tope {tipo_tope}</label>
              <input id="tope" class="form-control form-control-sm text-end" type="text" disabled bind:value={disponible} />
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <input class="form-control form-control-sm" type="file" id="formFile" on:change={(e) => leerArchivo(e)} />
            </div>
            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <button class="btn btn-primary btn-sm {statusMasivo ? 'disabled' : ''}" on:click={getMasivo}>
                {#if statusMasivo}
                  Procesando...
                {:else}
                  Procesar
                {/if}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-sm bg-white">
        <div class="card-header">Nota</div>
        <div class="card-body small">
          <ul>
            <li>El archivo a procesar debe contener las siguientes características:</li>
            <ul>
              <li>La extensión del archivo debe ser <b>.txt/.csv</b></li>
              <li>
                Los documentos deben ser ingresados en una sola columna, solo para el tipo <b>Números diferentes</b> dos columnas.
              </li>
              <li>El archivo no debe contener duplicados ni espacios en blanco.</li>
              <li>El archivo .txt/.csv debe estar delimitado por tabulaciones.</li>
            </ul>

            <li>Las consultas serán descontadas de su paquete mensual disponible.</li>

            <li>
              Para la opción <b>Endeudamiento</b> se recomienda procesar como máximo 30,000 registros por cada vez.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <Footer />
  </main>
{/if}
