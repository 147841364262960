<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import Deudas from "../components/persona/Deudas.svelte"
  import General from "../components/persona/General.svelte"
  import Historico from "../components/persona/Historico.svelte"
  import Laboral from "../components/persona/Laboral.svelte"
  import Otros from "../components/persona/Otros.svelte"
  import Spinner from "../components/Spinner.svelte"
  import { verifySession } from "../services/verifySession"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  export let documento

  let nombres = ""

  let estadoInicial = 0
  let loading = true

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      //Documentos No permitidos por empresas
      if ($user.id_cliente == "28") {
        const documentosB = ["40887547", "40127983", "41528564", "41980112", "40500254"]
        if (documentosB.includes(documento)) {
          navigate("/persona", { replace: true })
        }
      }

      //vencimiento de sesion
      verifySession($user.session_key)
      getPuntos()
      let consultas = $user.consultas - $user.consumo
      if (consultas > 0) {
        registrar()
      } else {
        navigate("/inicio", { replace: true })
      }
      if (!$user.permisos.includes("persona")) {
        user.setUser(null)
        navigate("/", { replace: true })
      }

      //Pass
      if ($user.actualizar_clave == "1") {
        navigate("/seguridad", { replace: true })
      }
    }
  })

  const registrar = async () => {
    let formData = new FormData()
    formData.append("documento", documento)
    formData.append("tipo", "persona")
    formData.append("session_key", $user.session_key)

    const response = await fetch(`${BASE_URL}consulta.php`, {
      method: "POST",
      body: formData,
    })

    const resp = await response.json()
    if (resp.status == "error") {
      user.setUser(null)
      navigate("/", { replace: true })
    }

    nombres = resp.data.nombres

    loading = false
    getPuntos()
  }

  $: {
    actualizarPagina(documento)
  }

  const actualizarPagina = (doc) => {
    if (estadoInicial > 0) {
      document.location.reload()
    }
    estadoInicial++
  }

  const getPuntos = async () => {
    let url = `${BASE_URL}session.php?session_key=${$user.session_key}`
    const response = await fetch(url)
    const resp = await response.json()

    if (resp.status === "success") {
      let temp_user = $user
      temp_user.consumo = resp.data.consumo
      temp_user.consultas = resp.data.consultas
      temp_user.inicio = resp.data.inicio
      temp_user.fin = resp.data.fin
      user.setUser(temp_user)
    } else {
      navigate("/", { replace: true })
    }
  }

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />
    {#if loading}
      <div class="p-3">
        <Spinner />
      </div>
    {:else}
      <div class="container-fluid bg-light my-0 text-end">
        <p class="small my-1">
          <span class="text-secondary">Documento: </span>
          {documento}
          <span class="text-secondary"> - </span>
          {nombres}
        </p>
      </div>
      <div class="container-fluid pb-3 bg-light">
        <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-general-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-general"
              type="button"
              role="tab"
              aria-controls="pills-general"
              aria-selected="true">Información General</button
            >
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-laboral-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-laboral"
              type="button"
              role="tab"
              aria-controls="pills-laboral"
              aria-selected="false">Datos Laborales</button
            >
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-historico-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-historico"
              type="button"
              role="tab"
              aria-controls="pills-historico"
              aria-selected="false">Histórico</button
            >
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-deudas-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-deudas"
              type="button"
              role="tab"
              aria-controls="pills-deudas"
              aria-selected="false">Deudas</button
            >
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-otros-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-otros"
              type="button"
              role="tab"
              aria-controls="pills-otros"
              aria-selected="false">Otros</button
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
            <General {documento} />
          </div>
          <div class="tab-pane fade" id="pills-laboral" role="tabpanel" aria-labelledby="pills-laboral-tab">
            <Laboral {documento} />
          </div>
          <div class="tab-pane fade" id="pills-historico" role="tabpanel" aria-labelledby="pills-historico-tab">
            <Historico {documento} />
          </div>
          <div class="tab-pane fade" id="pills-deudas" role="tabpanel" aria-labelledby="pills-deudas-tab">
            <Deudas {documento} />
          </div>
          <div class="tab-pane fade" id="pills-otros" role="tabpanel" aria-labelledby="pills-otros-tab">
            <Otros {documento} />
          </div>
        </div>
      </div>
    {/if}
  </main>
  <Footer />
{/if}
