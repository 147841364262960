<script>
  import uuid from "uuid-v4"
  import Accordion from "../Accordion.svelte"
  import PlanillaDatos from "./Planilla_datos.svelte"

  export let ruc

  let load = {
    planilla: false,
  }

  const click = (ops) => {
    switch (ops) {
      case "planilla":
        load.planilla = true
        break
      default:
        break
    }
  }
</script>

<div>
  <Accordion title="Planilla" idx={uuid()} on:click={() => click("planilla")}>
    {#if load.planilla}
      <PlanillaDatos {ruc} />
    {/if}
  </Accordion>
</div>
