<script>
  import { user } from "../stores/User"
  import Logo from "../images/Logo.svelte"
</script>

<header class="bg-light container-fluid">
  <div class="row">
    <div class="col-12 col-sm-6">
      <div class="d-flex">
        <div class="pt-1 pe-2">
          <Logo />
        </div>
        <div>
          <p class="fs-5 fw-bold mb-0">SBI</p>
          <p class="small">Sistema de Búsquedas Integradas</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6">
      <div class="d-flex justify-content-end">
        <div class="ps-3 text-end small">
          <p class="mb-0">
            Consultas disponibles: <span
              >{Number($user.consultas - $user.consumo).toLocaleString(
                "es-PE"
              )}</span
            >
          </p>
          <p class="mb-0">Vence: <span>{$user.fin}</span></p>
          <p class="mb-0">
            Ventas (WhatsApp):
            <a
              class="text-decoration-none"
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=529982287446&amp;text&amp;app_absent=0"
            >
              +52 998 228 7446
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</header>
