<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import uuid from "uuid-v4"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import exportTxt from "../services/exportTxt"
  import ubigeo from "../services/ubigeo.js"
  import { verifySession } from "../services/verifySession"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  const ids = {
    edad_inicio: uuid(),
    edad_fin: uuid(),
    ingreso_inicio: uuid(),
    ingreso_fin: uuid(),
    deuda_inicio: uuid(),
    deuda_fin: uuid(),
    entidades_inicio: uuid(),
    entidades_fin: uuid(),
    calificacion_positiva_meses: uuid(),
    calificacion_positiva_nor: uuid(),
    calificacion_positiva_cpp: uuid(),
    calificacion_negativa_meses: uuid(),
    calificacion_negativa_cpp: uuid(),
    calificacion_negativa_def: uuid(),
    calificacion_negativa_dud: uuid(),
    calificacion_negativa_per: uuid(),
    ruc_laboral: uuid(),
    departamento: uuid(),
    provincia: uuid(),
    distrito: uuid(),
    radio_tipo_entidades_incluir: uuid(),
    radio_tipo_entidades_excluir: uuid(),
    radio_entidades_incluir: uuid(),
    radio_entidades_excluir: uuid(),
  }

  let filtros_disponibles = {
    tipo: "Diario",
    disponible: 0,
  }

  let filtros = {
    documentos: [],
    edad_inicio: "",
    edad_fin: "",
    ingreso_inicio: "",
    ingreso_fin: "",
    deuda_inicio: "",
    deuda_fin: "",
    entidades_inicio: "",
    entidades_fin: "",
    calificacion_positiva_meses: "1",
    calificacion_positiva_nor: true,
    calificacion_positiva_cpp: false,
    calificacion_negativa_meses: "3",
    calificacion_negativa_cpp: false,
    calificacion_negativa_def: false,
    calificacion_negativa_dud: true,
    calificacion_negativa_per: true,
    direcciones: [],
    ops_tipo_entidad: "incluir",
    ops_entidad: "incluir",
    entidades: [],
    rucs: [],
    tipo_entidades: [],
  }

  let isLoading = false

  let departamentos = ubigeo.getDepartamento()
  let provincias = []
  let distritos = []

  let cod_dep = "00"
  let cod_pro = "00"
  let cod_dis = "00"

  let des_dep = ""
  let des_pro = ""
  let des_dis = ""

  let session_key = $user.session_key
  let entidades = []
  let entidad = "00001"
  let tipo_entidad = "Banco"
  let ruc_laboral = ""

  const getEntidades = async () => {
    const ops = "get_entidades"
    let url = `${BASE_URL}filtro.php?session_key=${session_key}&ops=${ops}`
    const response = await fetch(url)
    entidades = await response.json()
    return entidades
  }
  const promiseEntidades = getEntidades()

  // RUCs
  const addRuc = () => {
    if (
      ruc_laboral.length === 11 &&
      filtros.rucs.includes(ruc_laboral) === false
    ) {
      filtros.rucs = [...filtros.rucs, ruc_laboral]
      ruc_laboral = ""
    }
  }

  const deleteRuc = (ruc) => {
    filtros.rucs = filtros.rucs.filter((r) => r !== ruc)
  }

  // Tipo de entidades
  const addTipoEntidad = () => {
    if (!filtros.tipo_entidades.includes(tipo_entidad)) {
      filtros.tipo_entidades = [...filtros.tipo_entidades, tipo_entidad]
    }
  }

  const deleteTipoEntidad = (tipo_entidad_base) => {
    filtros.tipo_entidades = filtros.tipo_entidades.filter(
      (element) => element !== tipo_entidad_base
    )
  }

  // entidades
  const addEntidad = () => {
    let entidad_base = entidades.find((element) => element.codigo === entidad)
    if (!filtros.entidades.includes(entidad_base)) {
      filtros.entidades = [...filtros.entidades, entidad_base]
    }
  }

  const deleteEntidad = (entidad_base) => {
    filtros.entidades = filtros.entidades.filter(
      (element) => element !== entidad_base
    )
  }

  // Ubigeo
  const refreshProvincias = (e) => {
    provincias = ubigeo.getProvincia(cod_dep)
    des_dep = e.target.options[e.target.selectedIndex].text

    cod_pro = "00"
    des_pro = ""
    distritos = ubigeo.getDistrito(cod_dep, "00")
  }
  const refreshDistritos = (e) => {
    distritos = ubigeo.getDistrito(cod_dep, cod_pro)
    des_pro = e.target.options[e.target.selectedIndex].text

    cod_dis = "00"
    des_dis = ""
  }
  const refreshUbigeo = (e) => {
    des_dis = e.target.options[e.target.selectedIndex].text
  }

  const addDirecciones = () => {
    if (cod_dep === "00") return

    let ubigeo = cod_dep
    if (cod_pro !== "00") ubigeo = `${cod_dep}${cod_pro}`
    if (cod_dis !== "00") ubigeo = `${cod_dep}${cod_pro}${cod_dis}`

    let direccion = {
      ubigeo: ubigeo,
      departamento: des_dep,
      provincia: des_pro != "· · · SELECCIONE · · ·" ? des_pro : "",
      distrito: des_dis != "· · · SELECCIONE · · ·" ? des_dis : "",
    }
    if (!filtros.direcciones.find((u) => u.ubigeo === direccion.ubigeo)) {
      filtros.direcciones = [...filtros.direcciones, direccion]
    }
  }

  const deleteDireccion = (ubigeo) => {
    filtros.direcciones = filtros.direcciones.filter((u) => u.ubigeo !== ubigeo)
  }

  //Leer documentos
  const leerArchivo = (e) => {
    filtros.documentos = []
    let archivo = e.target.files[0]
    let lector = new FileReader()
    if (!archivo) {
      return
    }
    lector.onload = (e) => {
      let contenido = e.target.result
      contenido = contenido.split("\r\n")
      contenido.map(function (item) {
        let tabs = item.split("\t")
        let tabs_num = tabs[1]
        if (!tabs_num) {
          tabs_num = ""
        }
        let col = {
          documento: tabs[0],
          numero: tabs_num,
        }
        filtros.documentos.push(col.documento.trim())
      })
      console.log(filtros.documentos)
    }
    lector.readAsText(archivo)
  }

  //Descargar
  const descargarFiltros = (content) => {
    let texto = []
    texto.push(
      "Documento" +
        "\t" +
        "Paterno" +
        "\t" +
        "Materno" +
        "\t" +
        "Nombres" +
        "\t" +
        "Nacimiento" +
        "\t" +
        "Ubigeo" +
        "\t" +
        "Departamento" +
        "\t" +
        "Provincia" +
        "\t" +
        "Distrito" +
        "\t" +
        "Ingreso" +
        "\t" +
        "Deuda" +
        "\t" +
        "Num_entidades"
    )
    for (let d of content) {
      texto.push(
        "\n" +
          d.documento +
          "\t" +
          d.paterno +
          "\t" +
          d.materno +
          "\t" +
          d.nombres +
          "\t" +
          d.nacimiento +
          "\t" +
          d.ubigeo +
          "\t" +
          d.departamento +
          "\t" +
          d.provincia +
          "\t" +
          d.distrito +
          "\t" +
          d.ingreso +
          "\t" +
          d.deuda +
          "\t" +
          d.num_entidades
      )
    }

    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "filtros")
  }

  //obtener fecha de nacimiento AAAA-MM-DD
  const getFechaNacimiento = (edad) => {
    let fecha = new Date()
    fecha.setFullYear(fecha.getFullYear() - edad)
    return fecha.toISOString().substr(0, 10)
  }

  const procesar = async () => {
    if (!validarFormulario()) {
      alert("Completar el formulario")
      return
    }

    isLoading = true

    let data = {
      session_key: $user.session_key,
      filtros: filtros,
    }

    data.filtros.edad_inicio_fecha = getFechaNacimiento(filtros.edad_inicio)
    data.filtros.edad_fin_fecha = getFechaNacimiento(filtros.edad_fin)

    let url = `${BASE_URL}filtro.php`

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "aplication/json",
      },
    })

    const resp = await response.json()

    if (resp.status === "ok") {
      console.log(resp.data)
      descargarFiltros(resp.data)
    } else {
      alert(resp.message)
    }

    isLoading = false
    getPuntos()
    getFiltrosDisponibles()
    reiniciarTiempo()
  }

  const validarFormulario = () => {
    let status = true
    if (filtros.documentos.length === 0) status = false
    if (
      filtros.edad_inicio == "" ||
      filtros.edad_fin == "" ||
      filtros.ingreso_inicio == "" ||
      filtros.ingreso_fin == "" ||
      filtros.entidades_inicio == "" ||
      filtros.entidades_fin == ""
    )
      status = false

    return status
  }

  const getPuntos = async () => {
    let url = `${BASE_URL}session.php?session_key=${$user.session_key}`
    const response = await fetch(url)
    const resp = await response.json()

    if (resp.status === "success") {
      let temp_user = $user
      temp_user.consumo = resp.data.consumo
      temp_user.consultas = resp.data.consultas
      temp_user.inicio = resp.data.inicio
      temp_user.fin = resp.data.fin
      user.setUser(temp_user)
    } else {
      navigate("/", { replace: true })
    }
  }

  const getFiltrosDisponibles = async () => {
    const ops = "get_filtros_disponibles"
    let url = `${BASE_URL}filtro.php?ops=${ops}&session_key=${$user.session_key}`
    const response = await fetch(url)
    const resp = await response.json()

    if (resp.tipo === "D") {
      resp.tipo = "diario"
    } else {
      resp.tipo = "mensual"
    }

    filtros_disponibles.tipo = resp.tipo
    filtros_disponibles.disponible = resp.disponible

    console.log(resp)
  }

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
      if (!$user.permisos.includes("filtro")) {
        user.setUser(null)
        navigate("/", { replace: true })
      }
    }

    getFiltrosDisponibles()
  })
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />
    <div class="container-fluid py-3 bg-light">
      <div class="card shadow-sm bg-white">
        <div class="card-body">
          <div class="row g-3">
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <label for="formFile" class="form-label small text-secondary">
                Archivo
              </label>
              <input
                class="form-control form-control-sm"
                type="file"
                id="formFile"
                on:change={(e) => leerArchivo(e)}
              />
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <label
                for={ids.edad_inicio}
                class="form-label small text-secondary"
              >
                Rango edad
              </label>
              <div class="d-flex gap-2">
                <input
                  id={ids.edad_inicio}
                  bind:value={filtros.edad_inicio}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
                <input
                  id={ids.edad_fin}
                  bind:value={filtros.edad_fin}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <label
                for={ids.ingreso_inicio}
                class="form-label small text-secondary"
              >
                Rango ingreso
              </label>
              <div class="d-flex gap-2">
                <input
                  id={ids.ingreso_inicio}
                  bind:value={filtros.ingreso_inicio}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
                <input
                  id={ids.ingreso_fin}
                  bind:value={filtros.ingreso_fin}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <label
                for={ids.deuda_inicio}
                class="form-label small text-secondary"
              >
                Rango deuda
              </label>
              <div class="d-flex gap-2">
                <input
                  id={ids.deuda_inicio}
                  bind:value={filtros.deuda_inicio}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
                <input
                  id={ids.deuda_fin}
                  bind:value={filtros.deuda_fin}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <label
                for={ids.entidades_inicio}
                class="form-label small text-secondary"
              >
                Rango entidades
              </label>
              <div class="d-flex gap-2">
                <input
                  id={ids.entidades_inicio}
                  bind:value={filtros.entidades_inicio}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
                <input
                  id={ids.entidades_fin}
                  bind:value={filtros.entidades_fin}
                  class="form-control form-control-sm text-end"
                  type="number"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <label
                for={ids.calificacion_positiva_meses}
                class="form-label small text-secondary"
              >
                Calificación positiva
              </label>
              <div class="d-flex gap-2">
                <select
                  id={ids.calificacion_positiva_meses}
                  class="form-select form-select-sm"
                  bind:value={filtros.calificacion_positiva_meses}
                >
                  <option value="1">1 Mes</option>
                  <option value="3">3 Meses</option>
                  <option value="6">6 Meses</option>
                  <option value="12">12 Meses</option>
                </select>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={filtros.calificacion_positiva_nor}
                    id={ids.calificacion_positiva_nor}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.calificacion_positiva_nor}
                  >
                    NOR
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={filtros.calificacion_positiva_cpp}
                    id={ids.calificacion_positiva_cpp}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.calificacion_positiva_cpp}
                  >
                    CPP
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-5">
              <label
                for={ids.calificacion_negativa_meses}
                class="form-label small text-secondary"
              >
                Calificación negativa
              </label>
              <div class="d-flex gap-2">
                <select
                  id={ids.calificacion_negativa_meses}
                  bind:value={filtros.calificacion_negativa_meses}
                  class="form-select form-select-sm"
                >
                  <option value="3">3 Meses</option>
                  <option value="6">6 Meses</option>
                  <option value="12">12 Meses</option>
                  <option value="18">18 Meses</option>
                  <option value="24">24 Meses</option>
                </select>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={filtros.calificacion_negativa_cpp}
                    id={ids.calificacion_negativa_cpp}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.calificacion_negativa_cpp}
                  >
                    CPP
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={filtros.calificacion_negativa_def}
                    id={ids.calificacion_negativa_def}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.calificacion_negativa_def}
                  >
                    DEF
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={filtros.calificacion_negativa_dud}
                    id={ids.calificacion_negativa_dud}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.calificacion_negativa_dud}
                  >
                    DUD
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={filtros.calificacion_negativa_per}
                    id={ids.calificacion_negativa_per}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.calificacion_negativa_per}
                  >
                    PER
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <label
                for={ids.ruc_laboral}
                class="form-label small text-secondary"
              >
                RUC laboral
              </label>
              <div class="d-flex gap-2">
                <input
                  id={ids.ruc_laboral}
                  bind:value={ruc_laboral}
                  class="form-control form-control-sm"
                  type="text"
                />
                <button class="btn btn-secondary btn-sm" on:click={addRuc}
                  >Agregar</button
                >
              </div>
              <div>
                {#if filtros.rucs.length > 0}
                  <ul class="list-group small mt-1">
                    {#each filtros.rucs as ruc}
                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        {ruc}
                        <button
                          class="btn bg-white text-danger btn-sm p-0"
                          on:click={() => deleteRuc(ruc)}
                        >
                          <i class="bi bi-trash" />
                        </button>
                      </li>
                    {/each}
                  </ul>
                {/if}
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-9">
              <label
                for={ids.departamento}
                class="form-label small text-secondary"
              >
                Direcciones
              </label>
              <div class="d-flex gap-2">
                <select
                  id={ids.departamento}
                  bind:value={cod_dep}
                  on:change={refreshProvincias}
                  class="form-select form-select-sm"
                >
                  <option value="00">· · · SELECCIONE · · ·</option>
                  {#each departamentos as departamento}
                    <option value={departamento.cod_dep}>
                      {departamento.des_dep}
                    </option>
                  {/each}
                </select>
                <select
                  id={ids.provincia}
                  bind:value={cod_pro}
                  on:change={refreshDistritos}
                  class="form-select form-select-sm"
                >
                  <option value="00">· · · SELECCIONE · · ·</option>
                  {#each provincias as provincia}
                    <option value={provincia.cod_pro}>
                      {provincia.des_pro}
                    </option>
                  {/each}
                </select>
                <select
                  id={ids.distrito}
                  bind:value={cod_dis}
                  on:change={refreshUbigeo}
                  class="form-select form-select-sm"
                >
                  <option value="">· · · SELECCIONE · · ·</option>
                  {#each distritos as distrito}
                    <option value={distrito.cod_dis}>
                      {distrito.des_dis}
                    </option>
                  {/each}
                </select>
                <button
                  class="btn btn-secondary btn-sm"
                  on:click={addDirecciones}>Agregar</button
                >
              </div>
              <div>
                {#if filtros.direcciones.length > 0}
                  <div class="table-responsive small">
                    <table class="table table-striped table-sm">
                      {#if filtros.direcciones.length > 0}
                        <thead>
                          <tr>
                            <th class="text-secondary fw-normal">Ubigeo</th>
                            <th class="text-secondary fw-normal"
                              >Departamento</th
                            >
                            <th class="text-secondary fw-normal">Provincia</th>
                            <th class="text-secondary fw-normal">Distrito</th>
                            <th class="text-secondary fw-normal" />
                          </tr>
                        </thead>
                      {/if}
                      <tbody>
                        {#each filtros.direcciones as direccion}
                          <tr>
                            <td>{direccion.ubigeo}</td>
                            <td>{direccion.departamento}</td>
                            <td>{direccion.provincia}</td>
                            <td>{direccion.distrito}</td>
                            <td>
                              <button
                                class="btn text-danger btn-sm p-0"
                                on:click={() =>
                                  deleteDireccion(direccion.ubigeo)}
                              >
                                <i class="bi bi-trash" />
                              </button>
                            </td>
                          </tr>
                        {:else}
                          <tr>
                            <td colspan="5" class="text-center" />
                          </tr>
                        {/each}
                      </tbody>
                    </table>
                  </div>
                {/if}
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <span class="form-label small text-secondary">
                Tipo de entidades
              </span>
              <div class="d-flex gap-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    bind:group={filtros.ops_tipo_entidad}
                    id={ids.radio_tipo_entidades_incluir}
                    name="ops_tipo_entidad"
                    value={"incluir"}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.radio_tipo_entidades_incluir}
                  >
                    Incluir solo deudas de:
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    bind:group={filtros.ops_tipo_entidad}
                    id={ids.radio_tipo_entidades_excluir}
                    name="ops_tipo_entidad"
                    value={"excluir"}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.radio_tipo_entidades_excluir}
                  >
                    Excluir deudas de:
                  </label>
                </div>
              </div>
              <div class="d-flex gap-2">
                <select
                  bind:value={tipo_entidad}
                  class="form-select form-select-sm"
                >
                  <option value="Banco">Banco</option>
                  <option value="Caja Municipal de Ahorro y Crédito"
                    >Caja Municipal de Ahorro y Crédito</option
                  >
                  <option value="Caja Rural de Ahorro y Crédito"
                    >Caja Rural de Ahorro y Crédito</option
                  >
                  <option value="Financiera">Financiera</option>
                </select>
                <button
                  class="btn btn-secondary btn-sm"
                  on:click={addTipoEntidad}>Agregar</button
                >
              </div>
              <div>
                {#if filtros.tipo_entidades.length > 0}
                  <ul class="list-group small mt-1">
                    {#each filtros.tipo_entidades as tipo_entidad_base}
                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        {tipo_entidad_base}
                        <button
                          class="btn bg-white text-danger btn-sm p-0"
                          on:click={() => deleteTipoEntidad(tipo_entidad_base)}
                        >
                          <i class="bi bi-trash" />
                        </button>
                      </li>
                    {/each}
                  </ul>
                {/if}
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <span class="form-label small text-secondary"> Entidades </span>
              <div class="d-flex gap-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="ops_entidad"
                    bind:group={filtros.ops_entidad}
                    id={ids.radio_entidades_incluir}
                    value={"incluir"}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.radio_entidades_incluir}
                  >
                    Incluir solo deudas de:
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="ops_entidad"
                    bind:group={filtros.ops_entidad}
                    id={ids.radio_entidades_excluir}
                    value={"excluir"}
                  />
                  <label
                    class="form-check-label small"
                    for={ids.radio_entidades_excluir}
                  >
                    Excluir deudas de:
                  </label>
                </div>
              </div>
              <div class="d-flex gap-2">
                <select bind:value={entidad} class="form-select form-select-sm">
                  {#await promiseEntidades}
                    <option value="00001">BCP</option>
                  {:then data}
                    {#each data as entidad_base}
                      <option value={entidad_base.codigo}
                        >{entidad_base.empresa}</option
                      >
                    {/each}
                  {/await}
                </select>
                <button class="btn btn-secondary btn-sm" on:click={addEntidad}
                  >Agregar</button
                >
              </div>
              <div>
                {#if filtros.entidades.length > 0}
                  <ul class="list-group small mt-1">
                    {#each filtros.entidades as entidad_base}
                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        {entidad_base.empresa}
                        <button
                          class="btn bg-white text-danger btn-sm p-0"
                          on:click={() => deleteEntidad(entidad_base)}
                        >
                          <i class="bi bi-trash" />
                        </button>
                      </li>
                    {/each}
                  </ul>
                {/if}
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 text-end">
              <p>
                <span class="form-label small text-secondary">
                  Disponible {filtros_disponibles.tipo}: {Number(
                    filtros_disponibles.disponible
                  ).toLocaleString("es-PE")}
                </span>
              </p>
              {#if isLoading}
                <button class="btn btn-primary btn-sm disabled"
                  >Procesando...</button
                >
              {:else}
                <button class="btn btn-primary btn-sm" on:click={procesar}
                  >Procesar</button
                >
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
{/if}
