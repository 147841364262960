<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"
  import { Datatable } from "svelte-simple-datatables"

  export let ruc

  let data = {
    empresa: undefined,
    loading: false,
  }

  let rows

  const settings = {
    sortable: true,
    pagination: true,
    rowsPerPage: 10,
    columnFilter: false,
    scrollY: false,
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: false,
    },
    labels: {
      previous: "Anterior",
      next: "Siguiente",
    },
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    const ops = "general_vehiculos"
    data.loading = true
    const response = await fetch(
      `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    data = {
      empresa: resp.data,
      loading: false,
    }
  }

  cargarDatos()

  const encabezados = [
    "Placa",
    "Marca",
    "Modelo",
    "Clase",
    "Carroceria",
    "Año de fabricación",
    "Año de compra",
    "Nro. Transferencia",
    "Tipo de propiedad",
  ]
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <Datatable
    {settings}
    data={data.empresa}
    bind:dataRows={rows}    
  >
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        {#each encabezados as encabezado}
          <th class="text-secondary fw-normal">{encabezado}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#if rows}
        {#each $rows as row}
          <tr>
            <td>{row.placa}</td>
            <td>{row.marca}</td>
            <td>{row.modelo}</td>
            <td>{row.clase}</td>
            <td>{row.carroceria}</td>
            <td>{row.fabricacion}</td>
            <td>{row.compra}</td>
            <td>{row.nrotransferencia}</td>
            <td>{row.tipo_propiedad}</td>
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
  </Datatable>
{/if}
