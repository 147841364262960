<script>
  import { links, navigate } from "svelte-routing"
  import { onDestroy, onMount } from "svelte"
  import { globalHistory } from "svelte-routing/src/history"
  import { user } from "../stores/User"

  let pathname = window.location.pathname
  let unsub

  onMount(() => {
    unsub = globalHistory.listen(({ location }) => {
      pathname = location.pathname
    })
  })

  onDestroy(() => {
    unsub()
  })

  const logout = () => {
    user.setUser(null)
    navigate("/", { replace: true })
  }
</script>

<nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm sticky-top">
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav" use:links>
        {#if $user.permisos.includes("persona")}
          <li class="nav-item">
            <a
              class="nav-link"
              class:active={pathname.includes("/persona")}
              href="/persona">Persona</a
            >
          </li>
        {/if}
        {#if $user.permisos.includes("empresa")}
          <li class="nav-item">
            <a
              class="nav-link"
              class:active={pathname.includes("/empresa")}
              href="/empresa">Empresa</a
            >
          </li>
        {/if}
        {#if $user.permisos.includes("masivo")}
          <li class="nav-item">
            <a
              class="nav-link"
              class:active={pathname === "/masivo"}
              href="/masivo">Masivo</a
            >
          </li>
        {/if}
        {#if $user.permisos.includes("reporte")}
          <li class="nav-item">
            <a
              class="nav-link"
              class:active={pathname === "/reporte"}
              href="/reporte">Reporte</a
            >
          </li>
        {/if}
        {#if $user.permisos.includes("filtro")}
          <li class="nav-item">
            <a
              class="nav-link"
              class:active={pathname === "/filtro"}
              href="/filtro">Filtro</a
            >
          </li>
        {/if}
      </ul>
    </div>
    <div class="text-end">
      <div class="dropdown small">
        <p class="mb-0"><i class="bi bi-buildings" /> {$user.razon_social}</p>

        <div class="btn-group">
          <button
            type="button"
            class="btn btn-sm dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-person-fill" />
            {$user.nombres}
          </button>
          <ul class="dropdown-menu dropdown-menu-end text-end" use:links>
            {#if $user.permisos.includes("configuracion")}
              <li>
                <a class="dropdown-item" href="/configuracion">
                  <span class="small">Configuración</span>
                  <i class="bi bi-gear-fill" />
                </a>
              </li>
            {/if}
            <li>
              <button
                class="dropdown-item text-danger"
                on:click={logout}
                type="button"
              >
                <span class="small">Cerrar sesión</span>
                <i class="bi bi-box-arrow-right" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
