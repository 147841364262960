const getMoneda = (num) => {
  const n = String(num),
    p = n.indexOf(".")
  return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
    p < 0 || i < p ? `${m},` : m
  )
}

const redondear = (num, decimals = 2) => {
  let t = Math.pow(10, decimals)
  return (
    Math.round(
      num * t +
        (decimals > 0 ? 1 : 0) *
          (Math.sign(num) * (10 / Math.pow(100, decimals)))
    ) / t
  ).toFixed(decimals)
}

const dateFormat = (value, pattern = "####-##-##") => {
  let i = 0,
    date = value.toString()
  if (value.length === 8) {
    return pattern.replace(/#/g, (_) => date[i++])
  } else {
    return value
  }
}

const currentDate = () => {
  let date = new Date()
  return date.toISOString().slice(0, 10)
}

const getFecha = () => {
  let date = new Date()
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 101).toString().substring(1)
  let day = (date.getDate() + 100).toString().substring(1)

  let fecha = year + "-" + month + "-" + day
  return fecha
}

export default getMoneda
export { redondear, dateFormat, currentDate, getFecha }
