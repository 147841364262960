<script>
  import uuid from "uuid-v4"
  import Accordion from "../Accordion.svelte"
  import HistoricoDatos from "./Historico_datos.svelte"

  export let documento

  let load = {
    historico: false,
  }

  const click = (ops) => {
    switch (ops) {
      case "historico":
        load.historico = true
        break
      default:
        break
    }
  }
</script>

<div>
  <Accordion title="Histórico" idx={uuid()} on:click={() => click("historico")}>
    {#if load.historico}
      <HistoricoDatos {documento} />
    {/if}
  </Accordion>
</div>
