<script>
  import { addDoc, collection } from "firebase/firestore"
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import ResultadoEmpresa from "../components/empresa/Resultado_empresa.svelte"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import SinInformacion from "../components/Sin_informacion.svelte"
  import Spinner from "../components/Spinner.svelte"
  import { db } from "../firebase"
  import { verifySession } from "../services/verifySession"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  let ruc = ""

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
      getPuntos()
    }

    if (!$user.permisos.includes("empresa")) {
      user.setUser(null)
      navigate("/", { replace: true })
    }
  })

  let data = {
    empresas: undefined,
    loading: false,
  }

  const buscarEmpresa = async () => {
    verifySession($user.session_key)
    if (!validarFormulario()) {
      alert("El RUC no es válido")
      return
    }
    reiniciarTiempo()
    addRuc()
    let session_key = $user.session_key
    let url = `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}`
    data.loading = true
    const response = await fetch(url)
    const resp = await response.json()
    data = {
      empresas: resp.data,
      loading: false,
    }
  }

  const validarFormulario = () => {
    let validation = false
    ruc = ruc.trim()
    if (ruc.length === 11) validation = true
    return validation
  }

  const limpiar = () => {
    reiniciarTiempo()
    ruc = ""
  }

  const getPuntos = async () => {
    const response = await fetch(
      `${BASE_URL}session.php?session_key=${$user.session_key}`,
    )
    const resp = await response.json()

    if (resp.status === "success") {
      let temp_user = $user
      temp_user.consumo = resp.data.consumo
      temp_user.consultas = resp.data.consultas
      temp_user.inicio = resp.data.inicio
      temp_user.fin = resp.data.fin
      user.setUser(temp_user)
    } else {
      navigate("/", { replace: true })
    }
  }

  const addRuc = async () => {
    let tmp_ruc = {
      documento: ruc,
      tipo: "ruc",
    }
    try {
      await addDoc(collection(db, "documentos"), tmp_ruc)
    } catch (error) {
      console.error(error)
    }
  }

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />

    <div class="container-fluid py-3 bg-light">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h5 class="text-center">Búsqueda de Empresas</h5>
              <form on:submit|preventDefault={buscarEmpresa}>
                <label
                  for="inputDocumentoE"
                  class="form-label small text-secondary">Ruc</label
                >
                <input
                  type="text"
                  id="inputDocumentoE"
                  class="form-control form-control-sm"
                  bind:value={ruc}
                />
                <div class="d-flex gap-2 my-2">
                  {#if data.loading}
                    <button
                      type="submit"
                      class="w-100 btn btn-primary btn-sm disabled"
                      >Buscando...</button
                    >
                  {:else}
                    <button type="submit" class="w-100 btn btn-primary btn-sm"
                      >Buscar</button
                    >
                  {/if}
                  <button
                    type="button"
                    class="w-100 btn btn-primary btn-sm"
                    on:click={limpiar}>Limpiar</button
                  >
                </div>
              </form>
            </div>
            <div class="col-12 col-lg-6">
              {#if data.loading === true}
                <Spinner />
              {:else if data.empresas !== undefined}
                {#if data.empresas.error_id === undefined}
                  <ResultadoEmpresa empresas={data.empresas} />
                {:else if data.empresas.message == "No tiene consultas disponibles"}
                  <SinInformacion message={data.empresas.message} />
                {:else}
                  <SinInformacion />
                {/if}
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
{/if}
