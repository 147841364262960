<script>
  import uuid from "uuid-v4"
  import Accordion from "../Accordion.svelte"
  import LaboralEmpresa from "./Laboral_empresa.svelte"
  import LaboralTelefonos from "./Laboral_telefonos.svelte"
  import LaboralSeguro from "./Laboral_seguro.svelte"
  import LaboralPlanilla from "./Laboral_planilla.svelte"

  export let documento

  let load = {
    empresa: false,
    telefonos: false,
    seguro: false,
    planilla: false,
  }

  const click = (ops) => {
    switch (ops) {
      case "empresa":
        load.empresa = true
        break
      case "telefonos":
        load.telefonos = true
        break
      case "seguro":
        load.seguro = true
        break
      case "planilla":
        load.planilla = true
        break
      default:
        break
    }
  }
</script>

<div>
  <Accordion title="Empresa" idx={uuid()} on:click={() => click("empresa")}>
    {#if load.empresa}
      <LaboralEmpresa {documento} />
    {/if}
  </Accordion>
  <Accordion title="Teléfonos" idx={uuid()} on:click={() => click("telefonos")}>
    {#if load.telefonos}
      <LaboralTelefonos {documento} />
    {/if}
  </Accordion>
  <Accordion title="Seguro" idx={uuid()} show={true}>
    <LaboralSeguro {documento} />
  </Accordion>
  <Accordion
    title="Datos Laborales"
    idx={uuid()}
    on:click={() => click("planilla")}
  >
    {#if load.planilla}
      <LaboralPlanilla {documento} />
    {/if}
  </Accordion>
</div>
