<script>
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"
  import getMoneda from "../../services/moneda.js"
  import { BASE_URL } from "../../stores/BASE_URL"
  import { user } from "../../stores/User"

  export let documento

  let data = {
    persona: undefined,
    loading: false,
  }

  let mes = "1"

  let session_key = $user.session_key
  const ops = "resumen_financiero"

  const cargarDatos = async () => {
    let cliente = "cliente" + mes
    let clientedetalle = "clientedetalle" + mes
    data.loading = true
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}&cliente=${cliente}&clientedetalle=${clientedetalle}`
    )
    const resp = await response.json()
    data = {
      persona: resp.data,
      loading: false,
    }
  }

  const encabezados = [
    "Entidad",
    "Linea Aprobada TC",
    "Linea Disponible TC",
    "Deuda TC",
    "Vehicular",
    "Hipotecario",
    "Comercial",
    "Préstamo",
    "Convenio",
    "Otros",
  ]

  cargarDatos()
</script>

<div class="row mb-2">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <select
      class="form-select form-select-sm"
      bind:value={mes}
      on:change={() => cargarDatos()}
    >
      <option value="1">Mes 01</option>
      <option value="2">Mes 02</option>
      <option value="3">Mes 03</option>
      <option value="4">Mes 04</option>
      <option value="5">Mes 05</option>
      <option value="6">Mes 06</option>
    </select>
  </div>
</div>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead class="text-center">
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.persona as resumen}
          <tr class="text-end">
            <td class="text-start">{resumen.entidad}</td>
            <td>{getMoneda(resumen.linea_aprobada)}</td>
            <td>{getMoneda(resumen.linea_disponible)}</td>
            <td>{getMoneda(resumen.tarjeta)}</td>
            <td>{getMoneda(resumen.vehicular)}</td>
            <td>{getMoneda(resumen.hipotecario)}</td>
            <td>{getMoneda(resumen.comercial)}</td>
            <td>{getMoneda(resumen.prestamo)}</td>
            <td>{getMoneda(resumen.convenio)}</td>
            <td>{getMoneda(resumen.otros)}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}
