<script>
  import uuid from "uuid-v4"
  import Opcional_facebook from "../../components/persona/Opcional_facebook.svelte"
  import Opcional_licencia from "../../components/persona/Opcional_licencia.svelte"
  import OpcionalPension65 from "../../components/persona/Opcional_pension65.svelte"
  import Opcional_politicos from "../../components/persona/Opcional_politicos.svelte"
  import Opcional_seguro_policial from "../../components/persona/Opcional_seguro_policial.svelte"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"
  import { BASE_URL } from "../../stores/BASE_URL"
  import { user } from "../../stores/User"
  import Accordion from "../Accordion.svelte"

  export let documento

  let mostrar = false

  let data = {
    seguro_policial: [],
    seguro_policial_loading: true,
  }

  let session_key = $user.session_key

  const cargarDatosSeguroPolicial = async () => {
    const ops = "opcional_seguro_policial"
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    data = {
      seguro_policial: resp.data,
      seguro_policial_loading: false,
    }

    if (resp.data.length > 0) {
      mostrar = true
    }
  }

  const datosPoliticos = async () => {
    const ops = "otros_politicos"
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    if (resp.data.length > 0) mostrar = true
    return resp.data
  }

  const datosFacebook = async () => {
    const ops = "otros_redes_sociales"
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    if (resp.data.length > 0) mostrar = true
    return resp.data
  }

  const datosLicencia = async () => {
    const ops = "otros_licencia"
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    if (resp.data.length > 0) mostrar = true
    return resp.data
  }

  const datosPension65 = async () => {
    const ops = "otros_pension65"
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    if (resp.data.pension !== undefined) mostrar = true
    return resp.data
  }

  cargarDatosSeguroPolicial()

  let promise_politicos = datosPoliticos()
  let promise_facebook = datosFacebook()
  let promise_licencia = datosLicencia()
  let promise_pension65 = datosPension65()
</script>

{#await promise_licencia}
  <Spinner />
{:then licencias}
  {#if licencias.length > 0}
    <Accordion
      title="Licencia de conducir"
      idx={uuid()}
      show={true}
      message="Actualizando en linea"
      online={true}
    >
      <Opcional_licencia {licencias} />
    </Accordion>
  {/if}
{/await}

{#if data.seguro_policial !== undefined && data.seguro_policial.length > 0}
  <Accordion
    title="Datos del Asegurado según SALUDPOL"
    idx={uuid()}
    show={true}
    message="Actualizando en linea"
    online={true}
  >
    <Opcional_seguro_policial
      {documento}
      nombres={data.seguro_policial[2]}
      nacimiento={data.seguro_policial[3]}
      edad={data.seguro_policial[10]}
      incapacidad={data.seguro_policial[14]}
      estado={data.seguro_policial[4]}
      titular={data.seguro_policial[11] == "1" ? "SI" : "NO"}
    />
  </Accordion>
{/if}

{#await promise_politicos}
  <Spinner />
{:then politicos}
  {#if politicos.length > 0}
    <Accordion title="Políticos" idx={uuid()} show={true}>
      <Opcional_politicos {politicos} />
    </Accordion>
  {/if}
{/await}

{#await promise_facebook}
  <Spinner />
{:then redes_sociales}
  {#if redes_sociales.length > 0}
    <Accordion title="Redes sociales" idx={uuid()} show={true}>
      <Opcional_facebook {redes_sociales} />
    </Accordion>
  {/if}
{/await}

{#await promise_pension65}
  <Spinner />
{:then pension65}
  {#if pension65.pension !== undefined}
    <Accordion title="Pensión 65" idx={uuid()} show={true}>
      <OpcionalPension65
        pension={pension65.pension}
        monto={pension65.monto}
        persona_autorizada={pension65.persona_autorizada}
      />
    </Accordion>
  {/if}
{/await}

{#if mostrar === false}
  <SinInformacion />
{/if}
