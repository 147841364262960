<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import { verifySession } from "../services/verifySession"
  import { user } from "../stores/User"

  const usuarios = [
    {
      id: 1,
      usuario: "romario",
      nombres: "Romario B.",
      consultas: 50000,
      consumo: 10557,
      estado: "1",
    },
    {
      id: 2,
      usuario: "karen",
      nombres: "Karen Baldarrago",
      consultas: 20000,
      consumo: 64,
      estado: "1",
    },
    {
      id: 130,
      usuario: "javier",
      nombres: "Javier Chire",
      consultas: 1000,
      consumo: 75,
      estado: "1",
    },
    {
      id: 231,
      usuario: "prueba",
      nombres: "prueba",
      consultas: 10,
      consumo: 15,
      estado: "0",
    },
    {
      id: 232,
      usuario: "prueba2",
      nombres: "prueba2",
      consultas: 10,
      consumo: 14,
      estado: "0",
    },
    {
      id: 233,
      usuario: "bellmily04@gmail.com",
      nombres: "Miriam González Gálvez",
      consultas: 5000,
      consumo: 14,
      estado: "0",
    },
    {
      id: 234,
      usuario: "miriam.gonzalez@grupovalidata.com",
      nombres: "Miriam González",
      consultas: 5000,
      consumo: 21,
      estado: "0",
    },
    {
      id: 235,
      usuario: "dany.cabrera@grupovalidata.mx",
      nombres: "Dany Cabrera",
      consultas: 100000,
      consumo: 95,
      estado: "1",
    },
    {
      id: 236,
      usuario: "anamaria.torres@grupovalidata.mx",
      nombres: "Ana María Torres",
      consultas: 5000,
      consumo: 18,
      estado: "1",
    },
    {
      id: 1329,
      usuario: "fiorella.morales@grupovalidata.mx",
      nombres: "Fiorella Morales",
      consultas: 1000,
      consumo: 14,
      estado: "0",
    },
    {
      id: 1439,
      usuario: "juan.sanchez@grupovalidata.mx",
      nombres: "Juan Sánchez",
      consultas: 100,
      consumo: 1,
      estado: "1",
    },
  ]

  const encabezados = [
    "Usuario",
    "Nombres",
    "Consultas",
    "Consumo",
    "Estado",
    "",
  ]

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
    }

    if (!$user.permisos.includes("configuracion")) {
      //user.setUser(null)
      //navigate("/", { replace: true })
    }
  })

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />
    <div class="container-fluid py-3 bg-light">
      <div class="card shadow-sm">
        <div class="card-body small">
          <h5><i class="bi bi-buildings" /> {$user.razon_social}</h5>

          <div class="text-end">
            <button class="btn btn-sm btn-primary">Nuevo usuario</button>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  {#each encabezados as encabezado}
                    <th class="text-secondary fw-normal">{encabezado}</th>
                  {/each}
                </tr>
              </thead>
              <tbody>
                {#each usuarios as u}
                  <tr>
                    <td>{u.usuario}</td>
                    <td>{u.nombres}</td>
                    <td class="text-end">
                      {u.consultas.toLocaleString("es-PE")}
                    </td>
                    <td class="text-end">
                      {u.consumo.toLocaleString("es-PE")}
                    </td>
                    <td>
                      {#if u.estado == "1"}
                        <small
                          class="d-inline-flex px-2 text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2"
                        >
                          Activo
                        </small>
                      {:else}
                        <small
                          class="d-inline-flex px-2 text-danger bg-danger bg-opacity-10 border border-danger border-opacity-10 rounded-2"
                        >
                          Inactivo
                        </small>
                      {/if}
                    </td>
                    <td>
                      <button class="btn btn-warning btn-sm py-0 px-1">
                        <i class="bi bi-pencil-square" />
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
{/if}
