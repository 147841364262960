<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import getMoneda, { redondear } from "../../services/moneda.js"
  import Spinner from "../../components/Spinner.svelte"

  export let ruc

  let data = {
    empresa: undefined,
    loading: false,
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    const ops = "deudas_utilizacion"
    data.loading = true
    const response = await fetch(
      `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    data = {
      empresa: resp.data,
      loading: false,
    }

    if (data.empresa.length > 0) {
      data.empresa.total_linea_otorgada = redondear(
        data.empresa
          .map((item) => parseFloat(item.linea_otorgada))
          .reduce((prev, curr) => prev + curr, 0)
      )

      data.empresa.total_linea_no_utilizada = redondear(
        data.empresa
          .map((item) => parseFloat(item.linea_no_utilizada))
          .reduce((prev, curr) => prev + curr, 0)
      )

      data.empresa.total_linea_utilizada = redondear(
        data.empresa
          .map((item) => parseFloat(item.linea_utilizada))
          .reduce((prev, curr) => prev + curr, 0)
      )
    }
  }

  cargarDatos()

  const calcPorcentaje = (valor, total) => {
    if (parseFloat(valor) * 100 == 0 && parseFloat(total) == 0) return "0%"

    let porcentaje = (parseFloat(valor) * 100) / parseFloat(total)

    return `${Math.round(porcentaje)}%`
  }

  const encabezados = [
    "Entidad",
    "Linea aprobada",
    "Linea no utilizada",
    "Linea utilizada",
    "%L. Utilizada",
  ]
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead class="text-center">
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody class="text-end">
        {#each data.empresa as deuda}
          <tr>
            <td class="text-start">{deuda.empresa}</td>
            <td>{getMoneda(deuda.linea_otorgada)}</td>
            <td>{getMoneda(deuda.linea_no_utilizada)}</td>
            <td>{getMoneda(deuda.linea_utilizada)}</td>
            <td
              >{calcPorcentaje(deuda.linea_utilizada, deuda.linea_otorgada)}</td
            >
          </tr>
        {/each}
      </tbody>
      <tfoot class="text-end">
        <tr>
          <td class="text-secondary fw-normal text-start">Total</td>
          <td>{getMoneda(data.empresa.total_linea_otorgada)}</td>
          <td>{getMoneda(data.empresa.total_linea_no_utilizada)}</td>
          <td>{getMoneda(data.empresa.total_linea_utilizada)}</td>
          <td
            >{calcPorcentaje(
              data.empresa.total_linea_utilizada,
              data.empresa.total_linea_otorgada
            )}</td
          >
        </tr>
      </tfoot>
    </table>
  </div>
{/if}
