<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import { navigate } from "svelte-routing"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"

  export let documento

  let data = {
    persona: undefined,
    loading: false,
  }

  let session_key = $user.session_key

  const buscarPersona = async () => {
    const ops = "general_datos"
    let url = `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    data.loading = true
    const response = await fetch(url)
    const resp = await response.json()

    if (resp.data.error_id !== undefined) {
      alert(resp.data.message)
      navigate("/", { replace: true })
    }

    data = {
      persona: resp.data,
      loading: false,
    }

    data.persona.edad = calcularEdad(data.persona.nacimiento)
  }

  let data_foto = {
    persona: undefined,
    loading: false,
  }

  const handleFoto = async () => {
    const ops = "general_fotos"
    data_foto.loading = true
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`,
    )
    const resp = await response.json()
    data_foto = {
      persona: resp.data,
      loading: false,
    }
  }

  const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date()
    const [anio, mes, dia] = fechaNacimiento.split("-").map(Number)

    const nacimiento = new Date(anio, mes - 1, dia)

    if (isNaN(nacimiento.getTime())) {
      return ""
    }

    let edad = hoy.getFullYear() - nacimiento.getFullYear()

    const mesNacimiento = nacimiento.getMonth()
    const diaNacimiento = nacimiento.getDate()
    const mesActual = hoy.getMonth()
    const diaActual = hoy.getDate()

    if (
      mesActual < mesNacimiento ||
      (mesActual === mesNacimiento && diaActual < diaNacimiento)
    ) {
      edad--
    } else {
      console.log("Día actual: " + diaActual)
      console.log("Día nacimiento: " + diaNacimiento)
    }

    if (edad < 0 || edad > 130) {
      return ""
    } else {
      edad += " AÑOS"
    }

    return edad
  }

  buscarPersona()
</script>

<div class="row">
  <div class="col-12 col-sm-12 col-md-6">
    {#if data.loading === true}
      <Spinner />
    {:else if data.persona !== undefined}
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Nombres</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">
            {data.persona.paterno}
            {data.persona.materno}, {data.persona.nombres}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Documento</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.documento}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Nacimiento</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.nacimiento}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Edad</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.edad}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Nivel de Educación</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.nivel_educacion}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Ubicación</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.ubicacion}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Nacionalidad</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.nacionalidad}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Sexo</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.sexo}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Estado Civil</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.estado_civil}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Madre</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.madre}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Padre</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">{data.persona.padre}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <p class="text-secondary mb-0">Ubigeo Nacimiento</p>
        </div>
        <div class="col-12 col-sm-8">
          <p class="mb-0">
            {data.persona.lugar_nacimiento == null
              ? ""
              : data.persona.lugar_nacimiento}
          </p>
        </div>
      </div>
    {/if}
  </div>
  <div class="col-12 col-sm-12 col-md-6">
    {#if data_foto.loading === true}
      <Spinner />
    {:else if data_foto.persona !== undefined}
      {#if data_foto.persona.length === 0}
        <SinInformacion />
      {:else}
        <div class="row">
          <div
            class="col-12 col-sm-5 d-flex justify-content-center align-items-end p-0"
          >
            <div>
              <img
                class="img-fluid"
                src="data:image/jpeg;base64,{data_foto.persona.firma}"
                alt="Firma"
              />
              <p class="text-center text-secondary mb-0">Firma</p>
            </div>
          </div>
          <div
            class="col-12 col-sm-3 d-flex justify-content-center align-items-end p-0"
          >
            <div>
              <img
                width="125"
                class="img-fluid"
                src="data:image/jpeg;base64,{data_foto.persona.indicederecho}"
                alt="Índice"
              />
              <p class="text-center text-secondary mb-0">Índice derecho</p>
            </div>
          </div>
          <div class="col text-center">
            <img
              class="img-fluid"
              src="data:image/jpeg;base64,{data_foto.persona.foto}"
              alt="Foto"
            />
          </div>
        </div>
      {/if}
    {:else if $user.permisos.includes("foto")}
      <div class="h-100 d-flex justify-content-center align-items-center">
        <button class="btn btn-sm btn-primary" on:click={handleFoto}
          ><i class="bi bi-eye" /> Ver Firma y Foto</button
        >
      </div>
    {/if}
  </div>
</div>
