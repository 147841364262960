<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"

  export let ruc
  export let documento = ""

  let data = {
    empresa: undefined,
    loading: false,
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    let ops = "general_correos"
    let url = `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}`
    data.loading = true
    if (documento != "") {
      ops = "adicional_empresa_general_correos"
      url = `${BASE_URL}persona.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}&documento=${documento}`
    }
    const response = await fetch(url)
    const resp = await response.json()
    data = {
      empresa: resp.data,
      loading: false,
    }
  }

  let encabezados = ["Correo", "Fuente", "Fecha de actualización"]

  cargarDatos()
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.empresa as correo}
          <tr>
            <td>{correo.correo.toLowerCase()}</td>
            <td>{correo.fuente}</td>
            <td>{correo.fecha}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}
