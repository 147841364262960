<script>
  export let licencias = []
</script>

<div class="row">
  {#each licencias as licencia}
    <div class="col-12 {licencias.length > 1 ? 'col-md-6' : ''}">
      <p class="fs-6 mb-1">
        {#if licencia.tipo == "A"}
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"
            ><path
              d="M10 37.8v2.7q0 .65-.425 1.075Q9.15 42 8.5 42h-1q-.65 0-1.075-.425Q6 41.15 6 40.5V24.3l4.25-12.8q.25-.7.825-1.1.575-.4 1.325-.4h23.2q.75 0 1.325.4t.825 1.1L42 24.3v16.2q0 .65-.425 1.075Q41.15 42 40.5 42h-1.05q-.65 0-1.05-.425T38 40.5v-2.7Zm.15-16.5h27.7L35.1 13H12.9ZM9 24.3v10.5Zm5.3 8q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.975-.775-.825-1.925-.825t-1.975.825q-.825.825-.825 1.975 0 1.15.825 1.925.825.775 1.975.775Zm19.45 0q1.15 0 1.975-.8.825-.8.825-1.9 0-1.15-.825-1.975-.825-.825-1.975-.825-1.15 0-1.925.825-.775.825-.775 1.975 0 1.15.8 1.925.8.775 1.9.775ZM9 34.8h30V24.3H9Z"
            /></svg
          >
        {:else}
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"
            ><path
              d="M7.85 38Q4.6 38 2.3 35.725T0 30.15q0-3.3 2.275-5.575Q4.55 22.3 7.8 22.3l-2.35-1.8H0V18h9.05L14 21l7.95-3h7l-3.9-5H20.6v-3h5.95l4.3 5.55 6.45-3.2v5.2h-4.9l4.25 5.5q.85-.35 1.7-.525.85-.175 1.75-.175 3.3 0 5.6 2.275T48 30.2q0 3.3-2.3 5.55T40.1 38q-3.25 0-5.525-2.275Q32.3 33.45 32.3 30.2q0-1.5.5-2.85t1.5-2.45l-1.75-2.25-7.3 11.15h-5.5l-4.05-3.65q0 3.3-2.275 5.575Q11.15 38 7.85 38Zm0-3q2.05 0 3.45-1.425 1.4-1.425 1.4-3.425 0-2-1.4-3.425Q9.9 25.3 7.85 25.3q-2 0-3.425 1.4Q3 28.1 3 30.15q0 2.05 1.425 3.45Q5.85 35 7.85 35Zm14.7-14-8.65 3.25L22.55 21h7.5ZM40.1 35q2.05 0 3.475-1.4Q45 32.2 45 30.2q0-2.05-1.425-3.475Q42.15 25.3 40.1 25.3q-2 0-3.4 1.425T35.3 30.2q0 2 1.4 3.4t3.4 1.4Zm-16.45-4.2 6.4-9.8h-7.5l-8.65 3.25 6.95 6.55Z"
            /></svg
          >
        {/if}
        <span class="ms-2">
          {licencia.categoria} -
        </span>
        {#if licencia.estado === "VIGENTE"}
          <span class="text-success"> {licencia.estado} </span>
        {:else if licencia.estado === "VENCIDO"}
          <span class="text-warning"> {licencia.estado} </span>
        {:else}
          <span class="text-danger"> {licencia.estado} </span>
        {/if}
      </p>
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <tbody>
            <tr>
              <th class="text-secondary fw-normal">Nro. de licencia</th>
              <td colspan="3">{licencia.licencia}</td>
            </tr>
            <tr>
              <th class="text-secondary fw-normal">Clase y Categoría</th>
              <td colspan="3">{licencia.categoria}</td>
            </tr>
            <tr>
              <th class="text-secondary fw-normal">Vigente hasta</th>
              <td colspan="3">{licencia.vigencia}</td>
            </tr>
            <tr>
              <th class="text-secondary fw-normal">Restricciones</th>
              <td colspan="3">{licencia.restricciones}</td>
            </tr>
            <tr>
              <th class="text-secondary fw-normal">Estado de la licencia</th>
              <td colspan="3">{licencia.estado}</td>
            </tr>
            <tr>
              <th class="text-secondary fw-normal">Fecha de actualización</th>
              <td colspan="3">{licencia.fecha_consulta}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br />
  {/each}
</div>
