<script>
  export let color = "#fff"
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  width="32"
  height="32"
  version="1.1"
  style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 650000 650000"
>
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer" />
    <circle
      stroke={color}
      stroke-width="25400"
      fill="none"
      cx="325000"
      cy="325000"
      r="305000"
    />
    <circle fill={color} cx="324999" cy="325000" r="228750" />
  </g>
</svg>
