<script>
  import { navigate } from "svelte-routing"
  import Logo from "../images/Logo.svelte"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  let email = ""
  let password = ""

  let data_user = {
    user: undefined,
    message: undefined,
    loading: false,
  }

  const login = async () => {
    if (!email.trim() || !password.trim()) {
      console.log("Campos vacios")
      return
    }

    data_user.loading = true
    let formData = new FormData()
    formData.append("usuario", email)
    formData.append("clave", password)

    const response = await fetch(`${BASE_URL}session.php`, {
      method: "POST",
      body: formData,
    })

    const resp = await response.json()

    data_user = {
      user: resp.data,
      loading: false,
    }

    if (resp.status === "success") {
      user.setUser(data_user.user)
      navigate("/inicio", {
        replace: true,
      })
    } else {
      data_user.message = resp.message
    }
  }
</script>

<div
  class="Login text-center vh-100 d-flex justify-content-center align-items-center"
>
  <form
    on:submit|preventDefault={login}
    class="p-5 text-secondary bg-white shadow-sm rounded form-login"
  >
    <div class="mb-1">
      <Logo />
    </div>
    <p class="mb-3">Sistema de Búsquedas Integradas</p>
    <h3 class="mb-3 text-dark">Acceder</h3>
    <input
      class="form-control mb-2"
      type="text"
      placeholder="Ingrese su usuario"
      bind:value={email}
    />
    <input
      class="form-control mb-2"
      type="password"
      placeholder="Ingrese su contraseña"
      bind:value={password}
    />
    <button
      class="form-control btn btn-primary {data_user.loading ? 'disabled' : ''}"
      type="submit">{data_user.loading ? "Cargando" : "Ingresar"}</button
    >

    {#if data_user.message !== undefined}
      <div
        class="alert {data_user.message !== 'Bienvenido'
          ? 'alert-danger'
          : 'alert-success'} mt-2 small"
        role="alert"
      >
        {data_user.message}
      </div>
    {/if}
  </form>
</div>

<style>
  .form-login {
    max-width: 400px;
  }
</style>
