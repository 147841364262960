<script>
  import uuid from "uuid-v4"
  import Accordion from "../Accordion.svelte"
  import GeneralCorreos from "./General_correos.svelte"
  import GeneralDatos from "./General_datos.svelte"
  import GeneralDirecciones from "./General_direcciones.svelte"
  import GeneralEmpleados from "./General_empleados.svelte"
  import GeneralRepresentantes from "./General_representantes.svelte"
  import GeneralTelefonos from "./General_telefonos.svelte"
  import GeneralVehiculos from "./General_vehiculos.svelte"

  export let ruc

  let load = {
    correos: false,
    representantes: false,
    direcciones: false,
    correos: false,
    telefonos: false,
    empleados: false,
    vehiculos: false,
  }

  const click = (ops) => {
    switch (ops) {
      case "correos":
        load.correos = true
        break
      case "representantes":
        load.representantes = true
        break
      case "direcciones":
        load.direcciones = true
        break
      case "correos":
        load.correos = true
        break
      case "telefonos":
        load.telefonos = true
        break
      case "empleados":
        load.empleados = true
        break
      case "vehiculos":
        load.vehiculos = true
        break
    }
  }
</script>

<div>
  <Accordion title="Datos Generales" idx={uuid()} show={true}>
    <GeneralDatos {ruc} />
  </Accordion>
  <Accordion
    title="Representantes"
    idx={uuid()}
    on:click={() => click("representantes")}
  >
    {#if load.representantes}
      <GeneralRepresentantes {ruc} />
    {/if}
  </Accordion>
  <Accordion
    title="Direcciones"
    idx={uuid()}
    on:click={() => click("direcciones")}
  >
    {#if load.direcciones}
      <GeneralDirecciones {ruc} />
    {/if}
  </Accordion>
  <Accordion title="Correos" idx={uuid()} on:click={() => click("correos")}>
    {#if load.correos}
      <GeneralCorreos {ruc} />
    {/if}
  </Accordion>
  <Accordion title="Teléfonos" idx={uuid()} on:click={() => click("telefonos")}>
    {#if load.telefonos}
      <GeneralTelefonos {ruc} />
    {/if}
  </Accordion>

  <Accordion title="Empleados" idx={uuid()} on:click={() => click("empleados")}>
    {#if load.empleados}
      <GeneralEmpleados {ruc} />
    {/if}
  </Accordion>

  <Accordion title="Vehículos" idx={uuid()} on:click={() => click("vehiculos")}>
    {#if load.vehiculos}
      <GeneralVehiculos {ruc} />
    {/if}
  </Accordion>
</div>
