<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import { navigate } from "svelte-routing"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"

  export let ruc
  export let documento = ""

  let data = {
    empresa: undefined,
    loading: true,
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    let ops = "general_datos"
    let url = `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}`
    data.loading = true
    if (documento != "") {
      ops = "adicional_empresa_general_datos"
      url = `${BASE_URL}persona.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}&documento=${documento}`
    }

    const response = await fetch(url)
    const resp = await response.json()

    if (resp.data.error_id !== undefined) {
      alert(resp.data.message)
      navigate("/", { replace: true })
    }

    data = {
      empresa: resp.data,
      loading: false,
    }
  }

  setTimeout(function () {
    cargarDatos()
  }, 2000)
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <tbody>
        <tr>
          <th class="text-secondary fw-normal">RUC</th>
          <td colspan="3">{data.empresa.ruc}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Razón social</th>
          <td colspan="3">{data.empresa.razonsocial}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Comercial</th>
          <td colspan="3">{data.empresa.nombrecomercial}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Tipo</th>
          <td colspan="3">{data.empresa.tipo}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Giro</th>
          <td colspan="3">{data.empresa.giro}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Fecha Inscripción</th>
          <td colspan="3">{data.empresa.fecha_inscripcion}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Fecha Inicio</th>
          <td>{data.empresa.fecha_inicio}</td>
          <th class="text-secondary fw-normal">Fecha Baja</th>
          <td>{data.empresa.fecha_baja}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Estado</th>
          <td>{data.empresa.estado}</td>
          <th class="text-secondary fw-normal">Condición</th>
          <td>{data.empresa.condicion}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Comercio Exterior</th>
          <td colspan="3">{data.empresa.comercio}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Fecha de actualización</th>
          <td colspan="3">{data.empresa.fecha_consulta}</td>
        </tr>
      </tbody>
    </table>
  </div>
{/if}
