<script>
  import uuid from "uuid-v4"
  import Accordion from "../Accordion.svelte"
  import GeneralDatos from "./General_datos.svelte"
  import GeneralDirecciones from "./General_direcciones.svelte"
  import GeneralTelefonos from "./General_telefonos.svelte"
  import GeneralProfesionales from "./General_profesionales.svelte"
  import GeneralCorreos from "./General_correos.svelte"
  import GeneralIndependientes from "./General_independientes.svelte"
  import GeneralCargos from "./General_cargos.svelte"
  import GeneralFamiliares from "./General_familiares.svelte"
  import GeneralVehiculos from "./General_vehiculos.svelte"

  export let documento

  let load = {
    correos: false,
    direcciones: false,
    profesionales: false,
    telefonos: false,
    independientes: false,
    cargos: false,
    familiares: false,
    vehiculos: false,
  }

  const click = (ops) => {
    switch (ops) {
      case "correos":
        load.correos = true
        break
      case "direcciones":
        load.direcciones = true
        break
      case "profesionales":
        load.profesionales = true
        break
      case "telefonos":
        load.telefonos = true
        break
      case "independientes":
        load.independientes = true
        break
      case "cargos":
        load.cargos = true
        break
      case "familiares":
        load.familiares = true
        break
      case "vehiculos":
        load.vehiculos = true
        break
      default:
        break
    }
  }
</script>

<div>
  <Accordion title="Datos Generales" idx={uuid()} show={true}>
    <GeneralDatos {documento} />
  </Accordion>
  <Accordion
    title="Grados académicos y títulos profesionales"
    idx={uuid()}
    on:click={() => click("profesionales")}
  >
    {#if load.profesionales}
      <GeneralProfesionales {documento} />
    {/if}
  </Accordion>
  <Accordion
    title="Direcciones"
    idx={uuid()}
    on:click={() => click("direcciones")}
  >
    {#if load.direcciones}
      <GeneralDirecciones {documento} />
    {/if}
  </Accordion>
  <Accordion title="Correos" idx={uuid()} on:click={() => click("correos")}>
    {#if load.correos}
      <GeneralCorreos {documento} />
    {/if}
  </Accordion>
  <Accordion title="Teléfonos" idx={uuid()} on:click={() => click("telefonos")}>
    {#if load.telefonos}
      <GeneralTelefonos {documento} />
    {/if}
  </Accordion>
  <Accordion
    title="Independientes"
    idx={uuid()}
    on:click={() => click("independientes")}
  >
    {#if load.independientes}
      <GeneralIndependientes {documento} />
    {/if}
  </Accordion>
  <Accordion title="Cargos" idx={uuid()} on:click={() => click("cargos")}>
    {#if load.cargos}
      <GeneralCargos {documento} />
    {/if}
  </Accordion>
  <Accordion
    title="Familiares"
    idx={uuid()}
    on:click={() => click("familiares")}
  >
    {#if load.familiares}
      <GeneralFamiliares {documento} />
    {/if}
  </Accordion>
  <Accordion title="Vehículos" idx={uuid()} on:click={() => click("vehiculos")}>
    {#if load.vehiculos}
      <GeneralVehiculos {documento} />
    {/if}
  </Accordion>
</div>
