<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import ResultadoPersona from "../components/persona/Resultado_persona.svelte"
  import SinInformacion from "../components/Sin_informacion.svelte"
  import Spinner from "../components/Spinner.svelte"
  import { verifySession } from "../services/verifySession"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  let documento = ""
  let nombres = ""
  let paterno = ""
  let materno = ""
  let numero = ""

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
      getPuntos()
      if (!$user.permisos.includes("persona")) {
        user.setUser(null)
        navigate("/", { replace: true })
      }

      //Pass
      if ($user.actualizar_clave == "1") {
        navigate("/seguridad", { replace: true })
      }
    }
  })

  let data = {
    personas: undefined,
    loading: false,
  }

  const buscarPersona = async () => {
    verifySession($user.session_key)
    if (!validarFormulario()) {
      alert("Completar campos")
      return
    }
    reiniciarTiempo()
    let session_key = $user.session_key
    let url = `${BASE_URL}persona.php?documento=${documento}&paterno=${paterno}&materno=${materno}&nombres=${nombres}&numero=${numero}&session_key=${session_key}`
    data.loading = true
    const response = await fetch(url)
    const resp = await response.json()
    data = {
      personas: resp.data,
      loading: false,
    }
  }

  const validarFormulario = () => {
    let validation = false

    documento = documento.trim()
    nombres = nombres.trim()
    paterno = paterno.trim()
    materno = materno.trim()
    numero = numero.trim()

    if (documento.length > 5) validation = true
    if (numero.length > 5) validation = true
    if (nombres != "" && paterno != "" && materno != "") validation = true

    return validation
  }

  const limpiar = () => {
    documento = ""
    nombres = ""
    paterno = ""
    materno = ""
    numero = ""
  }

  let bloquados = {
    documento: false,
    nombres: false,
    paterno: false,
    materno: false,
    numero: false,
  }

  $: if (documento != "") {
    bloquados = {
      documento: false,
      nombres: true,
      paterno: true,
      materno: true,
      numero: true,
    }
  } else {
    bloquados = {
      documento: false,
      nombres: false,
      paterno: false,
      materno: false,
      numero: false,
    }
  }

  $: if (nombres != "" || paterno != "" || materno != "") {
    bloquados = {
      documento: true,
      nombres: false,
      paterno: false,
      materno: false,
      numero: true,
    }
  } else {
    bloquados = {
      documento: false,
      nombres: false,
      paterno: false,
      materno: false,
      numero: false,
    }
  }

  $: if (numero != "") {
    bloquados = {
      documento: true,
      nombres: true,
      paterno: true,
      materno: true,
      numero: false,
    }
  } else {
    bloquados = {
      documento: false,
      nombres: false,
      paterno: false,
      materno: false,
      numero: false,
    }
  }

  const getPuntos = async () => {
    const response = await fetch(`${BASE_URL}session.php?session_key=${$user.session_key}`)
    const resp = await response.json()

    if (resp.status === "success") {
      let temp_user = $user
      temp_user.consumo = resp.data.consumo
      temp_user.consultas = resp.data.consultas
      temp_user.inicio = resp.data.inicio
      temp_user.fin = resp.data.fin
      user.setUser(temp_user)
    } else {
      navigate("/", { replace: true })
    }
  }

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />

    <div class="container-fluid py-3 bg-light">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h5 class="text-center">Búsqueda de Personas</h5>
              <form on:submit|preventDefault={buscarPersona}>
                <label for="inputDocumento" class="form-label small text-secondary">Documento</label>
                <input type="text" id="inputDocumento" class="form-control form-control-sm" bind:value={documento} readonly={bloquados.documento} />

                <label for="inputNombres" class="form-label small text-secondary">Nombres</label>
                <input type="text" id="inputNombres" class="form-control form-control-sm" bind:value={nombres} readonly={bloquados.nombres} />

                <label for="inputApellidoPaterno" class="form-label small text-secondary">Apellido Paterno</label>
                <input type="text" id="inputApellidoPaterno" class="form-control form-control-sm" bind:value={paterno} readonly={bloquados.paterno} />

                <label for="inputApellidoMaterno" class="form-label small text-secondary">Apellido Materno</label>
                <input type="text" id="inputApellidoMaterno" class="form-control form-control-sm" bind:value={materno} readonly={bloquados.materno} />

                <label for="inputNumero" class="form-label small text-secondary">Número Telefónico</label>
                <input type="text" id="inputNumero" class="form-control form-control-sm" bind:value={numero} readonly={bloquados.numero} />

                <div class="d-flex gap-2 my-2">
                  {#if data.loading}
                    <button type="submit" class="w-100 btn btn-primary btn-sm disabled"> Buscando... </button>
                  {:else}
                    <button type="submit" class="w-100 btn btn-primary btn-sm"> Buscar </button>
                  {/if}
                  <button type="button" class="w-100 btn btn-primary btn-sm" on:click={limpiar}>Limpiar</button>
                </div>
              </form>
            </div>
            <div class="col-12 col-lg-6">
              {#if data.loading === true}
                <Spinner message="Buscando" />
              {:else if data.personas !== undefined}
                {#if data.personas.error_id === undefined}
                  <ResultadoPersona personas={data.personas} />
                {:else if data.personas.message == "No tiene consultas disponibles"}
                  <SinInformacion message={data.personas.message} />
                {:else}
                  <SinInformacion />
                {/if}
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
{/if}
