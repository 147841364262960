<script>
  import { createEventDispatcher } from "svelte"

  export let title = ""
  export let message = ""
  export let index = 0
  export let cupon = "000000"
  export let tipo = ""

  let isClosed = false

  const closeAlert = () => {
    isClosed = true
  }

  const dispatch = createEventDispatcher()

  const deleteNotificacion = () => {
    dispatch("delete", {
      index: index,
    })
  }

  message =
    message +
    ` <style>
      p,li {
        margin-bottom:5px;
      }
    </style>`
</script>

<div
  class="alert alert-warning alert-dismissible fade {isClosed
    ? ''
    : 'show'} small"
  role="alert"
>
  {#if tipo == "descuento"}
    <h4 class="alert-heading fs-5 mb-3">(Desc-{cupon}) {title}</h4>
  {:else}
    <h4 class="alert-heading fs-5 mb-3">{title}</h4>
  {/if}

  {@html message}
  <button
    type="button"
    class="btn-close"
    on:click={closeAlert}
    on:click={deleteNotificacion}
  />
</div>
