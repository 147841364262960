<script>
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"
  import getMoneda from "../../services/moneda.js"
  import { BASE_URL } from "../../stores/BASE_URL"
  import { user } from "../../stores/User"
  import { onMount } from "svelte"

  export let documento

  let data = {
    persona: [],
    loading: false,
  }
  let meses = []
  let mes = "1"

  const session_key = $user.session_key
  const ops = "resumen_financiero"

  const updateMeses = async () => {
    const ops = "getMesesResumenFinanciero"
    try {
      const response = await fetch(`${BASE_URL}info.php?ops=${ops}`)
      const resp = await response.json()

      if (Array.isArray(resp.data) && resp.data.length > 0) {
        meses = resp.data
      } else {
        console.error("No se recibieron meses válidos.")
        meses = []
      }

      await cargarDatos()
    } catch (error) {
      console.error("Error al obtener meses:", error)
    }
  }

  const cargarDatos = async () => {
    const cliente = `cliente${mes}`
    const clientedetalle = `clientedetalle${mes}`
    data.loading = true

    try {
      const response = await fetch(
        `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}&cliente=${cliente}&clientedetalle=${clientedetalle}`,
      )
      const resp = await response.json()
      data = {
        persona: Array.isArray(resp.data) ? resp.data : [],
        loading: false,
      }
    } catch (error) {
      console.error("Error al cargar datos:", error)
      data.loading = false
    }
  }

  const encabezados = [
    "Entidad",
    "Linea Aprobada TC",
    "Linea Disponible TC",
    "Deuda TC",
    "Vehicular",
    "Hipotecario",
    "Comercial",
    "Préstamo",
    "Convenio",
    "Otros",
  ]

  onMount(updateMeses)
</script>

<div class="row mb-2">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <select class="form-select form-select-sm" bind:value={mes} on:change={cargarDatos}>
      {#if meses.length > 0}
        {#each meses as m}
          <option value={m.key}>{m.value}</option>
        {/each}
      {:else}
        <option disabled>No hay meses disponibles</option>
      {/if}
    </select>
  </div>
</div>

{#if data.loading}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead class="text-center">
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.persona as resumen}
          <tr class="text-end">
            <td class="text-start">{resumen.entidad}</td>
            {#each [resumen.linea_aprobada, resumen.linea_disponible, resumen.tarjeta, resumen.vehicular, resumen.hipotecario, resumen.comercial, resumen.prestamo, resumen.convenio, resumen.otros] as monto}
              <td>{getMoneda(monto)}</td>
            {/each}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}
