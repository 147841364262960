<script>
  import { navigate } from "svelte-routing"
  export let personas

  const cargarPersona = (documento) => {
    navigate(`/persona/${documento}`, { replace: true })
  }
</script>

<div class="table-responsive small">
  <table class="table table-striped table-sm table-hover table-pointer">
    <thead>
      <tr>
        <th class="text-secondary fw-normal">Documento</th>
        <th class="text-secondary fw-normal">Nombres</th>
        <th class="text-secondary fw-normal" />
      </tr>
    </thead>
    <tbody>
      {#each personas as persona}
        <tr on:click={() => cargarPersona(persona.documento)}>
          <td>{persona.documento}</td>
          <td>{persona.paterno} {persona.materno} {persona.nombres}</td>
          <td class="text-end text-primary">
            <i class="bi bi-search" />
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
