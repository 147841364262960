<script>
  import uuid from "uuid-v4"
  import Accordion from "../Accordion.svelte"
  import DeudasGrafico from "./Deudas_grafico.svelte"
  import DeudasDetalle from "./Deudas_detalle.svelte"
  import DeudasUtilizacion from "./Deudas_utilizacion.svelte"

  export let ruc

  let load = {
    deudas: false,
    detalle: false,
    utilizacion: false,
  }

  const click = (ops) => {
    switch (ops) {
      case "deudas":
        load.deudas = true
        break
      case "detalle":
        load.detalle = true
        break
      case "utilizacion":
        load.utilizacion = true
        break
      default:
        break
    }
  }
</script>

<div>
  <Accordion title="Gráfico" idx={uuid()} on:click={() => click("deudas")}>
    {#if load.deudas}
      <DeudasGrafico {ruc} />
    {/if}
  </Accordion>
  <Accordion title="Detalle" idx={uuid()} on:click={() => click("detalle")}>
    {#if load.detalle}
      <DeudasDetalle {ruc} />
    {/if}
  </Accordion>
  <Accordion
    title="Utilización"
    idx={uuid()}
    on:click={() => click("utilizacion")}
  >
    {#if load.utilizacion}
      <DeudasUtilizacion {ruc} />
    {/if}
  </Accordion>
</div>
