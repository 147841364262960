<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import { verifySession } from "../services/verifySession"
  import { user } from "../stores/User"
  import Alert from "../components/notificaciones/Alert.svelte"
  import { BASE_URL } from "../stores/BASE_URL"

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
    }
  })

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  let notificaciones = []

  const getNotificaciones = async () => {
    let id_cliente = $user.id_cliente
    let session_key = $user.session_key
    const response = await fetch(
      `${BASE_URL}notificacion.php?session_key=${session_key}&id_cliente=${id_cliente}`
    )
    const resp = await response.json()

    if (resp.status == "ok") {
      notificaciones = resp.data
    } else {
      notificaciones = []
    }
  }

  const deleteNotificacion = (e) => {
    notificaciones = notificaciones.filter(
      (_, index) => index !== e.detail.index
    )
  }

  getNotificaciones()
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />
    <div class="container-fluid bg-light">
      <div class="row h-100">
        <div
          class="col-12 {notificaciones.length > 0
            ? ' col-md-6 '
            : ' col-md-12 '} py-3"
        >
          <div
            class="bienvenido d-flex align-items-center justify-content-center bg-white"
          >
            <h3 class="fs-4">
              <span class="text-secondary">Bienvenido </span>
              {$user.nombres}
            </h3>
          </div>
        </div>
        <div class="col-12 col-md-6 py-3">
          {#each notificaciones as { titulo, mensaje, tipo }, index}
            <Alert
              {index}
              title={titulo}
              message={mensaje}
              {tipo}
              cupon={String($user.id).padStart(6, "0")}
              on:delete={deleteNotificacion}
            />
          {/each}
        </div>
      </div>
    </div>

    <Footer />
  </main>
{/if}

<style>
  /* alto minimo de .bienvenido alto de la pantalla - (alto de Header + alto de Navbar + alto de Footer) */
  .bienvenido {
    min-height: calc(100vh - (100px + 200px));
  }
</style>
