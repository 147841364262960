<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import { verifySession } from "../services/verifySession"
  import { user } from "../stores/User"
  import { BASE_URL } from "../stores/BASE_URL"

  let isLoading = false

  let claves = {
    clave_actual: {
      value: "",
      error: false,
      message: "",
    },
    clave_nueva: {
      value: "",
      error: false,
      message: "",
    },
    clave_nueva_confirmacion: {
      value: "",
      error: false,
      message: "",
    },
  }

  let isCorrect = true

  const validar = () => {
    isCorrect = true

    // Validar campos vacios
    if (claves.clave_actual.value.trim() === "") {
      claves.clave_actual.error = true
      claves.clave_actual.message = "Campo vacio"
      isCorrect = false
    } else {
      claves.clave_actual.error = false
      claves.clave_actual.message = ""
    }

    if (claves.clave_nueva.value.trim() === "") {
      claves.clave_nueva.error = true
      claves.clave_nueva.message = "Campo vacio"
      isCorrect = false
    } else {
      claves.clave_nueva.error = false
      claves.clave_nueva.message = ""
    }

    if (claves.clave_nueva_confirmacion.value.trim() === "") {
      claves.clave_nueva_confirmacion.error = true
      claves.clave_nueva_confirmacion.message = "Campo vacio"
      isCorrect = false
    } else {
      claves.clave_nueva_confirmacion.error = false
      claves.clave_nueva_confirmacion.message = ""
    }

    // Validar claves iguales
    if (claves.clave_nueva.value !== claves.clave_nueva_confirmacion.value) {
      claves.clave_nueva_confirmacion.error = true
      claves.clave_nueva_confirmacion.message = "Claves no coinciden"
      isCorrect = false
    } else {
      if (!claves.clave_nueva_confirmacion.error) {
        claves.clave_nueva_confirmacion.error = false
        claves.clave_nueva_confirmacion.message = ""
      }
    }
    // Validar clave actual

    // Validar clave nueva
    if (!validarCaracteres(claves.clave_nueva.value)) {
      claves.clave_nueva.error = true
      claves.clave_nueva.message =
        "La clave debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial"
      isCorrect = false
    } else {
      claves.clave_nueva.error = false
      claves.clave_nueva.message = ""
    }

    console.log(isCorrect)
    return isCorrect
  }

  const validarCaracteres = (clave) => {
    let re = /^(?=.*\d)(?=.*[!*/#%@$_&-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    return re.test(clave)
  }

  const actualizarClave = async () => {
    reiniciarTiempo()
    isLoading = true
    if (validar()) {
      let formData = new FormData()
      formData.append("usuario", $user.usuario)
      formData.append("clave", claves.clave_actual.value)
      formData.append("clave_nueva", claves.clave_nueva.value)
      formData.append("session_key", $user.session_key)
      const response = await fetch(`${BASE_URL}session.php`, {
        method: "POST",
        body: formData,
      })
      const resp = await response.json()
      if (resp.status === "ok") {
        setTimeout(() => {
          navigate("/", {
            replace: true,
          })
        }, 1000)
      } else {
        claves.clave_actual.error = true
        claves.clave_actual.message = "Clave incorrecta"
      }
    }
    isLoading = false
  }

  const cancelar = () => {
    reiniciarTiempo()
    claves = {
      clave_actual: {
        value: "",
        error: false,
        message: "",
      },
      clave_nueva: {
        value: "",
        error: false,
        message: "",
      },
      clave_nueva_confirmacion: {
        value: "",
        error: false,
        message: "",
      },
    }
  }

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
    }

    if (!$user.permisos.includes("configuracion")) {
      user.setUser(null)
      navigate("/", { replace: true })
    }
  })

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />
    <div class="container-fluid py-3 bg-light">
      <div class="row g-2">
        <div class="col-12 col-sm-12 col-md-6 col-lg-7">
          <div class="card shadow-sm">
            <div class="card-body">
              <h5 class="text-center mb-3">Datos del usuario</h5>
              <div class="table-responsive">
                <table class="table table-striped table-sm small">
                  <tbody>
                    <tr>
                      <th class="text-secondary fw-normal">Empresa:</th>
                      <td>{$user.empresa}</td>
                    </tr>
                    <tr>
                      <th class="text-secondary fw-normal">Nombres:</th>
                      <td>{$user.nombres}</td>
                    </tr>
                    <tr>
                      <th class="text-secondary fw-normal"
                        >Consultas contratadas:</th
                      >
                      <td>{Number($user.consultas).toLocaleString("es-PE")}</td>
                    </tr>
                    <tr>
                      <th class="text-secondary fw-normal"
                        >Consultas disponibles:</th
                      >
                      <td
                        >{Number(
                          $user.consultas - $user.consumo
                        ).toLocaleString("es-PE")}</td
                      >
                    </tr>
                    <tr>
                      <th class="text-secondary fw-normal">Vencimiento:</th>
                      <td>{$user.fin}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-5">
          <div class="card shadow-sm">
            <div class="card-body">
              <h5 class="text-center mb-3">Cambiar contraseña</h5>

              <div class="mb-2">
                <label class="d-block">
                  <span class="form-label small text-secondary">
                    Contraseña actual
                  </span>
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    bind:value={claves.clave_actual.value}
                  />
                </label>
                {#if claves.clave_actual.error}
                  <div class="form-text text-danger">
                    <small>{claves.clave_actual.message}</small>
                  </div>
                {/if}
              </div>

              <div class="mb-2">
                <label class="d-block">
                  <span class="form-label small text-secondary">
                    Nueva contraseña
                  </span>
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    bind:value={claves.clave_nueva.value}
                  />
                </label>
                {#if claves.clave_nueva.error}
                  <div class="form-text text-danger">
                    <small>{claves.clave_nueva.message}</small>
                  </div>
                {/if}
              </div>

              <div class="mb-2">
                <label class="d-block">
                  <span class="form-label small text-secondary">
                    Confirmar nueva contraseña
                  </span>
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    bind:value={claves.clave_nueva_confirmacion.value}
                  />
                </label>
                {#if claves.clave_nueva_confirmacion.error}
                  <div class="form-text text-danger">
                    <small>{claves.clave_nueva_confirmacion.message}</small>
                  </div>
                {/if}
              </div>

              <div class="d-grid gap-2 d-md-block mb-2">
                {#if isLoading}
                  <button class="btn btn-primary btn-sm disabled">
                    Actualizando Contraseña...
                  </button>
                {:else}
                  <button
                    class="btn btn-primary btn-sm"
                    on:click={actualizarClave}
                  >
                    Cambiar contraseña
                  </button>
                {/if}
                <button class="btn btn-secondary btn-sm" on:click={cancelar}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
{/if}
