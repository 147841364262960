<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"

  export let documento

  let data = {
    persona: undefined,
    loading: false,
  }

  let session_key = $user.session_key
  const ops = "general_direcciones"

  const cargarDatos = async () => {
    data.loading = true
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()

    if (resp.status == "ok") {
      data = {
        persona: resp.data,
        loading: false,
      }
    } else {
      data.persona = null
    }
  }

  cargarDatos()

  let encabezados = [
    "Fecha",
    "Fuente",
    "Dirección",
    "Departamento",
    "Provincia",
    "Distrito",
  ]
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.persona as direccion}
          <tr>
            <td>{direccion.fecha_data}</td>
            <td>{direccion.origen}</td>
            <td>{direccion.direccion}</td>
            <td>{direccion.departamento}</td>
            <td>{direccion.provincia}</td>
            <td>{direccion.distrito}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}
