<script>
  export let redes_sociales = []
</script>

<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th class="text-secondary fw-normal">Red Social</th>
        <th class="text-secondary fw-normal">Nombre</th>
      </tr>
    </thead>
    <tbody>
      {#each redes_sociales as facebook}
        <tr>
          <td>Facebook</td>
          <td>
            <a
              href="https://www.facebook.com/profile.php?id={facebook.link}"
              target="_blank">{facebook.nombres}</a
            >
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
