<script>
  import { onMount } from "svelte"
  import { Link, navigate } from "svelte-routing"
  import { Datatable } from "svelte-simple-datatables"
  import { tweened } from "svelte/motion"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import SinInformacion from "../components/Sin_informacion.svelte"
  import Spinner from "../components/Spinner.svelte"
  import exportTxt from "../services/exportTxt"
  import { getFecha } from "../services/moneda.js"
  import { verifySession } from "../services/verifySession"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  let inicio = getFecha()
  let fin = getFecha()
  let session_key = $user.session_key
  let isDownload = false
  let consultas = $user.consultas - $user.consumo

  let isDownloadCompleto = false

  let data = {
    consultas: undefined,
    loading: false,
  }

  let data_descargar = {
    consultas: undefined,
    loading: false,
  }

  const settings = {
    sortable: true,
    pagination: true,
    rowsPerPage: 20,
    columnFilter: false,
    scrollY: false,
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: false,
    },
    labels: {
      previous: "Anterior",
      next: "Siguiente",
    },
  }

  let rows

  const buscar = async () => {
    reiniciarTiempo()
    if (!validar()) return

    let ops = "getConsultas"
    data.loading = true

    const response = await fetch(`${BASE_URL}reporte.php?session_key=${session_key}&ops=${ops}&inicio=${inicio}&fin=${fin}`)
    const resp = await response.json()

    if (resp.status == "ok") {
      data = {
        consultas: resp.data,
        loading: false,
      }
    } else {
      data.loading = false
      data.consultas = []
    }
  }

  const descargar = async () => {
    reiniciarTiempo()
    if (!validar()) return

    isDownload = true
    let ops = "getConsultas"
    data_descargar.loading = true

    const response = await fetch(`${BASE_URL}reporte.php?session_key=${session_key}&ops=${ops}&inicio=${inicio}&fin=${fin}`)
    const resp = await response.json()

    if (resp.status == "ok") {
      data_descargar = {
        consultas: resp.data,
        loading: false,
      }
    } else {
      data_descargar.loading = false
      data_descargar.consultas = []
    }

    let texto = []
    texto.push("Usuario" + "\t" + "Documento" + "\t" + "Búsqueda" + "\t" + "Cantidad" + "\t" + "Fecha")
    data_descargar.consultas.forEach((d) => {
      texto.push("\n" + d.usuario + "\t" + d.documento + "\t" + d.busqueda + "\t" + d.cantidad + "\t" + d.fecha)
    })
    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "reporte_consultas")
    isDownload = false
  }

  const descargarCompleto = async () => {
    reiniciarTiempo()
    if (!validar()) return
    isDownloadCompleto = true
    let ops = "getConsultasCompletas"
    data_descargar.loading = true

    const response = await fetch(`${BASE_URL}reporte.php?session_key=${session_key}&ops=${ops}&inicio=${inicio}&fin=${fin}`)
    const resp = await response.json()

    if (resp.status == "ok") {
      data_descargar = {
        consultas: resp.data,
        loading: false,
      }
    } else {
      data_descargar.loading = false
      data_descargar.consultas = []
    }

    let texto = []
    texto.push("Usuario" + "\t" + "Documento" + "\t" + "Búsqueda" + "\t" + "Cantidad" + "\t" + "Fecha")
    data_descargar.consultas.forEach((d) => {
      texto.push("\n" + d.usuario + "\t" + d.documento + "\t" + d.busqueda + "\t" + d.cantidad + "\t" + d.fecha)
    })
    const txt = new Blob(texto, {
      type: "text/plain",
    })

    exportTxt(txt, "reporte_consultas_completo")
    isDownloadCompleto = false
  }

  const validar = () => {
    let tmpFin = new Date(fin)
    let tmpInicio = new Date(inicio)

    let dif = tmpFin.getTime() - tmpInicio.getTime()
    let dias = Math.round(dif / (1000 * 60 * 60 * 24))

    if (dias > 31) {
      alert("El rango de fechas no puede ser mayor a 31 días")
      return false
    }

    return true
  }

  const getPuntos = async () => {
    const response = await fetch(`${BASE_URL}session.php?session_key=${$user.session_key}`)
    const resp = await response.json()

    if (resp.status === "success") {
      let temp_user = $user
      temp_user.consumo = resp.data.consumo
      temp_user.consultas = resp.data.consultas
      temp_user.inicio = resp.data.inicio
      temp_user.fin = resp.data.fin
      user.setUser(temp_user)
    } else {
      navigate("/", { replace: true })
    }
  }

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
      if (!$user.permisos.includes("reporte")) {
        user.setUser(null)
        navigate("/", { replace: true })
      }

      //Pass
      if ($user.actualizar_clave == "1") {
        navigate("/seguridad", { replace: true })
      }
    }

    getPuntos()
  })

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />
    <div class="container-fluid py-3 bg-light">
      <div class="card shadow-sm bg-white">
        <div class="card-body small">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 mb-3">
              <input type="date" class="form-control form-control-sm" bind:value={inicio} />
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 mb-3">
              <input type="date" class="form-control form-control-sm" bind:value={fin} />
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
              <div class="d-flex gap-2">
                {#if data.loading}
                  <button class="btn btn-primary btn-sm disabled">Buscando...</button>
                {:else}
                  <button class="btn btn-primary btn-sm" on:click={buscar}>Buscar</button>
                {/if}

                {#if isDownload}
                  <button class="btn btn-primary btn-sm disabled">
                    <i class="bi bi-download" /> Descargando...
                  </button>
                {:else}
                  <button class="btn btn-primary btn-sm" on:click={descargar}>
                    <i class="bi bi-download" /> Descarga individual
                  </button>
                {/if}

                {#if $user.rol == "1"}
                  {#if isDownloadCompleto}
                    <button class="btn btn-primary btn-sm disabled">
                      <i class="bi bi-download" /> Descargando...
                    </button>
                  {:else}
                    <button class="btn btn-primary btn-sm" on:click={descargarCompleto}>
                      <i class="bi bi-download" /> Descarga total
                    </button>
                  {/if}
                {/if}
              </div>
            </div>
          </div>

          {#if data.loading === true}
            <Spinner />
          {:else if data.consultas !== undefined}
            {#if data.consultas.length === 0}
              <SinInformacion />
            {:else}
              <Datatable {settings} data={data.consultas} bind:dataRows={rows}>
                <table class="table table-striped table-sm">
                  <thead>
                    <th class="text-secondary fw-normal" data-key="usuario"> Usuario </th>
                    <th class="text-secondary fw-normal" data-key="documento"> Documento </th>
                    <th class="text-secondary fw-normal" data-key="busqueda"> Búsqueda </th>
                    <th class="text-secondary fw-normal" data-key="cantidad"> Cantidad </th>
                    <th class="text-secondary fw-normal" data-key="fecha"> Fecha </th>
                  </thead>
                  <tbody>
                    {#if rows}
                      {#each $rows as row}
                        <tr>
                          <td>{row.usuario}</td>
                          {#if consultas > 0}
                            {#if row.documento.length === 8 && /\d/.test(row.documento) && (row.cantidad == 1 || row.cantidad == 0)}
                              <td>
                                <Link class="no_sub" to="/persona/{row.documento}">
                                  {row.documento}
                                </Link>
                              </td>
                            {:else if row.documento.length === 11 && /\d/.test(row.documento) && (row.cantidad == 1 || row.cantidad == 0)}
                              <td>
                                <Link class="no_sub" to="/empresa/{row.documento}">
                                  {row.documento}
                                </Link>
                              </td>
                            {:else}
                              <td>{row.documento}</td>
                            {/if}
                          {:else}
                            <td>{row.documento}</td>
                          {/if}

                          <td>{row.busqueda}</td>
                          <td>{Number(row.cantidad).toLocaleString("es-PE")}</td>
                          <td>{row.fecha}</td>
                        </tr>
                      {/each}
                    {/if}
                  </tbody>
                </table>
              </Datatable>
            {/if}
          {/if}
        </div>
      </div>
    </div>
  </main>
  <Footer />
{/if}
