<script>
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"
  import { BASE_URL } from "../../stores/BASE_URL"
  import { user } from "../../stores/User"

  export let documento

  let data = {
    persona: undefined,
    loading: false,
  }

  let session_key = $user.session_key
  const ops = "general_cargos"

  const cargarDatos = async () => {
    data.loading = true
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    data = {
      persona: resp.data,
      loading: false,
    }
  }

  cargarDatos()

  const encabezados = [
    "RUC",
    "Empresa",
    "Cargo",
    "Estado",
    "Fecha desde",
    "Actualización",
  ]
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.persona as cargo}
          <tr>
            <td>{cargo.ruc}</td>
            <td>{cargo.razonsocial}</td>
            <td>{cargo.cargo}</td>
            <td>{cargo.estado}</td>
            <td>{cargo.fecha_desde}</td>
            <td>{cargo.fecha_data.substr(0, 10)}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}
