import { writable } from "svelte/store"

const createUser = () => {
  const { subscribe, set } = writable(
    JSON.parse(localStorage.getItem("sbi-session")) || null
  )

  return {
    subscribe,
    setUser: (user) => {
      set(user)
      localStorage.setItem("sbi-session", JSON.stringify(user))
    },
  }
}

export const user = createUser()
