<script>
  import { onMount } from "svelte"
  import { navigate } from "svelte-routing"
  import { tweened } from "svelte/motion"
  import Deudas from "../components/empresa/Deudas.svelte"
  import General from "../components/empresa/General.svelte"
  import Historico from "../components/empresa/Historico.svelte"
  import Planilla from "../components/empresa/Planilla.svelte"
  import Footer from "../components/Footer.svelte"
  import Header from "../components/Header.svelte"
  import Navbar from "../components/Navbar.svelte"
  import Spinner from "../components/Spinner.svelte"
  import { verifySession } from "../services/verifySession"
  import { BASE_URL } from "../stores/BASE_URL"
  import { user } from "../stores/User"

  export let ruc

  let razon_social = ""

  let estadoInicial = 0
  let loading = true

  onMount(() => {
    if (!$user) {
      navigate("/", { replace: true })
    } else {
      verifySession($user.session_key)
      let consultas = $user.consultas - $user.consumo
      if (consultas > 0) {
        registrar()
      } else {
        navigate("/inicio", { replace: true })
      }
      if (!$user.permisos.includes("empresa")) {
        user.setUser(null)
        navigate("/", { replace: true })
      }
    }
  })

  const registrar = async () => {
    let formData = new FormData()
    formData.append("documento", ruc)
    formData.append("tipo", "empresa")
    formData.append("session_key", $user.session_key)

    const response = await fetch(`${BASE_URL}consulta.php`, {
      method: "POST",
      body: formData,
    })

    const resp = await response.json()
    razon_social = resp.data.razon_social

    if (resp.status == "error") {
      user.setUser(null)
      navigate("/", { replace: true })
    }

    loading = false
    getPuntos()
  }

  $: {
    actualizarPagina(ruc)
  }

  const actualizarPagina = (doc) => {
    if (estadoInicial > 0) {
      document.location.reload()
    }
    estadoInicial++
  }

  const getPuntos = async () => {
    const response = await fetch(
      `${BASE_URL}session.php?session_key=${$user.session_key}`
    )
    const resp = await response.json()

    if (resp.status === "success") {
      let temp_user = $user
      temp_user.consumo = resp.data.consumo
      temp_user.consultas = resp.data.consultas
      temp_user.inicio = resp.data.inicio
      temp_user.fin = resp.data.fin
      user.setUser(temp_user)
    } else {
      navigate("/", { replace: true })
    }

    reiniciarTiempo()
  }

  // Tiempo de sessión
  let original = 20 * 60
  let timer = tweened(original)

  setInterval(() => {
    if ($timer > 0) $timer--
  }, 1000)

  $: minutes = Math.floor($timer / 60)

  $: if (minutes <= 0) {
    user.setUser(null)
    navigate("/", { replace: true })
  }

  const reiniciarTiempo = () => {
    timer = tweened(original)
  }
</script>

{#if $user}
  <main>
    <Header />
    <Navbar />
    {#if loading}
      <div class="p-3">
        <Spinner />
      </div>
    {:else}
      <div class="container-fluid bg-light my-0 text-end">
        <p class="small my-1">
          <span class="text-secondary">RUC: </span>
          {ruc}
          <span class="text-secondary"> - </span>
          {razon_social}
        </p>
      </div>
      <div class="container-fluid pb-3 bg-light">
        <ul class="nav nav-tabs mb-3" id="pills-tab-e" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-general-tab-e"
              data-bs-toggle="pill"
              data-bs-target="#pills-general-e"
              type="button"
              role="tab"
              aria-controls="pills-general-e"
              aria-selected="true">Información General</button
            >
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-historico-tab-e"
              data-bs-toggle="pill"
              data-bs-target="#pills-historico-e"
              type="button"
              role="tab"
              aria-controls="pills-historico-e"
              aria-selected="false">Histórico</button
            >
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-deudas-tab-e"
              data-bs-toggle="pill"
              data-bs-target="#pills-deudas-e"
              type="button"
              role="tab"
              aria-controls="pills-deudas-e"
              aria-selected="false">Deudas</button
            >
          </li>
          <!-- {#if $user.permisos.includes("planilla1") || $user.permisos.includes("planilla2")} -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-planilla-tab-e"
              data-bs-toggle="pill"
              data-bs-target="#pills-planilla-e"
              type="button"
              role="tab"
              aria-controls="pills-planilla-e"
              aria-selected="false">Planilla</button
            >
          </li>
          <!-- {/if} -->
        </ul>
        <div class="tab-content" id="pills-tabContent-e">
          <div
            class="tab-pane fade show active"
            id="pills-general-e"
            role="tabpanel"
            aria-labelledby="pills-general-tab-e"
          >
            <General {ruc} />
          </div>
          <div
            class="tab-pane fade"
            id="pills-historico-e"
            role="tabpanel"
            aria-labelledby="pills-historico-tab-e"
          >
            <Historico {ruc} />
          </div>
          <div
            class="tab-pane fade"
            id="pills-deudas-e"
            role="tabpanel"
            aria-labelledby="pills-deudas-tab-e"
          >
            <Deudas {ruc} />
          </div>
          <div
            class="tab-pane fade"
            id="pills-planilla-e"
            role="tabpanel"
            aria-labelledby="pills-planilla-tab-e"
          >
            <Planilla {ruc} />
          </div>
        </div>
      </div>
    {/if}
  </main>
  <Footer />
{/if}
