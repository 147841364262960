<script>
	export let tip = false, pos = 'bottom';
</script>

<button class="Tooltip {pos}">
  <span class="Tooltiptext">{tip}</span>
	<slot></slot>
</button>

<style>

  .Tooltip {
    border: none;
    background: none;
    padding: 0;
    position: relative;
  }

  /* Tooltip text */
  .Tooltip .Tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 2px;
    border-radius: 6px;
    font-size: 11px;
  
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .Tooltip:hover .Tooltiptext {
    visibility: visible;
  }

  .left .Tooltiptext {
      top: 0;
      right: 105%;
  }

  .left .Tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  .right .Tooltiptext {
    top: 0;
    left: 105%;
  }

  .right .Tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }

</style>