<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import { Datatable } from "svelte-simple-datatables"
  import exportTxt from "../../services/exportTxt.js"
  import Spinner from "../../components/Spinner.svelte"

  export let ruc

  let data = {
    empresa: undefined,
    loading: false,
  }

  let session_key = $user.session_key

  let value = "planilla1"

  const cargarDatos = async () => {
    const ops = "planilla_datos"
    data.loading = true
    const response = await fetch(
      `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}&planilla=${value}`
    )
    const resp = await response.json()
    data = {
      empresa: resp.data,
      loading: false,
    }
  }

  cargarDatos()

  const settings = {
    sortable: true,
    pagination: true,
    rowsPerPage: 10,
    columnFilter: false,
    scrollY: false,
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: false,
    },
    labels: {
      previous: "Anterior",
      next: "Siguiente",
    },
  }

  let rows
  let dowloanding = false

  const descargarDatos = async () => {
    dowloanding = true
    let txt = []

    txt.push(
      "RUC" +
        "\t" +
        "Razon Social" +
        "\t" +
        "Condición" +
        "\t" +
        "Devengue" +
        "\t" +
        "Documento" +
        "\t" +
        "Paterno" +
        "\t" +
        "Materno" +
        "\t" +
        "Nombres" +
        "\t" +
        "P1" +
        "\t" +
        "Nacimiento" +
        "\t" +
        "Departamento" +
        "\t" +
        "Provincia" +
        "\t" +
        "Distrito"
    )

    const ops = "planilla_descargar"
    let url = `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}&planilla=${value}`
    const response = await fetch(url)
    const resp = await response.json()

    if (resp.status == "error") {
      alert(resp.data.message)
      dowloanding = false
      return
    }

    for (let p of resp.data) {
      txt.push(
        "\n" +
          p.ruc +
          "\t" +
          p.razonsocial +
          "\t" +
          p.condicion +
          "\t" +
          p.devengue +
          "\t" +
          p.documento +
          "\t" +
          p.paterno +
          "\t" +
          p.materno +
          "\t" +
          p.nombres +
          "\t" +
          p.ingreso +
          "\t" +
          p.nacimiento +
          "\t" +
          p.departamento +
          "\t" +
          p.provincia +
          "\t" +
          p.distrito
      )
    }

    const blob = new Blob(txt, {
      type: "text/plain",
    })

    exportTxt(blob, "planilla_" + ruc)
    dowloanding = false
  }
</script>

<div class="row mb-2 g-2">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <select
      class="form-select form-select-sm"
      bind:value
      on:change={() => cargarDatos()}
    >
      <option value="planilla1">Mes 01</option>
      <option value="planilla2">Mes 02</option>
      <option value="planilla3">Mes 03</option>
    </select>
  </div>
  <div class="col-12 col-sm-6 col-md-8 col-lg-9 text-end">
    {#if data.loading !== true}
      {#if dowloanding}
        <button class="btn btn-sm btn-primary disabled"
          ><i class="bi bi-download" /> Descargando...</button
        >
      {:else if $user.permisos.includes("planilla1") || $user.permisos.includes("planilla2")}
        <button class="btn btn-sm btn-primary" on:click={() => descargarDatos()}
          ><i class="bi bi-download" /> Descargar</button
        >
      {:else}
        <button class="btn btn-sm btn-primary disabled">
          <i class="bi bi-download" />
          Descargar
        </button>
      {/if}
    {/if}
  </div>
</div>
{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <div>
    <Datatable
      {settings}
      data={data.empresa}
      bind:dataRows={rows}      
    >
    <table class="table table-striped table-sm">
      <thead>
        <th class="text-secondary fw-normal text-start" data-key="condicion"
          >Condición</th
        >
        <th class="text-secondary fw-normal text-start" data-key="devengue"
          >Devengue</th
        >
        <th class="text-secondary fw-normal text-start" data-key="documento"
          >Documento</th
        >
        <th class="text-secondary fw-normal text-start" data-key="nombres"
          >Nombres</th
        >
        <th class="text-secondary fw-normal text-end" data-key="p1">P1</th>
      </thead>
      <tbody>
        {#if rows}
          {#each $rows as row}
            <tr>
              <td>{row.condicion}</td>
              <td>{row.devengue}</td>
              <td>{row.documento}</td>
              <td>{row.nombres}</td>
              <td class="text-end">{row.ingreso}</td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
    </Datatable>
  </div>
{/if}
