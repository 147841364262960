<script>
  export let pension = ""
  export let monto = ""
  export let persona_autorizada = ""
</script>

<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th class="text-secondary fw-normal">Descripción</th>
        <th class="text-secondary fw-normal">Monto</th>
        <th class="text-secondary fw-normal"
          >Persona autorizada para realizar el cobro</th
        >
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{pension}</td>
        <td>{monto}</td>
        <td>{persona_autorizada}</td>
      </tr>
    </tbody>
  </table>
</div>
