<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"

  export let documento

  let data = {
    persona: undefined,
    loading: false,
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    const ops = "laboral_empresa"
    data.loading = true
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    data = {
      persona: resp.data,
      loading: false,
    }
  }

  cargarDatos()
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <tbody>
        <tr>
          <th class="text-secondary fw-normal">RUC</th>
          <td>{data.persona.ruc}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Razón social</th>
          <td>{data.persona.razonsocial}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Comercial</th>
          <td>{data.persona.nombrecomercial}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Tipo</th>
          <td>{data.persona.tipo}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Giro</th>
          <td>{data.persona.giro != null ? data.persona.giro : ""}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Estado</th>
          <td>{data.persona.estado}</td>
        </tr>
        <tr>
          <th class="text-secondary fw-normal">Condición</th>
          <td>{data.persona.condicion}</td>
        </tr>
      </tbody>
    </table>
  </div>
{/if}
