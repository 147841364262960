import { navigate } from "svelte-routing"
import { BASE_URL } from "../stores/BASE_URL"
import { user } from "../stores/User"

export const verifySession = async (session_key) => {
  const ops = "validar_session_actual"
  let url = `${BASE_URL}session.php?session_key=${session_key}&ops=${ops}`
  const response = await fetch(url)
  const resp = await response.json()
  if (resp.status == "error") {
    user.setUser(null)
    navigate("/", { replace: true })
  }
}
