<script>
  import { Router, Route } from "svelte-routing"
  import Login from "./pages/Login.svelte"
  import Inicio from "./pages/Inicio.svelte"
  import PersonaBuscar from "./pages/Persona_buscar.svelte"
  import Persona from "./pages/Persona.svelte"
  import Empresa from "./pages/Empresa.svelte"
  import Configuracion from "./pages/Configuracion.svelte"
  import EmpresaBuscar from "./pages/Empresa_buscar.svelte"
  import Masivo from "./pages/Masivo.svelte"
  import Filtro from "./pages/Filtro.svelte"
  import Reporte from "./pages/Reporte.svelte"
  import Administrador from "./pages/Administrador.svelte"
</script>

<Router>
  <Route path="/">
    <Login />
  </Route>
  <Route path="/inicio">
    <Inicio />
  </Route>
  <Route path="/persona/:documento" let:params>
    <Persona documento={params.documento} />
  </Route>
  <Route path="/persona">
    <PersonaBuscar />
  </Route>
  <Route path="/empresa/:ruc" let:params>
    <Empresa ruc={params.ruc} />
  </Route>
  <Route path="/empresa">
    <EmpresaBuscar />
  </Route>
  <Route path="/configuracion">
    <Configuracion />
  </Route>
  <Route path="/masivo">
    <Masivo />
  </Route>
  <Route path="/filtro">
    <Filtro />
  </Route>
  <Route path="/reporte">
    <Reporte />
  </Route>
  <Route path="/administrador">
    <Administrador />
  </Route>
</Router>

<style>
  :global(*) {
    font-family: "Poppins", sans-serif;
  }
  :global(:root) {
    --rojo: #dc3545;
    --verde: #008f39;
    --naranja: #ff8000;
    --amarillo: #ffff00;
    --negro: #000;
    --gris: #adb5bd;
    --blanco: #f5f5f5;
  }
  :global(body) {
    background-color: #f5f5f5;
  }

  :global(::-webkit-scrollbar) {
    width: 6px;
    height: 6px;
  }

  :global(::-webkit-scrollbar-thumb) {
    background: #ccc;
    border-radius: 3px;
  }
  :global(::-webkit-scrollbar-thumb:active) {
    background-color: #999999;
  }
  :global(::-webkit-scrollbar-thumb:hover) {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  :global(::-webkit-scrollbar-track) {
    background: #e1e1e1;
    border-radius: 3px;
  }
  :global(::-webkit-scrollbar-track:hover, ::-webkit-scrollbar-track:active) {
    background: #d4d4d4;
  }
  :global(.no_sub) {
    text-decoration: none;
  }
  :global(.table-pointer) {
    cursor: pointer;
  }
</style>
