<script>
  export let documento = ""
  export let nombres = ""
  export let nacimiento = ""
  export let edad = ""
  export let incapacidad = ""
  export let estado = ""
  export let titular = ""
</script>

<div class="table-responsive">
  <table class="table table-striped table-sm">
    <tbody>
      <tr>
        <th class="text-secondary fw-normal">Documento</th>
        <td>{documento}</td>
      </tr>
      <tr>
        <th class="text-secondary fw-normal">Nombres</th>
        <td>{nombres}</td>
      </tr>
      <tr>
        <th class="text-secondary fw-normal">Nacimiento</th>
        <td>{nacimiento}</td>
      </tr>
      <tr>
        <th class="text-secondary fw-normal">Edad</th>
        <td>{edad}</td>
      </tr>
      <tr>
        <th class="text-secondary fw-normal">Incapacidad</th>
        <td>{incapacidad}</td>
      </tr>
      <tr>
        <th class="text-secondary fw-normal">Estado</th>
        <td>{estado}</td>
      </tr>
      <tr>
        <th class="text-secondary fw-normal">Titular</th>
        <td>{titular}</td>
      </tr>
    </tbody>
  </table>
</div>

<small class="text-secondary">
  * Fondo de Aseguramiento en Salud de la Policía Nacional del Perú
</small>
