<script>
    import { onMount } from "svelte"
    import { navigate } from "svelte-routing"
    import { tweened } from "svelte/motion"
    import Footer from "../components/Footer.svelte"
    import { verifySession } from "../services/verifySession"
    import { user } from "../stores/User"
    import { BASE_URL } from "../stores/BASE_URL"

    let isLoading = false

    let claves = {
        clave_actual: { value: "", error: false, message: "" },
        clave_nueva: { value: "", error: false, message: "" },
        clave_nueva_confirmacion: { value: "", error: false, message: "" },
    }

    let isCorrect = true

    // Función genérica para validar campos vacíos
    const validarCampoVacio = (campo, nombreCampo) => {
        if (campo.value.trim() === "") {
            campo.error = true
            campo.message = `${nombreCampo} no puede estar vacío`
            isCorrect = false
        } else {
            campo.error = false
            campo.message = ""
        }
    }

    const validar = () => {
        isCorrect = true

        validarCampoVacio(claves.clave_actual, "Contraseña actual")
        validarCampoVacio(claves.clave_nueva, "Nueva contraseña")
        validarCampoVacio(claves.clave_nueva_confirmacion, "Confirmación de nueva contraseña")

        // Validar que las claves nuevas coincidan
        if (claves.clave_nueva.value !== claves.clave_nueva_confirmacion.value) {
            claves.clave_nueva_confirmacion.error = true
            claves.clave_nueva_confirmacion.message = "Las contraseñas no coinciden"
            isCorrect = false
        }

        // Validar seguridad de la clave nueva
        if (!validarCaracteres(claves.clave_nueva.value)) {
            claves.clave_nueva.error = true
            claves.clave_nueva.message =
                "La clave debe tener al menos 10 caracteres, incluyendo una letra mayúscula, una letra minúscula, un número y al menos dos caracteres especiales (!*/#%@$_&-)"
            isCorrect = false
        }

        return isCorrect
    }

    const validarCaracteres = (clave) => {
        let re = /^(?=.*\d)(?=(.*[!*/#%@$_&-]){2,})(?=.*[a-z])(?=.*[A-Z]).{10,}$/
        return re.test(clave)
    }

    const actualizarClave = async () => {
        reiniciarTiempo()
        isLoading = true
        if (validar()) {
            let formData = new FormData()
            formData.append("usuario", $user.usuario)
            formData.append("clave", claves.clave_actual.value)
            formData.append("clave_nueva", claves.clave_nueva.value)
            formData.append("session_key", $user.session_key)
            formData.append("ops", "actualizarClaveObligatoria")

            const response = await fetch(`${BASE_URL}session.php`, {
                method: "POST",
                body: formData,
            })

            const resp = await response.json()

            if (resp.status === "ok") {
                alert("Su contraseña se actualizó correctamente. Por favor, inicie sesión nuevamente con su nueva contraseña.")
                setTimeout(() => navigate("/", { replace: true }), 1000)
            } else {
                claves.clave_actual.error = true
                claves.clave_actual.message = "Clave incorrecta"
            }
        }
        isLoading = false
    }

    const cancelar = () => {
        reiniciarTiempo()
        claves = {
            clave_actual: { value: "", error: false, message: "" },
            clave_nueva: { value: "", error: false, message: "" },
            clave_nueva_confirmacion: { value: "", error: false, message: "" },
        }
    }

    // Verificación de sesión al montar el componente
    onMount(() => {
        if (!$user) {
            navigate("/", { replace: true })
        } else {
            verifySession($user.session_key)
            if ($user.actualizar_clave == "0") {
                navigate("/", { replace: true })
            }
        }

        if (!$user.permisos.includes("configuracion")) {
            user.setUser(null)
            navigate("/", { replace: true })
        }
    })

    // Manejo del tiempo de sesión
    let original = 20 * 60
    let timer = tweened(original)

    setInterval(() => {
        if ($timer > 0) $timer--
    }, 1000)

    $: minutes = Math.floor($timer / 60)

    $: if (minutes <= 0) {
        user.setUser(null)
        navigate("/", { replace: true })
    }

    const reiniciarTiempo = () => {
        timer = tweened(original)
    }
</script>

{#if $user}
    <main>
        <div class="container py-5 bg-light">
            <div class="row g-2">
                <!-- Formulario de cambio de contraseña -->
                <div class="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="text-center mb-3">
                                <b>{$user.nombres} </b> Por razones de seguridad, es necesario cambiar tu contraseña antes de acceder al sistema
                            </h5>
                            <p class="text-center small">
                                La contraseña debe tener al menos 10 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y al
                                menos dos caracteres especiales
                            </p>
                            <hr />

                            <div class="mb-2">
                                <label class="d-block">
                                    <span class="form-label small text-secondary">Contraseña actual</span>
                                    <input type="password" class="form-control form-control-sm" bind:value={claves.clave_actual.value} />
                                </label>
                                {#if claves.clave_actual.error}
                                    <div class="form-text text-danger">
                                        <small>{claves.clave_actual.message}</small>
                                    </div>
                                {/if}
                            </div>

                            <div class="mb-2">
                                <label class="d-block">
                                    <span class="form-label small text-secondary"> Nueva contraseña </span>
                                    <input type="password" class="form-control form-control-sm" bind:value={claves.clave_nueva.value} />
                                </label>
                                {#if claves.clave_nueva.error}
                                    <div class="form-text text-danger">
                                        <small>
                                            {claves.clave_nueva.message}
                                        </small>
                                    </div>
                                {/if}
                            </div>

                            <div class="mb-2">
                                <label class="d-block">
                                    <span class="form-label small text-secondary"> Confirmar nueva contraseña </span>
                                    <input type="password" class="form-control form-control-sm" bind:value={claves.clave_nueva_confirmacion.value} />
                                </label>
                                {#if claves.clave_nueva_confirmacion.error}
                                    <div class="form-text text-danger">
                                        <small>
                                            {claves.clave_nueva_confirmacion.message}
                                        </small>
                                    </div>
                                {/if}
                            </div>

                            <div class="d-grid gap-2 d-md-block mb-2">
                                {#if isLoading}
                                    <button class="btn btn-primary btn-sm disabled"> Actualizando Contraseña... </button>
                                {:else}
                                    <button class="btn btn-primary btn-sm" on:click={actualizarClave}>Cambiar contraseña</button>
                                {/if}
                                <button class="btn btn-secondary btn-sm" on:click={cancelar}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-3"></div>
            </div>
        </div>
    </main>
    <Footer />
{/if}
