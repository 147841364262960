<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import getMoneda, { dateFormat } from "../../services/moneda.js"
  import Spinner from "../../components/Spinner.svelte"

  export let documento

  let data = {
    persona: undefined,
    loading: false,
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    const ops = "historico_datos"
    data.loading = true
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    data = {
      persona: resp.data,
      loading: false,
    }
  }

  cargarDatos()

  const peorCalificacion = (nor, cpp, def, dud, per, ops = "text") => {
    let calificacion = {
      color: "--negro",
      text: "PER",
    }

    if (per != 0.0) {
      calificacion.text = "PER"
      calificacion.color = "--negro"
    } else if (dud != 0.0) {
      calificacion.text = "DUD"
      calificacion.color = "--rojo"
    } else if (def != 0.0) {
      calificacion.text = "DEF"
      calificacion.color = "--naranja"
    } else if (cpp != 0.0) {
      calificacion.text = "CPP"
      calificacion.color = "--amarillo"
    } else {
      calificacion.text = "NOR"
      calificacion.color = "--verde"
    }

    if (ops == "text") {
      return calificacion.text
    } else {
      return calificacion.color
    }
  }

  const dateFormatText = (value) => {
    let meses = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DIC",
    ]
    let date = new Date(dateFormat(value, "####-##-##"))
    let yyyy = date.getFullYear()
    let mm = date.getMonth()

    let fecha = `${meses[mm]} ${yyyy}`
    return fecha
  }
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead class="text-center text-secondary">
        <tr>
          <th class="fw-normal" rowspan="2">Fecha</th>
          <th class="fw-normal" rowspan="2">Mes</th>
          <th class="fw-normal text-center" colspan="9"
            >Superintendencia de Banca y Seguros (SBS y Microf.)</th
          >
        </tr>
        <tr>
          <th class="fw-normal">N. Enti.</th>
          <th class="fw-normal">Deuda Total</th>
          <th class="fw-normal">Disponible TC</th>
          <th
            class="fw-normal text-white"
            style="background-color: var(--verde)">%NOR</th
          >
          <th
            class="fw-normal text-white"
            style="background-color: var(--amarillo)">%CPP</th
          >
          <th
            style="background-color: var(--naranja)"
            class="text-white fw-normal">%DEF</th
          >
          <th style="background-color: var(--rojo)" class="text-white fw-normal"
            >%DUD</th
          >
          <th
            class="text-white fw-normal"
            style="background-color: var(--negro)">%PER</th
          >
          <th class="fw-normal">Peor Califi.</th>
        </tr>
      </thead>
      <tbody>
        {#each data.persona as historico}
          <tr>
            <td>{dateFormat(historico.fechareporte, "####-##-##")}</td>
            <td class="text-center">{dateFormatText(historico.fechareporte)}</td
            >
            <td class="text-center">{historico.cantidadempresas}</td>
            <td class="text-end">{getMoneda(historico.deuda)}</td>
            <td class="text-end">{getMoneda(historico.disponible)}</td>
            <td class="text-end">{historico.calificacion_0}</td>
            <td class="text-end">{historico.calificacion_1}</td>
            <td class="text-end">{historico.calificacion_2}</td>
            <td class="text-end">{historico.calificacion_3}</td>
            <td class="text-end">{historico.calificacion_4}</td>
            <td
              style="background-color: var({peorCalificacion(
                historico.calificacion_0,
                historico.calificacion_1,
                historico.calificacion_2,
                historico.calificacion_3,
                historico.calificacion_4,
                'color'
              )})"
              class="text-center text-white"
              >{peorCalificacion(
                historico.calificacion_0,
                historico.calificacion_1,
                historico.calificacion_2,
                historico.calificacion_3,
                historico.calificacion_4,
                "text"
              )}</td
            >
          </tr>
        {/each}
      </tbody>
    </table>
  </div>

  <small class="text-secondary"
    ><b>NOR:</b> Normal, <b>CPP:</b> Con Problemas Potenciales, <b>DEF:</b>
    Deficiente, <b>DUD:</b> Dudoso, <b>PER:</b> Pérdida, <b>SCAL:</b> Sin Calificación</small
  >
{/if}
