<script>
  import Semaforo from "../Semaforo.svelte"
  import Tooltip from "../Tooltip.svelte"
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import getMoneda from "../../services/moneda.js"
  import Spinner from "../../components/Spinner.svelte"

  export let documento

  let data = {
    persona: undefined,
    loading: false,
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    const ops = "deudas_grafico"
    data.loading = true
    const response = await fetch(
      `${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`
    )
    const resp = await response.json()
    data = {
      persona: resp.data,
      loading: false,
    }
  }

  cargarDatos()
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-sm text-center table-grafico">
      <tbody>
        <tr>
          <th>
            <Tooltip tip="Mínimo Riesgo: Sin deudas vencidas" pos="right">
              <Semaforo color="#008f39" />
            </Tooltip>
          </th>
          {#each data.persona as deuda, index}
            <td>
              {#if deuda.semaforo == "verde"}
                <Tooltip
                  tip="Monto total: {getMoneda(deuda.monto)}"
                  pos={index > 11 ? "left" : "right"}
                >
                  <Semaforo color="#008f39" />
                </Tooltip>
              {/if}
            </td>
          {/each}
        </tr>
        <tr>
          <th>
            <Tooltip tip="No registra información de deudas" pos="right">
              <Semaforo color="#adb5bd" />
            </Tooltip>
          </th>
          {#each data.persona as deuda, index}
            <td>
              {#if deuda.semaforo == "gris"}
                <Tooltip
                  tip="Monto total: {getMoneda(deuda.monto)}"
                  pos={index > 11 ? "left" : "right"}
                >
                  <Semaforo color="#adb5bd" />
                </Tooltip>
              {/if}
            </td>
          {/each}
        </tr>
        <tr>
          <th>
            <Tooltip tip="Mediano Riesgo: Deudas con poco atraso" pos="right">
              <Semaforo color="#ffff00" />
            </Tooltip>
          </th>
          {#each data.persona as deuda, index}
            <td>
              {#if deuda.semaforo == "amarillo"}
                <Tooltip
                  tip="Monto total: {getMoneda(deuda.monto)}"
                  pos={index > 11 ? "left" : "right"}
                >
                  <Semaforo color="#ffff00" />
                </Tooltip>
              {/if}
            </td>
          {/each}
        </tr>
        <tr>
          <th>
            <Tooltip
              tip="Alto Riesgo: Deudas con atraso significativo"
              pos="right"
            >
              <Semaforo color="#dc3545" />
            </Tooltip>
          </th>
          {#each data.persona as deuda, index}
            <td>
              {#if deuda.semaforo == "rojo"}
                <Tooltip
                  tip="Monto total: {getMoneda(deuda.monto)}"
                  pos={index > 11 ? "left" : "right"}
                >
                  <Semaforo color="#dc3545" />
                </Tooltip>
              {/if}
            </td>
          {/each}
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th />
          {#each data.persona as { mes }}
            <td>
              <small class="fecha text-secondary">
                {mes}
              </small>
            </td>
          {/each}
        </tr>
      </tfoot>
    </table>
  </div>
{/if}

<style>
  .fecha {
    writing-mode: vertical-lr;
    transform: rotate(-145deg);
  }

  .table-grafico,
  .table-grafico tbody,
  .table-grafico tr,
  .table-grafico td,
  .table-grafico th,
  .table-grafico tfoot {
    border: none;
  }

  .table-grafico tfoot td {
    border-top: 3px solid #e9ecef;
  }

  .table-grafico tbody th {
    border-right: 3px solid #e9ecef;
  }

  .table-grafico tbody td {
    border: 1px dashed #e9ecef;
  }
</style>
