<script>
  export let message = "Cargando"
  export let online = false
</script>

{#if online}
  <div class="d-flex align-items-center text-primary">
    <strong class="text-secondary">{message} ...</strong>
    <div class="spinner-grow text-light ms-auto" role="status" />
    <div class="spinner-grow text-success ms-1" role="status" />
  </div>
{:else}
  <div class="d-flex align-items-center text-primary">
    <strong class="text-secondary">{message} ...</strong>
    <div class="spinner-border ms-auto" role="status" aria-hidden="true" />
  </div>
{/if}
