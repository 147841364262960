<script>
  import { user } from "../../stores/User"
  import { BASE_URL } from "../../stores/BASE_URL"
  import { Datatable } from "svelte-simple-datatables"
  import SinInformacion from "../../components/Sin_informacion.svelte"
  import Spinner from "../../components/Spinner.svelte"

  export let ruc
  export let documento = ""

  let data = {
    empresa: undefined,
    loading: false,
  }

  let rows

  const settings = {
    sortable: true,
    pagination: true,
    rowsPerPage: 10,
    columnFilter: false,
    scrollY: false,
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: false,
    },
    labels: {
      previous: "Anterior",
      next: "Siguiente",
    },
  }

  let session_key = $user.session_key

  const cargarDatos = async () => {
    let ops = "general_direcciones"
    let url = `${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}`
    data.loading = true
    if (documento != "") {
      ops = "adicional_empresa_general_direcciones"
      url = `${BASE_URL}persona.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}&documento=${documento}`
    }
    const response = await fetch(url)
    const resp = await response.json()
    data = {
      empresa: resp.data,
      loading: false,
    }
  }

  let encabezados = [
    "Fecha",
    "Tipo",
    "Direccion",
    "Departamento",
    "Provincia",
    "Distrito",
  ]

  cargarDatos()
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <Datatable
    {settings}
    data={data.empresa}
    bind:dataRows={rows}    
  >
  <table class="table table-striped table-sm">
    <thead>
      <th class="text-secondary fw-normal text-start" data-key="fecha">Fecha</th
      >
      <th class="text-secondary fw-normal text-start" data-key="tipo">Tipo</th>
      <th class="text-secondary fw-normal text-start" data-key="direccion"
        >Direccion</th
      >
      <th class="text-secondary fw-normal text-start" data-key="departamento"
        >Departamento</th
      >
      <th class="text-secondary fw-normal text-start" data-key="provincia"
        >Provincia</th
      >
      <th class="text-secondary fw-normal text-start" data-key="distrito"
        >Distrito</th
      >
    </thead>
    <tbody>
      {#if rows}
        {#each $rows as row}
          <tr>
            <td>{row.fecha}</td>
            <td>{row.tipo}</td>
            <td>{row.direccion}</td>
            <td>{row.departamento}</td>
            <td>{row.provincia}</td>
            <td>{row.distrito}</td>
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
  </Datatable>
{/if}
