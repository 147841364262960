<script>
  export let title,
    idx,
    show = false

  function handleKeydown(event) {
    if (event.key === "Enter" || event.key === " ") {
      event.target.click()
    }
  }
</script>

<div class="accordion" id="accordion-{idx}">
  <div class="accordion-item small mb-2">
    <h2 class="accordion-header" on:click|once on:keydown={handleKeydown}>
      <button
        class="accordion-button text-primary {show ? '' : 'collapsed'}"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapse-{idx}"
        aria-expanded="false"
        aria-controls="collapse-{idx}"
      >
        {title}
      </button>
    </h2>
    <div
      id="collapse-{idx}"
      class="accordion-collapse collapse {show ? 'show' : ''}"
      data-bs-parent="#accordion-{idx}"
    >
      <div class="accordion-body">
        <slot />
      </div>
    </div>
  </div>
</div>
