const exportTxt = (blob, name) => {
    let reader = new FileReader()
    reader.onload = function (event) {
        let save = document.createElement('a')
        save.href = event.target.result
        save.target = '_blank'
        save.download = name || 'archivo.dat';
        let clicEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });
        save.dispatchEvent(clicEvent)
    }
    reader.readAsDataURL(blob)
}

export default exportTxt