class Ubigeo {
  departamento = [
    {
      cod_dep: "01",
      des_dep: "AMAZONAS",
    },
    {
      cod_dep: "02",
      des_dep: "ANCASH",
    },
    {
      cod_dep: "03",
      des_dep: "APURIMAC",
    },
    {
      cod_dep: "04",
      des_dep: "AREQUIPA",
    },
    {
      cod_dep: "05",
      des_dep: "AYACUCHO",
    },
    {
      cod_dep: "06",
      des_dep: "CAJAMARCA",
    },
    {
      cod_dep: "24",
      des_dep: "CALLAO",
    },
    {
      cod_dep: "07",
      des_dep: "CUSCO",
    },
    {
      cod_dep: "08",
      des_dep: "HUANCAVELICA",
    },
    {
      cod_dep: "09",
      des_dep: "HUANUCO",
    },
    {
      cod_dep: "10",
      des_dep: "ICA",
    },
    {
      cod_dep: "11",
      des_dep: "JUNIN",
    },
    {
      cod_dep: "12",
      des_dep: "LA LIBERTAD",
    },
    {
      cod_dep: "13",
      des_dep: "LAMBAYEQUE",
    },
    {
      cod_dep: "14",
      des_dep: "LIMA",
    },
    {
      cod_dep: "15",
      des_dep: "LORETO",
    },
    {
      cod_dep: "16",
      des_dep: "MADRE DE DIOS",
    },
    {
      cod_dep: "17",
      des_dep: "MOQUEGUA",
    },
    {
      cod_dep: "18",
      des_dep: "PASCO",
    },
    {
      cod_dep: "19",
      des_dep: "PIURA",
    },
    {
      cod_dep: "20",
      des_dep: "PUNO",
    },
    {
      cod_dep: "21",
      des_dep: "SAN MARTIN",
    },
    {
      cod_dep: "22",
      des_dep: "TACNA",
    },
    {
      cod_dep: "23",
      des_dep: "TUMBES",
    },
    {
      cod_dep: "25",
      des_dep: "UCAYALI",
    },
  ]

  provincia = [
    {
      cod_dep: "01",
      cod_pro: "01",
      des_pro: "CHACHAPOYAS",
    },
    {
      cod_dep: "01",
      cod_pro: "02",
      des_pro: "BAGUA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      des_pro: "BONGARA",
    },
    {
      cod_dep: "01",
      cod_pro: "06",
      des_pro: "CONDORCANQUI",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      des_pro: "LUYA",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      des_pro: "RODRIGUEZ DE MENDOZA",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      des_pro: "UTCUBAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      des_pro: "HUARAZ",
    },
    {
      cod_dep: "02",
      cod_pro: "02",
      des_pro: "AIJA",
    },
    {
      cod_dep: "02",
      cod_pro: "16",
      des_pro: "ANTONIO RAYMONDI",
    },
    {
      cod_dep: "02",
      cod_pro: "18",
      des_pro: "ASUNCION",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      des_pro: "BOLOGNESI",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      des_pro: "CARHUAZ",
    },
    {
      cod_dep: "02",
      cod_pro: "17",
      des_pro: "CARLOS FERMIN FITZCARRALD",
    },
    {
      cod_dep: "02",
      cod_pro: "05",
      des_pro: "CASMA",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      des_pro: "CORONGO",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      des_pro: "HUARI",
    },
    {
      cod_dep: "02",
      cod_pro: "19",
      des_pro: "HUARMEY",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      des_pro: "HUAYLAS",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      des_pro: "MARISCAL LUZURIAGA",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      des_pro: "OCROS",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      des_pro: "PALLASCA",
    },
    {
      cod_dep: "02",
      cod_pro: "11",
      des_pro: "POMABAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      des_pro: "RECUAY",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      des_pro: "SANTA",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      des_pro: "SIHUAS",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      des_pro: "YUNGAY",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      des_pro: "ABANCAY",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      des_pro: "ANDAHUAYLAS",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      des_pro: "ANTABAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      des_pro: "AYMARAES",
    },
    {
      cod_dep: "03",
      cod_pro: "05",
      des_pro: "COTABAMBAS",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      des_pro: "CHINCHEROS",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      des_pro: "GRAU",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      des_pro: "AREQUIPA",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      des_pro: "CAMANA",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      des_pro: "CARAVELI",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      des_pro: "CASTILLA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      des_pro: "CAYLLOMA",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      des_pro: "CONDESUYOS",
    },
    {
      cod_dep: "04",
      cod_pro: "07",
      des_pro: "ISLAY",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      des_pro: "LA UNION",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      des_pro: "HUAMANGA",
    },
    {
      cod_dep: "05",
      cod_pro: "02",
      des_pro: "CANGALLO",
    },
    {
      cod_dep: "05",
      cod_pro: "08",
      des_pro: "HUANCA SANCOS",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      des_pro: "HUANTA",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      des_pro: "LA MAR",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      des_pro: "LUCANAS",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      des_pro: "PARINACOCHAS",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      des_pro: "PAUCAR DEL SARA SARA",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      des_pro: "SUCRE",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      des_pro: "VICTOR FAJARDO",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      des_pro: "VILCAS HUAMAN",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      des_pro: "CAJAMARCA",
    },
    {
      cod_dep: "06",
      cod_pro: "02",
      des_pro: "CAJABAMBA",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      des_pro: "CELENDIN",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      des_pro: "CHOTA",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      des_pro: "CONTUMAZA",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      des_pro: "CUTERVO",
    },
    {
      cod_dep: "06",
      cod_pro: "07",
      des_pro: "HUALGAYOC",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      des_pro: "JAEN",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      des_pro: "SAN IGNACIO",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      des_pro: "SAN MARCOS",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      des_pro: "SAN MIGUEL",
    },
    {
      cod_dep: "06",
      cod_pro: "13",
      des_pro: "SAN PABLO",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      des_pro: "SANTA CRUZ",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      des_pro: "CALLAO",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      des_pro: "CUSCO",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      des_pro: "ACOMAYO",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      des_pro: "ANTA",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      des_pro: "CALCA",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      des_pro: "CANAS",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      des_pro: "CANCHIS",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      des_pro: "CHUMBIVILCAS",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      des_pro: "ESPINAR",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      des_pro: "LA CONVENCION",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      des_pro: "PARURO",
    },
    {
      cod_dep: "07",
      cod_pro: "11",
      des_pro: "PAUCARTAMBO",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      des_pro: "QUISPICANCHI",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      des_pro: "URUBAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      des_pro: "HUANCAVELICA",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      des_pro: "ACOBAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      des_pro: "ANGARAES",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      des_pro: "CASTROVIRREYNA",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      des_pro: "CHURCAMPA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      des_pro: "HUAYTARA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      des_pro: "TAYACAJA",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      des_pro: "HUANUCO",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      des_pro: "AMBO",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      des_pro: "DOS DE MAYO",
    },
    {
      cod_dep: "09",
      cod_pro: "09",
      des_pro: "HUACAYBAMBA",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      des_pro: "HUAMALIES",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      des_pro: "LEONCIO PRADO",
    },
    {
      cod_dep: "09",
      cod_pro: "05",
      des_pro: "MARAÑON",
    },
    {
      cod_dep: "09",
      cod_pro: "07",
      des_pro: "PACHITEA",
    },
    {
      cod_dep: "09",
      cod_pro: "08",
      des_pro: "PUERTO INCA",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      des_pro: "LAURICOCHA",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      des_pro: "YAROWILCA",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      des_pro: "ICA",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      des_pro: "CHINCHA",
    },
    {
      cod_dep: "10",
      cod_pro: "03",
      des_pro: "NAZCA",
    },
    {
      cod_dep: "10",
      cod_pro: "05",
      des_pro: "PALPA",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      des_pro: "PISCO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      des_pro: "HUANCAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      des_pro: "CONCEPCION",
    },
    {
      cod_dep: "11",
      cod_pro: "08",
      des_pro: "CHANCHAMAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      des_pro: "JAUJA",
    },
    {
      cod_dep: "11",
      cod_pro: "04",
      des_pro: "JUNIN",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      des_pro: "SATIPO",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      des_pro: "TARMA",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      des_pro: "YAULI",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      des_pro: "CHUPACA",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      des_pro: "TRUJILLO",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      des_pro: "ASCOPE",
    },
    {
      cod_dep: "12",
      cod_pro: "02",
      des_pro: "BOLIVAR",
    },
    {
      cod_dep: "12",
      cod_pro: "09",
      des_pro: "CHEPEN",
    },
    {
      cod_dep: "12",
      cod_pro: "10",
      des_pro: "JULCAN",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      des_pro: "OTUZCO",
    },
    {
      cod_dep: "12",
      cod_pro: "05",
      des_pro: "PACASMAYO",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      des_pro: "PATAZ",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      des_pro: "SANCHEZ CARRION",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      des_pro: "SANTIAGO DE CHUCO",
    },
    {
      cod_dep: "12",
      cod_pro: "11",
      des_pro: "GRAN CHIMU",
    },
    {
      cod_dep: "12",
      cod_pro: "12",
      des_pro: "VIRU",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      des_pro: "CHICLAYO",
    },
    {
      cod_dep: "13",
      cod_pro: "02",
      des_pro: "FERREÑAFE",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      des_pro: "LAMBAYEQUE",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      des_pro: "LIMA",
    },
    {
      cod_dep: "14",
      cod_pro: "09",
      des_pro: "BARRANCA",
    },
    {
      cod_dep: "14",
      cod_pro: "02",
      des_pro: "CAJATAMBO",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      des_pro: "CANTA",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      des_pro: "CAÑETE",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      des_pro: "HUARAL",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      des_pro: "HUAROCHIRI",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      des_pro: "HUAURA",
    },
    {
      cod_dep: "14",
      cod_pro: "10",
      des_pro: "OYON",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      des_pro: "YAUYOS",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      des_pro: "MAYNAS",
    },
    {
      cod_dep: "15",
      cod_pro: "02",
      des_pro: "ALTO AMAZONAS",
    },
    {
      cod_dep: "15",
      cod_pro: "03",
      des_pro: "LORETO",
    },
    {
      cod_dep: "15",
      cod_pro: "06",
      des_pro: "MARISCAL RAMON CASTILLA",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      des_pro: "REQUENA",
    },
    {
      cod_dep: "15",
      cod_pro: "05",
      des_pro: "UCAYALI",
    },
    {
      cod_dep: "15",
      cod_pro: "07",
      des_pro: "DATEM DEL MARAÑON",
    },
    {
      cod_dep: "15",
      cod_pro: "09",
      des_pro: "PUTUMAYO",
    },
    {
      cod_dep: "16",
      cod_pro: "01",
      des_pro: "TAMBOPATA",
    },
    {
      cod_dep: "16",
      cod_pro: "02",
      des_pro: "MANU",
    },
    {
      cod_dep: "16",
      cod_pro: "03",
      des_pro: "TAHUAMANU",
    },
    {
      cod_dep: "17",
      cod_pro: "01",
      des_pro: "MARISCAL NIETO",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      des_pro: "GENERAL SANCHEZ CERRO",
    },
    {
      cod_dep: "17",
      cod_pro: "03",
      des_pro: "ILO",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      des_pro: "PASCO",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      des_pro: "DANIEL ALCIDES CARRION",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      des_pro: "OXAPAMPA",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      des_pro: "PIURA",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      des_pro: "AYABACA",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      des_pro: "HUANCABAMBA",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      des_pro: "MORROPON",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      des_pro: "PAITA",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      des_pro: "SULLANA",
    },
    {
      cod_dep: "19",
      cod_pro: "07",
      des_pro: "TALARA",
    },
    {
      cod_dep: "19",
      cod_pro: "08",
      des_pro: "SECHURA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      des_pro: "PUNO",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      des_pro: "AZANGARO",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      des_pro: "CARABAYA",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      des_pro: "CHUCUITO",
    },
    {
      cod_dep: "20",
      cod_pro: "12",
      des_pro: "EL COLLAO",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      des_pro: "HUANCANE",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      des_pro: "LAMPA",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      des_pro: "MELGAR",
    },
    {
      cod_dep: "20",
      cod_pro: "13",
      des_pro: "MOHO",
    },
    {
      cod_dep: "20",
      cod_pro: "11",
      des_pro: "SAN ANTONIO DE PUTINA",
    },
    {
      cod_dep: "20",
      cod_pro: "09",
      des_pro: "SAN ROMAN",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      des_pro: "SANDIA",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      des_pro: "YUNGUYO",
    },
    {
      cod_dep: "21",
      cod_pro: "01",
      des_pro: "MOYOBAMBA",
    },
    {
      cod_dep: "21",
      cod_pro: "07",
      des_pro: "BELLAVISTA",
    },
    {
      cod_dep: "21",
      cod_pro: "10",
      des_pro: "EL DORADO",
    },
    {
      cod_dep: "21",
      cod_pro: "02",
      des_pro: "HUALLAGA",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      des_pro: "LAMAS",
    },
    {
      cod_dep: "21",
      cod_pro: "04",
      des_pro: "MARISCAL CACERES",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      des_pro: "PICOTA",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      des_pro: "RIOJA",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      des_pro: "SAN MARTIN",
    },
    {
      cod_dep: "21",
      cod_pro: "08",
      des_pro: "TOCACHE",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      des_pro: "TACNA",
    },
    {
      cod_dep: "22",
      cod_pro: "04",
      des_pro: "CANDARAVE",
    },
    {
      cod_dep: "22",
      cod_pro: "03",
      des_pro: "JORGE BASADRE",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      des_pro: "TARATA",
    },
    {
      cod_dep: "23",
      cod_pro: "01",
      des_pro: "TUMBES",
    },
    {
      cod_dep: "23",
      cod_pro: "02",
      des_pro: "CONTRALMIRANTE VILLAR",
    },
    {
      cod_dep: "23",
      cod_pro: "03",
      des_pro: "ZARUMILLA",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      des_pro: "CORONEL PORTILLO",
    },
    {
      cod_dep: "25",
      cod_pro: "03",
      des_pro: "ATALAYA",
    },
    {
      cod_dep: "25",
      cod_pro: "02",
      des_pro: "PADRE ABAD",
    },
    {
      cod_dep: "25",
      cod_pro: "04",
      des_pro: "PURUS",
    },
  ]

  distrito = [
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "CHACHAPOYAS",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "ASUNCION",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "BALSAS",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CHETO",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "CHILIQUIN",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "CHUQUIBAMBA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "GRANADA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "HUANCAS",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "LA JALCA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "LEIMEBAMBA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "LEVANTO",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "MAGDALENA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "MARISCAL CASTILLA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "MOLINOPAMPA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "MONTEVIDEO",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "16",
      des_dis: "OLLEROS",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "17",
      des_dis: "QUINJALCA",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "18",
      des_dis: "SAN FRANCISCO DE DAGUAS",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "19",
      des_dis: "SAN ISIDRO DE MAINO",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "20",
      des_dis: "SOLOCO",
    },
    {
      cod_dep: "01",
      cod_pro: "01",
      cod_dis: "21",
      des_dis: "SONCHE",
    },
    {
      cod_dep: "01",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "BAGUA",
    },
    {
      cod_dep: "01",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "ARAMANGO",
    },
    {
      cod_dep: "01",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "COPALLIN",
    },
    {
      cod_dep: "01",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "EL PARCO",
    },
    {
      cod_dep: "01",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "IMAZA",
    },
    {
      cod_dep: "01",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "LA PECA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "JUMBILLA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "CHISQUILLA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "CHURUJA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "COROSHA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "CUISPES",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "FLORIDA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "JAZAN",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "RECTA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "SAN CARLOS",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "SHIPASBAMBA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "VALERA",
    },
    {
      cod_dep: "01",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "YAMBRASBAMBA",
    },
    {
      cod_dep: "01",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "NIEVA",
    },
    {
      cod_dep: "01",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "EL CENEPA",
    },
    {
      cod_dep: "01",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "RIO SANTIAGO",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "LAMUD",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "CAMPORREDONDO",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "COCABAMBA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "COLCAMAR",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "CONILA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "INGUILPATA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "LONGUITA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "LONYA CHICO",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "LUYA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "LUYA VIEJO",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "MARIA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "12",
      des_dis: "OCALLI",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "13",
      des_dis: "OCUMAL",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "14",
      des_dis: "PISUQUIA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "23",
      des_dis: "PROVIDENCIA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "15",
      des_dis: "SAN CRISTOBAL",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "16",
      des_dis: "SAN FRANCISCO DEL YESO",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "17",
      des_dis: "SAN JERONIMO",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "18",
      des_dis: "SAN JUAN DE LOPECANCHA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "19",
      des_dis: "SANTA CATALINA",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "20",
      des_dis: "SANTO TOMAS",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "21",
      des_dis: "TINGO",
    },
    {
      cod_dep: "01",
      cod_pro: "04",
      cod_dis: "22",
      des_dis: "TRITA",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "SAN NICOLAS",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "CHIRIMOTO",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "COCHAMAL",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "HUAMBO",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "LIMABAMBA",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "LONGAR",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "MARISCAL BENAVIDES",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "MILPUC",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "OMIA",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "10",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "11",
      des_dis: "TOTORA",
    },
    {
      cod_dep: "01",
      cod_pro: "05",
      cod_dis: "12",
      des_dis: "VISTA ALEGRE",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "BAGUA GRANDE",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "CAJARURO",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "CUMBA",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "EL MILAGRO",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "JAMALCA",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "LONYA GRANDE",
    },
    {
      cod_dep: "01",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "YAMON",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "HUARAZ",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "COCHABAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "COLCABAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "HUANCHAY",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "INDEPENDENCIA",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "JANGAS",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "LA LIBERTAD",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "OLLEROS",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "PAMPAS",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "PARIACOTO",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "PIRA",
    },
    {
      cod_dep: "02",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "TARICA",
    },
    {
      cod_dep: "02",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "AIJA",
    },
    {
      cod_dep: "02",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CORIS",
    },
    {
      cod_dep: "02",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "HUACLLAN",
    },
    {
      cod_dep: "02",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "LA MERCED",
    },
    {
      cod_dep: "02",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "SUCCHA",
    },
    {
      cod_dep: "02",
      cod_pro: "16",
      cod_dis: "01",
      des_dis: "LLAMELLIN",
    },
    {
      cod_dep: "02",
      cod_pro: "16",
      cod_dis: "02",
      des_dis: "ACZO",
    },
    {
      cod_dep: "02",
      cod_pro: "16",
      cod_dis: "03",
      des_dis: "CHACCHO",
    },
    {
      cod_dep: "02",
      cod_pro: "16",
      cod_dis: "04",
      des_dis: "CHINGAS",
    },
    {
      cod_dep: "02",
      cod_pro: "16",
      cod_dis: "05",
      des_dis: "MIRGAS",
    },
    {
      cod_dep: "02",
      cod_pro: "16",
      cod_dis: "06",
      des_dis: "SAN JUAN DE RONTOY",
    },
    {
      cod_dep: "02",
      cod_pro: "18",
      cod_dis: "01",
      des_dis: "CHACAS",
    },
    {
      cod_dep: "02",
      cod_pro: "18",
      cod_dis: "02",
      des_dis: "ACOCHACA",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "CHIQUIAN",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "ABELARDO PARDO LEZAMETA",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "21",
      des_dis: "ANTONIO RAYMONDI",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "AQUIA",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "CAJACAY",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "22",
      des_dis: "CANIS",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "23",
      des_dis: "COLQUIOC",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "25",
      des_dis: "HUALLANCA",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "HUASTA",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "HUAYLLACAYAN",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "24",
      des_dis: "LA PRIMAVERA",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "13",
      des_dis: "MANGAS",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "15",
      des_dis: "PACLLON",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "17",
      des_dis: "SAN MIGUEL DE CORPANQUI",
    },
    {
      cod_dep: "02",
      cod_pro: "03",
      cod_dis: "20",
      des_dis: "TICLLOS",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "CARHUAZ",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "ACOPAMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "AMASHCA",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "ANTA",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "ATAQUERO",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "MARCARA",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "PARIAHUANCA",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "SAN MIGUEL DE ACO",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "SHILLA",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "TINCO",
    },
    {
      cod_dep: "02",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "YUNGAR",
    },
    {
      cod_dep: "02",
      cod_pro: "17",
      cod_dis: "01",
      des_dis: "SAN LUIS",
    },
    {
      cod_dep: "02",
      cod_pro: "17",
      cod_dis: "03",
      des_dis: "SAN NICOLAS",
    },
    {
      cod_dep: "02",
      cod_pro: "17",
      cod_dis: "02",
      des_dis: "YAUYA",
    },
    {
      cod_dep: "02",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "CASMA",
    },
    {
      cod_dep: "02",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "BUENA VISTA ALTA",
    },
    {
      cod_dep: "02",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "COMANDANTE NOEL",
    },
    {
      cod_dep: "02",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "YAUTAN",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "CORONGO",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "ACO",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "BAMBAS",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "CUSCA",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "LA PAMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "YANAC",
    },
    {
      cod_dep: "02",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "YUPAN",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "HUARI",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "16",
      des_dis: "ANRA",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "CAJAY",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "CHAVIN DE HUANTAR",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "HUACACHI",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "HUACCHIS",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "HUACHIS",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "07",
      des_dis: "HUANTAR",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "08",
      des_dis: "MASIN",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "09",
      des_dis: "PAUCAS",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "10",
      des_dis: "PONTO",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "11",
      des_dis: "RAHUAPAMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "12",
      des_dis: "RAPAYAN",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "13",
      des_dis: "SAN MARCOS",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "14",
      des_dis: "SAN PEDRO DE CHANA",
    },
    {
      cod_dep: "02",
      cod_pro: "08",
      cod_dis: "15",
      des_dis: "UCO",
    },
    {
      cod_dep: "02",
      cod_pro: "19",
      cod_dis: "01",
      des_dis: "HUARMEY",
    },
    {
      cod_dep: "02",
      cod_pro: "19",
      cod_dis: "02",
      des_dis: "COCHAPETI",
    },
    {
      cod_dep: "02",
      cod_pro: "19",
      cod_dis: "05",
      des_dis: "CULEBRAS",
    },
    {
      cod_dep: "02",
      cod_pro: "19",
      cod_dis: "03",
      des_dis: "HUAYAN",
    },
    {
      cod_dep: "02",
      cod_pro: "19",
      cod_dis: "04",
      des_dis: "MALVAS",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "CARAZ",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "HUALLANCA",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "HUATA",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "HUAYLAS",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "MATO",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "PAMPAROMAS",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "PUEBLO LIBRE",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "SANTA CRUZ",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "10",
      des_dis: "SANTO TORIBIO",
    },
    {
      cod_dep: "02",
      cod_pro: "07",
      cod_dis: "09",
      des_dis: "YURACMARCA",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "PISCOBAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "CASCA",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "08",
      des_dis: "ELEAZAR GUZMAN BARRON",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "FIDEL OLIVAS ESCUDERO",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "LLAMA",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "06",
      des_dis: "LLUMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "LUCMA",
    },
    {
      cod_dep: "02",
      cod_pro: "09",
      cod_dis: "07",
      des_dis: "MUSGA",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "07",
      des_dis: "OCROS",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "01",
      des_dis: "ACAS",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "02",
      des_dis: "CAJAMARQUILLA",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "03",
      des_dis: "CARHUAPAMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "04",
      des_dis: "COCHAS",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "05",
      des_dis: "CONGAS",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "06",
      des_dis: "LLIPA",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "08",
      des_dis: "SAN CRISTOBAL DE RAJAN",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "09",
      des_dis: "SAN PEDRO",
    },
    {
      cod_dep: "02",
      cod_pro: "20",
      cod_dis: "10",
      des_dis: "SANTIAGO DE CHILCAS",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "CABANA",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "BOLOGNESI",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "CONCHUCOS",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "HUACASCHUQUE",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "HUANDOVAL",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "06",
      des_dis: "LACABAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "07",
      des_dis: "LLAPO",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "08",
      des_dis: "PALLASCA",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "09",
      des_dis: "PAMPAS",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "10",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "02",
      cod_pro: "10",
      cod_dis: "11",
      des_dis: "TAUCA",
    },
    {
      cod_dep: "02",
      cod_pro: "11",
      cod_dis: "01",
      des_dis: "POMABAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "11",
      cod_dis: "02",
      des_dis: "HUAYLLAN",
    },
    {
      cod_dep: "02",
      cod_pro: "11",
      cod_dis: "03",
      des_dis: "PAROBAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "11",
      cod_dis: "04",
      des_dis: "QUINUABAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "01",
      des_dis: "RECUAY",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "10",
      des_dis: "CATAC",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "02",
      des_dis: "COTAPARACO",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "03",
      des_dis: "HUAYLLAPAMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "09",
      des_dis: "LLACLLIN",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "04",
      des_dis: "MARCA",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "05",
      des_dis: "PAMPAS CHICO",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "06",
      des_dis: "PARARIN",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "07",
      des_dis: "TAPACOCHA",
    },
    {
      cod_dep: "02",
      cod_pro: "12",
      cod_dis: "08",
      des_dis: "TICAPAMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "01",
      des_dis: "CHIMBOTE",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "02",
      des_dis: "CACERES DEL PERU",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "08",
      des_dis: "COISHCO",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "03",
      des_dis: "MACATE",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "04",
      des_dis: "MORO",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "05",
      des_dis: "NEPEÑA",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "06",
      des_dis: "SAMANCO",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "07",
      des_dis: "SANTA",
    },
    {
      cod_dep: "02",
      cod_pro: "13",
      cod_dis: "09",
      des_dis: "NUEVO CHIMBOTE",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "01",
      des_dis: "SIHUAS",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "07",
      des_dis: "ACOBAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "02",
      des_dis: "ALFONSO UGARTE",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "08",
      des_dis: "CASHAPAMPA",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "03",
      des_dis: "CHINGALPO",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "04",
      des_dis: "HUAYLLABAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "05",
      des_dis: "QUICHES",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "09",
      des_dis: "RAGASH",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "10",
      des_dis: "SAN JUAN",
    },
    {
      cod_dep: "02",
      cod_pro: "14",
      cod_dis: "06",
      des_dis: "SICSIBAMBA",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "01",
      des_dis: "YUNGAY",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "02",
      des_dis: "CASCAPARA",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "03",
      des_dis: "MANCOS",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "04",
      des_dis: "MATACOTO",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "05",
      des_dis: "QUILLO",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "06",
      des_dis: "RANRAHIRCA",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "07",
      des_dis: "SHUPLUY",
    },
    {
      cod_dep: "02",
      cod_pro: "15",
      cod_dis: "08",
      des_dis: "YANAMA",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "ABANCAY",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CHACOCHE",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CIRCA",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "CURAHUASI",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "HUANIPACA",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "LAMBRAMA",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "PICHIRHUA",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "SAN PEDRO DE CACHORA",
    },
    {
      cod_dep: "03",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "TAMBURCO",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "ANDAHUAYLAS",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "ANDARAPA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "CHIARA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "HUANCARAMA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "HUANCARAY",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "17",
      des_dis: "HUAYANA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "KISHUARA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "PACOBAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "13",
      des_dis: "PACUCHA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "PAMPACHIRI",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "14",
      des_dis: "POMACOCHA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "SAN ANTONIO DE CACHI",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "SAN JERONIMO",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "18",
      des_dis: "SAN MIGUEL DE CHACCRAMPA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "15",
      des_dis: "SANTA MARIA DE CHICMO",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "TALAVERA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "16",
      des_dis: "TUMAY HUARACA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "TURPO",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "19",
      des_dis: "KAQUIABAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "03",
      cod_dis: "20",
      des_dis: "JOSE MARIA ARGUEDAS",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "ANTABAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "EL ORO",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "HUAQUIRCA",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "JUAN ESPINOZA MEDRANO",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "OROPESA",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "PACHACONAS",
    },
    {
      cod_dep: "03",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "SABAINO",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "CHALHUANCA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "CAPAYA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CARAYBAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "CHAPIMARCA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "COLCABAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "COTARUSE",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "HUAYLLO",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "17",
      des_dis: "JUSTO APU SAHUARAURA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "LUCRE",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "09",
      des_dis: "POCOHUANCA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "16",
      des_dis: "SAN JUAN DE CHACÑA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "SAÑAYCA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "SORAYA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "12",
      des_dis: "TAPAIRIHUA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "13",
      des_dis: "TINTAY",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "14",
      des_dis: "TORAYA",
    },
    {
      cod_dep: "03",
      cod_pro: "02",
      cod_dis: "15",
      des_dis: "YANACA",
    },
    {
      cod_dep: "03",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "TAMBOBAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "COTABAMBAS",
    },
    {
      cod_dep: "03",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "COYLLURQUI",
    },
    {
      cod_dep: "03",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "HAQUIRA",
    },
    {
      cod_dep: "03",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "MARA",
    },
    {
      cod_dep: "03",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "CHALLHUAHUACHO",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "CHINCHEROS",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "ANCO-HUALLO",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "COCHARCAS",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "HUACCANA",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "OCOBAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "ONGOY",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "URANMARCA",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "RANRACANCHA",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "09",
      des_dis: "ROCCHACC",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "10",
      des_dis: "EL PORVENIR",
    },
    {
      cod_dep: "03",
      cod_pro: "07",
      cod_dis: "11",
      des_dis: "LOS CHANKAS",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "CHUQUIBAMBILLA",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "CURPAHUASI",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "GAMARRA",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "HUAYLLATI",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "MAMARA",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "MICAELA BASTIDAS",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "PATAYPAMPA",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "PROGRESO",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "SAN ANTONIO",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "13",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "TURPAY",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "11",
      des_dis: "VILCABAMBA",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "12",
      des_dis: "VIRUNDO",
    },
    {
      cod_dep: "03",
      cod_pro: "06",
      cod_dis: "14",
      des_dis: "CURASCO",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "AREQUIPA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "28",
      des_dis: "ALTO SELVA ALEGRE",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CAYMA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "CERRO COLORADO",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CHARACATO",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "CHIGUATA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "27",
      des_dis: "JACOBO HUNTER",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "LA JOYA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "26",
      des_dis: "MARIANO MELGAR",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "MIRAFLORES",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "MOLLEBAYA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "PAUCARPATA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "POCSI",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "POLOBAYA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "QUEQUEÑA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "SABANDIA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "SACHACA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "SAN JUAN DE SIGUAS",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "16",
      des_dis: "SAN JUAN DE TARUCANI",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "17",
      des_dis: "SANTA ISABEL DE SIGUAS",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "18",
      des_dis: "SANTA RITA DE SIGUAS",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "19",
      des_dis: "SOCABAYA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "20",
      des_dis: "TIABAYA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "21",
      des_dis: "UCHUMAYO",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "22",
      des_dis: "VITOR",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "23",
      des_dis: "YANAHUARA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "24",
      des_dis: "YARABAMBA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "25",
      des_dis: "YURA",
    },
    {
      cod_dep: "04",
      cod_pro: "01",
      cod_dis: "29",
      des_dis: "JOSE LUIS BUSTAMANTE Y RIVERO",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "CAMANA",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "JOSE MARIA QUIMPER",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "MARIANO NICOLAS VALCARCEL",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "MARISCAL CACERES",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "NICOLAS DE PIEROLA",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "OCOÑA",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "QUILCA",
    },
    {
      cod_dep: "04",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "SAMUEL PASTOR",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "CARAVELI",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "ACARI",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "ATICO",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "ATIQUIPA",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "BELLA UNION",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "CAHUACHO",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "CHALA",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "CHAPARRA",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "HUANUHUANU",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "JAQUI",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "LOMAS",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "12",
      des_dis: "QUICACHA",
    },
    {
      cod_dep: "04",
      cod_pro: "04",
      cod_dis: "13",
      des_dis: "YAUCA",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "APLAO",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "ANDAGUA",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "AYO",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "CHACHAS",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "CHILCAYMARCA",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "CHOCO",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "HUANCARQUI",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "MACHAGUAY",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "ORCOPAMPA",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "10",
      des_dis: "PAMPACOLCA",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "11",
      des_dis: "TIPAN",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "13",
      des_dis: "UÑON",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "12",
      des_dis: "URACA",
    },
    {
      cod_dep: "04",
      cod_pro: "05",
      cod_dis: "14",
      des_dis: "VIRACO",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "CHIVAY",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "ACHOMA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CABANACONDE",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "CALLALLI",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "CAYLLOMA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "COPORAQUE",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "HUAMBO",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "HUANCA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "09",
      des_dis: "ICHUPAMPA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "LARI",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "LLUTA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "12",
      des_dis: "MACA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "13",
      des_dis: "MADRIGAL",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "14",
      des_dis: "SAN ANTONIO DE CHUCA",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "15",
      des_dis: "SIBAYO",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "16",
      des_dis: "TAPAY",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "17",
      des_dis: "TISCO",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "18",
      des_dis: "TUTI",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "19",
      des_dis: "YANQUE",
    },
    {
      cod_dep: "04",
      cod_pro: "02",
      cod_dis: "20",
      des_dis: "MAJES",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "CHUQUIBAMBA",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "ANDARAY",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "CAYARANI",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "CHICHAS",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "IRAY",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "RIO GRANDE",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "SALAMANCA",
    },
    {
      cod_dep: "04",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "YANAQUIHUA",
    },
    {
      cod_dep: "04",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "MOLLENDO",
    },
    {
      cod_dep: "04",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "COCACHACRA",
    },
    {
      cod_dep: "04",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "DEAN VALDIVIA",
    },
    {
      cod_dep: "04",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "ISLAY",
    },
    {
      cod_dep: "04",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "MEJIA",
    },
    {
      cod_dep: "04",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "PUNTA DE BOMBON",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "COTAHUASI",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "ALCA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "CHARCANA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "HUAYNACOTAS",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "PAMPAMARCA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "PUYCA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "07",
      des_dis: "QUECHUALLA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "08",
      des_dis: "SAYLA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "09",
      des_dis: "TAURIA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "10",
      des_dis: "TOMEPAMPA",
    },
    {
      cod_dep: "04",
      cod_pro: "08",
      cod_dis: "11",
      des_dis: "TORO",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "AYACUCHO",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "ACOCRO",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "ACOS VINCHOS",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "CARMEN ALTO",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CHIARA",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "OCROS",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "PACAYCASA",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "QUINUA",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "SAN JOSE DE TICLLAS",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "SAN JUAN BAUTISTA",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "SANTIAGO DE PISCHA",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "SOCOS",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "TAMBILLO",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "VINCHOS",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "JESUS NAZARENO",
    },
    {
      cod_dep: "05",
      cod_pro: "01",
      cod_dis: "16",
      des_dis: "ANDRES AVELINO CACERES DORREGARAY",
    },
    {
      cod_dep: "05",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "CANGALLO",
    },
    {
      cod_dep: "05",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "CHUSCHI",
    },
    {
      cod_dep: "05",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "LOS MOROCHUCOS",
    },
    {
      cod_dep: "05",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "MARIA PARADO DE BELLIDO",
    },
    {
      cod_dep: "05",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "PARAS",
    },
    {
      cod_dep: "05",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "TOTOS",
    },
    {
      cod_dep: "05",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "SANCOS",
    },
    {
      cod_dep: "05",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "CARAPO",
    },
    {
      cod_dep: "05",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "SACSAMARCA",
    },
    {
      cod_dep: "05",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "SANTIAGO DE LUCANAMARCA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "HUANTA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "AYAHUANCO",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "HUAMANGUILLA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "IGUAIN",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "LURICOCHA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "SANTILLANA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "SIVIA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "LLOCHEGUA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "CANAYRE",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "UCHURACCAY",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "PUCACOLPA",
    },
    {
      cod_dep: "05",
      cod_pro: "03",
      cod_dis: "13",
      des_dis: "CHACA",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "SAN MIGUEL",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "ANCO",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "AYNA",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "CHILCAS",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "CHUNGUI",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "LUIS CARRANZA",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "TAMBO",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "SAMUGARI",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "ANCHIHUAY",
    },
    {
      cod_dep: "05",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "ORONCCOY",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "PUQUIO",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "AUCARA",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "CABANA",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "CARMEN SALCEDO",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "CHAVIÑA",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "CHIPAO",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "10",
      des_dis: "HUAC-HUAS",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "11",
      des_dis: "LARAMATE",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "12",
      des_dis: "LEONCIO PRADO",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "14",
      des_dis: "LLAUTA",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "13",
      des_dis: "LUCANAS",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "16",
      des_dis: "OCAÑA",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "17",
      des_dis: "OTOCA",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "29",
      des_dis: "SAISA",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "32",
      des_dis: "SAN CRISTOBAL",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "21",
      des_dis: "SAN JUAN",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "22",
      des_dis: "SAN PEDRO",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "31",
      des_dis: "SAN PEDRO DE PALCO",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "20",
      des_dis: "SANCOS",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "24",
      des_dis: "SANTA ANA DE HUAYCAHUACHO",
    },
    {
      cod_dep: "05",
      cod_pro: "05",
      cod_dis: "25",
      des_dis: "SANTA LUCIA",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "CORACORA",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "CHUMPI",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "CORONEL CASTAÑEDA",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "PACAPAUSA",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "11",
      des_dis: "PULLO",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "12",
      des_dis: "PUYUSCA",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "15",
      des_dis: "SAN FRANCISCO DE RAVACAYCO",
    },
    {
      cod_dep: "05",
      cod_pro: "06",
      cod_dis: "16",
      des_dis: "UPAHUACHO",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "PAUSA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "COLTA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "CORCULLA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "LAMPA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "MARCABAMBA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "06",
      des_dis: "OYOLO",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "07",
      des_dis: "PARARCA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "08",
      des_dis: "SAN JAVIER DE ALPABAMBA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "09",
      des_dis: "SAN JOSE DE USHUA",
    },
    {
      cod_dep: "05",
      cod_pro: "10",
      cod_dis: "10",
      des_dis: "SARA SARA",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "01",
      des_dis: "QUEROBAMBA",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "02",
      des_dis: "BELEN",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "03",
      des_dis: "CHALCOS",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "10",
      des_dis: "CHILCAYOC",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "09",
      des_dis: "HUACAÑA",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "11",
      des_dis: "MORCOLLA",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "05",
      des_dis: "PAICO",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "07",
      des_dis: "SAN PEDRO DE LARCAY",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "04",
      des_dis: "SAN SALVADOR DE QUIJE",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "06",
      des_dis: "SANTIAGO DE PAUCARAY",
    },
    {
      cod_dep: "05",
      cod_pro: "11",
      cod_dis: "08",
      des_dis: "SORAS",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "HUANCAPI",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "ALCAMENCA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "APONGO",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "15",
      des_dis: "ASQUIPATA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "CANARIA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "CAYARA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "COLCA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "09",
      des_dis: "HUAMANQUIQUIA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "10",
      des_dis: "HUANCARAYLLA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "HUAYA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "13",
      des_dis: "SARHUA",
    },
    {
      cod_dep: "05",
      cod_pro: "07",
      cod_dis: "14",
      des_dis: "VILCANCHOS",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "VILCAS HUAMAN",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "ACCOMARCA",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "CARHUANCA",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "CONCEPCION",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "06",
      des_dis: "HUAMBALPA",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "08",
      des_dis: "INDEPENDENCIA",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "07",
      des_dis: "SAURAMA",
    },
    {
      cod_dep: "05",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "VISCHONGO",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "CAJAMARCA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "ASUNCION",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CHETILLA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "COSPAN",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "ENCAÑADA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "JESUS",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "LLACANORA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "LOS BAÑOS DEL INCA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "MAGDALENA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "MATARA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "NAMORA",
    },
    {
      cod_dep: "06",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "SAN JUAN",
    },
    {
      cod_dep: "06",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "CAJABAMBA",
    },
    {
      cod_dep: "06",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "CACHACHI",
    },
    {
      cod_dep: "06",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CONDEBAMBA",
    },
    {
      cod_dep: "06",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "SITACOCHA",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "CELENDIN",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "CHUMUCH",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "CORTEGANA",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "HUASMIN",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "JORGE CHAVEZ",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "JOSE GALVEZ",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "MIGUEL IGLESIAS",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "OXAMARCA",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "SOROCHUCO",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "SUCRE",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "UTCO",
    },
    {
      cod_dep: "06",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "LA LIBERTAD DE PALLAN",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "CHOTA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "ANGUIA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "CHADIN",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "CHIGUIRIP",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "CHIMBAN",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "18",
      des_dis: "CHOROPAMPA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "COCHABAMBA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "CONCHAN",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "HUAMBOS",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "LAJAS",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "LLAMA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "11",
      des_dis: "MIRACOSTA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "12",
      des_dis: "PACCHA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "13",
      des_dis: "PION",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "14",
      des_dis: "QUEROCOTO",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "17",
      des_dis: "SAN JUAN DE LICUPIS",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "15",
      des_dis: "TACABAMBA",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "16",
      des_dis: "TOCMOCHE",
    },
    {
      cod_dep: "06",
      cod_pro: "06",
      cod_dis: "19",
      des_dis: "CHALAMARCA",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "CONTUMAZA",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "CHILETE",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "CUPISNIQUE",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "GUZMANGO",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "SAN BENITO",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "SANTA CRUZ DE TOLEDO",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "TANTARICA",
    },
    {
      cod_dep: "06",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "YONAN",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "CUTERVO",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "CALLAYUC",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "CHOROS",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "CUJILLO",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "LA RAMADA",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "PIMPINGOS",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "QUEROCOTILLO",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "SAN ANDRES DE CUTERVO",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "SAN JUAN DE CUTERVO",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "10",
      des_dis: "SAN LUIS DE LUCMA",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "11",
      des_dis: "SANTA CRUZ",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "12",
      des_dis: "SANTO DOMINGO DE LA CAPILLA",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "13",
      des_dis: "SANTO TOMAS",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "14",
      des_dis: "SOCOTA",
    },
    {
      cod_dep: "06",
      cod_pro: "05",
      cod_dis: "15",
      des_dis: "TORIBIO CASANOVA",
    },
    {
      cod_dep: "06",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "BAMBAMARCA",
    },
    {
      cod_dep: "06",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "CHUGUR",
    },
    {
      cod_dep: "06",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "HUALGAYOC",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "JAEN",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "BELLAVISTA",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "CHONTALI",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "COLASAY",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "12",
      des_dis: "HUABAL",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "11",
      des_dis: "LAS PIRIAS",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "POMAHUACA",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "PUCARA",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "07",
      des_dis: "SALLIQUE",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "08",
      des_dis: "SAN FELIPE",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "09",
      des_dis: "SAN JOSE DEL ALTO",
    },
    {
      cod_dep: "06",
      cod_pro: "08",
      cod_dis: "10",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      cod_dis: "01",
      des_dis: "SAN IGNACIO",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      cod_dis: "02",
      des_dis: "CHIRINOS",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      cod_dis: "03",
      des_dis: "HUARANGO",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      cod_dis: "05",
      des_dis: "LA COIPA",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      cod_dis: "04",
      des_dis: "NAMBALLE",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      cod_dis: "06",
      des_dis: "SAN JOSE DE LOURDES",
    },
    {
      cod_dep: "06",
      cod_pro: "11",
      cod_dis: "07",
      des_dis: "TABACONAS",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      cod_dis: "01",
      des_dis: "PEDRO GALVEZ",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      cod_dis: "07",
      des_dis: "CHANCAY",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      cod_dis: "05",
      des_dis: "EDUARDO VILLANUEVA",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      cod_dis: "03",
      des_dis: "GREGORIO PITA",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      cod_dis: "02",
      des_dis: "ICHOCAN",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      cod_dis: "04",
      des_dis: "JOSE MANUEL QUIROZ",
    },
    {
      cod_dep: "06",
      cod_pro: "12",
      cod_dis: "06",
      des_dis: "JOSE SABOGAL",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "SAN MIGUEL",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "13",
      des_dis: "BOLIVAR",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "CALQUIS",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "12",
      des_dis: "CATILLUC",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "09",
      des_dis: "EL PRADO",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "LA FLORIDA",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "LLAPA",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "NANCHOC",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "06",
      des_dis: "NIEPOS",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "07",
      des_dis: "SAN GREGORIO",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "08",
      des_dis: "SAN SILVESTRE DE COCHAN",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "11",
      des_dis: "TONGOD",
    },
    {
      cod_dep: "06",
      cod_pro: "10",
      cod_dis: "10",
      des_dis: "UNION AGUA BLANCA",
    },
    {
      cod_dep: "06",
      cod_pro: "13",
      cod_dis: "01",
      des_dis: "SAN PABLO",
    },
    {
      cod_dep: "06",
      cod_pro: "13",
      cod_dis: "02",
      des_dis: "SAN BERNARDINO",
    },
    {
      cod_dep: "06",
      cod_pro: "13",
      cod_dis: "03",
      des_dis: "SAN LUIS",
    },
    {
      cod_dep: "06",
      cod_pro: "13",
      cod_dis: "04",
      des_dis: "TUMBADEN",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "SANTA CRUZ",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "10",
      des_dis: "ANDABAMBA",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "CATACHE",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "CHANCAYBAÑOS",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "LA ESPERANZA",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "NINABAMBA",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "06",
      des_dis: "PULAN",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "11",
      des_dis: "SAUCEPAMPA",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "07",
      des_dis: "SEXI",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "08",
      des_dis: "UTICYACU",
    },
    {
      cod_dep: "06",
      cod_pro: "09",
      cod_dis: "09",
      des_dis: "YAUYUCAN",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "CALLAO",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "BELLAVISTA",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CARMEN DE LA LEGUA REYNOSO",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "LA PERLA",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "LA PUNTA",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "VENTANILLA",
    },
    {
      cod_dep: "24",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "MI PERU",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "CUSCO",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CCORCA",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "POROY",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "SAN JERONIMO",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "SAN SEBASTIAN",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "SANTIAGO",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "SAYLLA",
    },
    {
      cod_dep: "07",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "WANCHAQ",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "ACOMAYO",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "ACOPIA",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "ACOS",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "MOSOC LLACTA",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "POMACANCHI",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "RONDOCAN",
    },
    {
      cod_dep: "07",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "SANGARARA",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "ANTA",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "ANCAHUASI",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "CACHIMAYO",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "CHINCHAYPUJIO",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "HUAROCONDO",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "LIMATAMBO",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "MOLLEPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "PUCYURA",
    },
    {
      cod_dep: "07",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "ZURITE",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "CALCA",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "COYA",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "LAMAY",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "LARES",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "PISAC",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "SAN SALVADOR",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "TARAY",
    },
    {
      cod_dep: "07",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "YANATILE",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "YANAOCA",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "CHECCA",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "KUNTURKANKI",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "LANGUI",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "LAYO",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "PAMPAMARCA",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "QUEHUE",
    },
    {
      cod_dep: "07",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "TUPAC AMARU",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "SICUANI",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "CHECACUPE",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "COMBAPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "MARANGANI",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "PITUMARCA",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "SAN PABLO",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "SAN PEDRO",
    },
    {
      cod_dep: "07",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "TINTA",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "SANTO TOMAS",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "CAPACMARCA",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "CHAMACA",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "COLQUEMARCA",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "LIVITACA",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "LLUSCO",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "QUIÑOTA",
    },
    {
      cod_dep: "07",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "VELILLE",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "ESPINAR",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "CONDOROMA",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "COPORAQUE",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "OCORURO",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "PALLPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "PICHIGUA",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "07",
      des_dis: "SUYCKUTAMBO",
    },
    {
      cod_dep: "07",
      cod_pro: "08",
      cod_dis: "08",
      des_dis: "ALTO PICHIGUA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "SANTA ANA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "ECHARATE",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "HUAYOPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "MARANURA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "OCOBAMBA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "08",
      des_dis: "QUELLOUNO",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "09",
      des_dis: "QUIMBIRI",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "06",
      des_dis: "SANTA TERESA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "07",
      des_dis: "VILCABAMBA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "10",
      des_dis: "PICHARI",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "11",
      des_dis: "INKAWASI",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "12",
      des_dis: "VILLA VIRGEN",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "13",
      des_dis: "VILLA KINTIARINA",
    },
    {
      cod_dep: "07",
      cod_pro: "09",
      cod_dis: "15",
      des_dis: "MEGANTONI",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "PARURO",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "ACCHA",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "CCAPI",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "COLCHA",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "HUANOQUITE",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "06",
      des_dis: "OMACHA",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "08",
      des_dis: "PACCARITAMBO",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "09",
      des_dis: "PILLPINTO",
    },
    {
      cod_dep: "07",
      cod_pro: "10",
      cod_dis: "07",
      des_dis: "YAURISQUE",
    },
    {
      cod_dep: "07",
      cod_pro: "11",
      cod_dis: "01",
      des_dis: "PAUCARTAMBO",
    },
    {
      cod_dep: "07",
      cod_pro: "11",
      cod_dis: "02",
      des_dis: "CAICAY",
    },
    {
      cod_dep: "07",
      cod_pro: "11",
      cod_dis: "04",
      des_dis: "CHALLABAMBA",
    },
    {
      cod_dep: "07",
      cod_pro: "11",
      cod_dis: "03",
      des_dis: "COLQUEPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "11",
      cod_dis: "06",
      des_dis: "HUANCARANI",
    },
    {
      cod_dep: "07",
      cod_pro: "11",
      cod_dis: "05",
      des_dis: "KOSÑIPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "01",
      des_dis: "URCOS",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "02",
      des_dis: "ANDAHUAYLILLAS",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "03",
      des_dis: "CAMANTI",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "04",
      des_dis: "CCARHUAYO",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "05",
      des_dis: "CCATCA",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "06",
      des_dis: "CUSIPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "07",
      des_dis: "HUARO",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "08",
      des_dis: "LUCRE",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "09",
      des_dis: "MARCAPATA",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "10",
      des_dis: "OCONGATE",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "11",
      des_dis: "OROPESA",
    },
    {
      cod_dep: "07",
      cod_pro: "12",
      cod_dis: "12",
      des_dis: "QUIQUIJANA",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      cod_dis: "01",
      des_dis: "URUBAMBA",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      cod_dis: "02",
      des_dis: "CHINCHERO",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      cod_dis: "03",
      des_dis: "HUAYLLABAMBA",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      cod_dis: "04",
      des_dis: "MACHUPICCHU",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      cod_dis: "05",
      des_dis: "MARAS",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      cod_dis: "06",
      des_dis: "OLLANTAYTAMBO",
    },
    {
      cod_dep: "07",
      cod_pro: "13",
      cod_dis: "07",
      des_dis: "YUCAY",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "HUANCAVELICA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "ACOBAMBILLA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "ACORIA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CONAYCA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "CUENCA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "HUACHOCOLPA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "HUAYLLAHUARA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "IZCUCHACA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "LARIA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "MANTA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "MARISCAL CACERES",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "MOYA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "NUEVO OCCORO",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "PALCA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "16",
      des_dis: "PILCHACA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "17",
      des_dis: "VILCA",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "18",
      des_dis: "YAULI",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "19",
      des_dis: "ASCENSION",
    },
    {
      cod_dep: "08",
      cod_pro: "01",
      cod_dis: "20",
      des_dis: "HUANDO",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "ACOBAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "ANDABAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "ANTA",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "CAJA",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "MARCAS",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "PAUCARA",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "POMACOCHA",
    },
    {
      cod_dep: "08",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "ROSARIO",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "LIRCAY",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "ANCHONGA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "CALLANMARCA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "CCOCHACCASA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "CHINCHO",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "CONGALLA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "HUANCA-HUANCA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "HUAYLLAY GRANDE",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "JULCAMARCA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "SAN ANTONIO DE ANTAPARCO",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "SANTO TOMAS DE PATA",
    },
    {
      cod_dep: "08",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "SECCLLA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "CASTROVIRREYNA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "ARMA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "AURAHUA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "CAPILLAS",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "CHUPAMARCA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "COCAS",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "HUACHOS",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "HUAMATAMBO",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "14",
      des_dis: "MOLLEPAMPA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "22",
      des_dis: "SAN JUAN",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "29",
      des_dis: "SANTA ANA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "27",
      des_dis: "TANTARA",
    },
    {
      cod_dep: "08",
      cod_pro: "04",
      cod_dis: "28",
      des_dis: "TICRAPO",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "CHURCAMPA",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "ANCO",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "CHINCHIHUASI",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "EL CARMEN",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "LA MERCED",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "LOCROJA",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "PAUCARBAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "SAN MIGUEL DE MAYOCC",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "09",
      des_dis: "SAN PEDRO DE CORIS",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "10",
      des_dis: "PACHAMARCA",
    },
    {
      cod_dep: "08",
      cod_pro: "07",
      cod_dis: "11",
      des_dis: "COSME",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "HUAYTARA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "AYAVI",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "CORDOVA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "HUAYACUNDO ARMA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "LARAMARCA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "OCOYO",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "PILPICHACA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "QUERCO",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "QUITO-ARMA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "SAN ANTONIO DE CUSICANCHA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "11",
      des_dis: "SAN FRANCISCO DE SANGAYAICO",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "12",
      des_dis: "SAN ISIDRO",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "13",
      des_dis: "SANTIAGO DE CHOCORVOS",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "14",
      des_dis: "SANTIAGO DE QUIRAHUARA",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "15",
      des_dis: "SANTO DOMINGO DE CAPILLAS",
    },
    {
      cod_dep: "08",
      cod_pro: "06",
      cod_dis: "16",
      des_dis: "TAMBO",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "PAMPAS",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "ACOSTAMBO",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "ACRAQUIA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "AHUAYCHA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "COLCABAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "DANIEL HERNANDEZ",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "11",
      des_dis: "HUACHOCOLPA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "12",
      des_dis: "HUARIBAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "15",
      des_dis: "ÑAHUIMPUQUIO",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "17",
      des_dis: "PAZOS",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "18",
      des_dis: "QUISHUAR",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "19",
      des_dis: "SALCABAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "26",
      des_dis: "SALCAHUASI",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "20",
      des_dis: "SAN MARCOS DE ROCCHAC",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "23",
      des_dis: "SURCUBAMBA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "25",
      des_dis: "TINTAY PUNCU",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "28",
      des_dis: "QUICHUAS",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "29",
      des_dis: "ANDAYMARCA",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "30",
      des_dis: "ROBLE",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "31",
      des_dis: "PICHOS",
    },
    {
      cod_dep: "08",
      cod_pro: "05",
      cod_dis: "32",
      des_dis: "SANTIAGO DE TUCUMA",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "HUANUCO",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "AMARILIS",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CHINCHAO",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "CHURUBAMBA",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "MARGOS",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "QUISQUI",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "SAN FRANCISCO DE CAYRAN",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "SAN PEDRO DE CHAULAN",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "SANTA MARIA DEL VALLE",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "YARUMAYO",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "PILLCO MARCA",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "YACUS",
    },
    {
      cod_dep: "09",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "SAN PABLO DE PILLAO",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "AMBO",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "CAYNA",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "COLPAS",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "CONCHAMARCA",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "HUACAR",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "SAN FRANCISCO",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "SAN RAFAEL",
    },
    {
      cod_dep: "09",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "TOMAY KICHWA",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "LA UNION",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "CHUQUIS",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "MARIAS",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "14",
      des_dis: "PACHAS",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "16",
      des_dis: "QUIVILLA",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "17",
      des_dis: "RIPAN",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "21",
      des_dis: "SHUNQUI",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "22",
      des_dis: "SILLAPATA",
    },
    {
      cod_dep: "09",
      cod_pro: "03",
      cod_dis: "23",
      des_dis: "YANAS",
    },
    {
      cod_dep: "09",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "HUACAYBAMBA",
    },
    {
      cod_dep: "09",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "CANCHABAMBA",
    },
    {
      cod_dep: "09",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "COCHABAMBA",
    },
    {
      cod_dep: "09",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "PINRA",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "LLATA",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "ARANCAY",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "CHAVIN DE PARIARCA",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "JACAS GRANDE",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "JIRCAN",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "MIRAFLORES",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "MONZON",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "PUNCHAO",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "PUÑOS",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "SINGA",
    },
    {
      cod_dep: "09",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "TANTAMAYO",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "RUPA-RUPA",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "DANIEL ALOMIAS ROBLES",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "HERMILIO VALDIZAN",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "JOSE CRESPO Y CASTILLO",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "LUYANDO",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "MARIANO DAMASO BERAUN",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "PUCAYACU",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "CASTILLO GRANDE",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "PUEBLO NUEVO",
    },
    {
      cod_dep: "09",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "SANTO DOMINGO DE ANDA",
    },
    {
      cod_dep: "09",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "HUACRACHUCO",
    },
    {
      cod_dep: "09",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "CHOLON",
    },
    {
      cod_dep: "09",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "SAN BUENAVENTURA",
    },
    {
      cod_dep: "09",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "LA MORADA",
    },
    {
      cod_dep: "09",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "SANTA ROSA DE ALTO YANAJANCA",
    },
    {
      cod_dep: "09",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "PANAO",
    },
    {
      cod_dep: "09",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "CHAGLLA",
    },
    {
      cod_dep: "09",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "MOLINO",
    },
    {
      cod_dep: "09",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "UMARI",
    },
    {
      cod_dep: "09",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "PUERTO INCA",
    },
    {
      cod_dep: "09",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "CODO DEL POZUZO",
    },
    {
      cod_dep: "09",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "HONORIA",
    },
    {
      cod_dep: "09",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "TOURNAVISTA",
    },
    {
      cod_dep: "09",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "YUYAPICHIS",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "JESUS",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "BAÑOS",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      cod_dis: "07",
      des_dis: "JIVIA",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "QUEROPALCA",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      cod_dis: "06",
      des_dis: "RONDOS",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "SAN FRANCISCO DE ASIS",
    },
    {
      cod_dep: "09",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "SAN MIGUEL DE CAURI",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "01",
      des_dis: "CHAVINILLO",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "03",
      des_dis: "CAHUAC",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "04",
      des_dis: "CHACABAMBA",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "02",
      des_dis: "APARICIO POMARES",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "05",
      des_dis: "JACAS CHICO",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "06",
      des_dis: "OBAS",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "07",
      des_dis: "PAMPAMARCA",
    },
    {
      cod_dep: "09",
      cod_pro: "11",
      cod_dis: "08",
      des_dis: "CHORAS",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "ICA",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "LA TINGUIÑA",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "LOS AQUIJES",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "OCUCAJE",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "PACHACUTEC",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "PARCONA",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "PUEBLO NUEVO",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "SALAS",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "SAN JOSE DE LOS MOLINOS",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "SAN JUAN BAUTISTA",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "SANTIAGO",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "SUBTANJALLA",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "TATE",
    },
    {
      cod_dep: "10",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "YAUCA DEL ROSARIO",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "CHINCHA ALTA",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "09",
      des_dis: "ALTO LARAN",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "CHAVIN",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CHINCHA BAJA",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "EL CARMEN",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "GROCIO PRADO",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "PUEBLO NUEVO",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "SAN JUAN DE YANAC",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "SAN PEDRO DE HUACARPANA",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "SUNAMPE",
    },
    {
      cod_dep: "10",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "TAMBO DE MORA",
    },
    {
      cod_dep: "10",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "NAZCA",
    },
    {
      cod_dep: "10",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "CHANGUILLO",
    },
    {
      cod_dep: "10",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "EL INGENIO",
    },
    {
      cod_dep: "10",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "MARCONA",
    },
    {
      cod_dep: "10",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "VISTA ALEGRE",
    },
    {
      cod_dep: "10",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "PALPA",
    },
    {
      cod_dep: "10",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "LLIPATA",
    },
    {
      cod_dep: "10",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "RIO GRANDE",
    },
    {
      cod_dep: "10",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "SANTA CRUZ",
    },
    {
      cod_dep: "10",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "TIBILLO",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "PISCO",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "HUANCANO",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "HUMAY",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "INDEPENDENCIA",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "PARACAS",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "SAN ANDRES",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "SAN CLEMENTE",
    },
    {
      cod_dep: "10",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "TUPAC AMARU INCA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "HUANCAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "CARHUACALLANGA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "CHACAPAMPA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "CHICCHE",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "CHILCA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "CHONGOS ALTO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "CHUPURO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "COLCA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "CULLHUAS",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "EL TAMBO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "HUACRAPUQUIO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "16",
      des_dis: "HUALHUAS",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "18",
      des_dis: "HUANCAN",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "19",
      des_dis: "HUASICANCHA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "20",
      des_dis: "HUAYUCACHI",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "21",
      des_dis: "INGENIO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "22",
      des_dis: "PARIAHUANCA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "23",
      des_dis: "PILCOMAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "24",
      des_dis: "PUCARA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "25",
      des_dis: "QUICHUAY",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "26",
      des_dis: "QUILCAS",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "27",
      des_dis: "SAN AGUSTIN",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "28",
      des_dis: "SAN JERONIMO DE TUNAN",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "32",
      des_dis: "SAÑO",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "33",
      des_dis: "SAPALLANGA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "34",
      des_dis: "SICAYA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "31",
      des_dis: "SANTO DOMINGO DE ACOBAMBA",
    },
    {
      cod_dep: "11",
      cod_pro: "01",
      cod_dis: "36",
      des_dis: "VIQUES",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "CONCEPCION",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "ACO",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "ANDAMARCA",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "CHAMBARA",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "COCHAS",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "COMAS",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "HEROINAS TOLEDO",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "MANZANARES",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "09",
      des_dis: "MARISCAL CASTILLA",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "MATAHUASI",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "MITO",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "12",
      des_dis: "NUEVE DE JULIO",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "13",
      des_dis: "ORCOTUNA",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "15",
      des_dis: "SAN JOSE DE QUERO",
    },
    {
      cod_dep: "11",
      cod_pro: "02",
      cod_dis: "14",
      des_dis: "SANTA ROSA DE OCOPA",
    },
    {
      cod_dep: "11",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "CHANCHAMAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "PERENE",
    },
    {
      cod_dep: "11",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "PICHANAQUI",
    },
    {
      cod_dep: "11",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "SAN LUIS DE SHUARO",
    },
    {
      cod_dep: "11",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "SAN RAMON",
    },
    {
      cod_dep: "11",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "VITOC",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "JAUJA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "ACOLLA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "APATA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "ATAURA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "CANCHAYLLO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "31",
      des_dis: "CURICACA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "EL MANTARO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "HUAMALI",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "HUARIPAMPA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "HUERTAS",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "JANJAILLO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "JULCAN",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "LEONOR ORDOÑEZ",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "13",
      des_dis: "LLOCLLAPAMPA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "14",
      des_dis: "MARCO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "15",
      des_dis: "MASMA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "32",
      des_dis: "MASMA CHICCHE",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "16",
      des_dis: "MOLINOS",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "17",
      des_dis: "MONOBAMBA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "18",
      des_dis: "MUQUI",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "19",
      des_dis: "MUQUIYAUYO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "20",
      des_dis: "PACA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "21",
      des_dis: "PACCHA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "22",
      des_dis: "PANCAN",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "23",
      des_dis: "PARCO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "24",
      des_dis: "POMACANCHA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "25",
      des_dis: "RICRAN",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "26",
      des_dis: "SAN LORENZO",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "27",
      des_dis: "SAN PEDRO DE CHUNAN",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "33",
      des_dis: "SAUSA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "28",
      des_dis: "SINCOS",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "29",
      des_dis: "TUNAN MARCA",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "30",
      des_dis: "YAULI",
    },
    {
      cod_dep: "11",
      cod_pro: "03",
      cod_dis: "34",
      des_dis: "YAUYOS",
    },
    {
      cod_dep: "11",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "JUNIN",
    },
    {
      cod_dep: "11",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "CARHUAMAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "ONDORES",
    },
    {
      cod_dep: "11",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "ULCUMAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "SATIPO",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "COVIRIALI",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "LLAYLLA",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "MAZAMARI",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "PAMPA HERMOSA",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "PANGOA",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "RIO NEGRO",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "RIO TAMBO",
    },
    {
      cod_dep: "11",
      cod_pro: "07",
      cod_dis: "09",
      des_dis: "VIZCATAN DEL ENE",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "TARMA",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "ACOBAMBA",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "HUARICOLCA",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "HUASAHUASI",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "LA UNION",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "PALCA",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "PALCAMAYO",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "SAN PEDRO DE CAJAS",
    },
    {
      cod_dep: "11",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "TAPO",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "LA OROYA",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "CHACAPALPA",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "HUAY-HUAY",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "MARCAPOMACOCHA",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "MOROCOCHA",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "PACCHA",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "SANTA BARBARA DE CARHUACAYAN",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "SANTA ROSA DE SACCO",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "SUITUCANCHA",
    },
    {
      cod_dep: "11",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "YAULI",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "CHUPACA",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "AHUAC",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "CHONGOS BAJO",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "HUACHAC",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "HUAMANCACA CHICO",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "06",
      des_dis: "SAN JUAN DE YSCOS",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "07",
      des_dis: "SAN JUAN DE JARPA",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "08",
      des_dis: "TRES DE DICIEMBRE",
    },
    {
      cod_dep: "11",
      cod_pro: "09",
      cod_dis: "09",
      des_dis: "YANACANCHA",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "TRUJILLO",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "EL PORVENIR",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "FLORENCIA DE MORA",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "HUANCHACO",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "LA ESPERANZA",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "LAREDO",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "MOCHE",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "POROTO",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "SALAVERRY",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "SIMBAL",
    },
    {
      cod_dep: "12",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "VICTOR LARCO HERRERA",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "ASCOPE",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "CHICAMA",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "CHOCOPE",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "MAGDALENA DE CAO",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "PAIJAN",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "07",
      des_dis: "RAZURI",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "SANTIAGO DE CAO",
    },
    {
      cod_dep: "12",
      cod_pro: "08",
      cod_dis: "08",
      des_dis: "CASA GRANDE",
    },
    {
      cod_dep: "12",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "BOLIVAR",
    },
    {
      cod_dep: "12",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "BAMBAMARCA",
    },
    {
      cod_dep: "12",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CONDORMARCA",
    },
    {
      cod_dep: "12",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "LONGOTEA",
    },
    {
      cod_dep: "12",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "UCHUMARCA",
    },
    {
      cod_dep: "12",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "UCUNCHA",
    },
    {
      cod_dep: "12",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "CHEPEN",
    },
    {
      cod_dep: "12",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "PACANGA",
    },
    {
      cod_dep: "12",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "PUEBLO NUEVO",
    },
    {
      cod_dep: "12",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "JULCAN",
    },
    {
      cod_dep: "12",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "CALAMARCA",
    },
    {
      cod_dep: "12",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "CARABAMBA",
    },
    {
      cod_dep: "12",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "HUASO",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "OTUZCO",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "AGALLPAMPA",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "CHARAT",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "HUARANCHAL",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "LA CUESTA",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "13",
      des_dis: "MACHE",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "PARANDAY",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "SALPO",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "SINSICAP",
    },
    {
      cod_dep: "12",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "USQUIL",
    },
    {
      cod_dep: "12",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "SAN PEDRO DE LLOC",
    },
    {
      cod_dep: "12",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "GUADALUPE",
    },
    {
      cod_dep: "12",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "JEQUETEPEQUE",
    },
    {
      cod_dep: "12",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "PACASMAYO",
    },
    {
      cod_dep: "12",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "SAN JOSE",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "TAYABAMBA",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "BULDIBUYO",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "CHILLIA",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "HUANCASPATA",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "HUAYLILLAS",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "HUAYO",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "ONGON",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "PARCOY",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "PATAZ",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "PIAS",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "13",
      des_dis: "SANTIAGO DE CHALLAS",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "11",
      des_dis: "TAURIJA",
    },
    {
      cod_dep: "12",
      cod_pro: "06",
      cod_dis: "12",
      des_dis: "URPAY",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "HUAMACHUCO",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "CHUGAY",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "COCHORCO",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "CURGOS",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "MARCABAL",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "SANAGORAN",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "SARIN",
    },
    {
      cod_dep: "12",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "SARTIMBAMBA",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "SANTIAGO DE CHUCO",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "ANGASMARCA",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "CACHICADAN",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "MOLLEBAMBA",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "MOLLEPATA",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "QUIRUVILCA",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "SANTA CRUZ DE CHUCA",
    },
    {
      cod_dep: "12",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "SITABAMBA",
    },
    {
      cod_dep: "12",
      cod_pro: "11",
      cod_dis: "01",
      des_dis: "CASCAS",
    },
    {
      cod_dep: "12",
      cod_pro: "11",
      cod_dis: "02",
      des_dis: "LUCMA",
    },
    {
      cod_dep: "12",
      cod_pro: "11",
      cod_dis: "03",
      des_dis: "MARMOT",
    },
    {
      cod_dep: "12",
      cod_pro: "11",
      cod_dis: "04",
      des_dis: "SAYAPULLO",
    },
    {
      cod_dep: "12",
      cod_pro: "12",
      cod_dis: "01",
      des_dis: "VIRU",
    },
    {
      cod_dep: "12",
      cod_pro: "12",
      cod_dis: "02",
      des_dis: "CHAO",
    },
    {
      cod_dep: "12",
      cod_pro: "12",
      cod_dis: "03",
      des_dis: "GUADALUPITO",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "CHICLAYO",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CHONGOYAPE",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "ETEN",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "ETEN PUERTO",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "JOSE LEONARDO ORTIZ",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "LA VICTORIA",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "LAGUNAS",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "MONSEFU",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "NUEVA ARICA",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "OYOTUN",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "PICSI",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "PIMENTEL",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "REQUE",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "SAÑA",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "16",
      des_dis: "CAYALTI",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "17",
      des_dis: "PATAPO",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "18",
      des_dis: "POMALCA",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "19",
      des_dis: "PUCALA",
    },
    {
      cod_dep: "13",
      cod_pro: "01",
      cod_dis: "20",
      des_dis: "TUMAN",
    },
    {
      cod_dep: "13",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "FERREÑAFE",
    },
    {
      cod_dep: "13",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CAÑARIS",
    },
    {
      cod_dep: "13",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "INCAHUASI",
    },
    {
      cod_dep: "13",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "MANUEL ANTONIO MESONES MURO",
    },
    {
      cod_dep: "13",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "PITIPO",
    },
    {
      cod_dep: "13",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "PUEBLO NUEVO",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "LAMBAYEQUE",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "CHOCHOPE",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "ILLIMO",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "JAYANCA",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "MOCHUMI",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "MORROPE",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "MOTUPE",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "OLMOS",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "PACORA",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "SALAS",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "SAN JOSE",
    },
    {
      cod_dep: "13",
      cod_pro: "03",
      cod_dis: "12",
      des_dis: "TUCUME",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "LIMA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "ANCON",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "ATE",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "25",
      des_dis: "BARRANCO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "BREÑA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "CARABAYLLO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "CHACLACAYO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "CHORRILLOS",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "39",
      des_dis: "CIENEGUILLA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "COMAS",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "35",
      des_dis: "EL AGUSTINO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "34",
      des_dis: "INDEPENDENCIA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "33",
      des_dis: "JESUS MARIA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "LA MOLINA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "LA VICTORIA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "LINCE",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "42",
      des_dis: "LOS OLIVOS",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "LURIGANCHO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "LURIN",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "MAGDALENA DEL MAR",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "17",
      des_dis: "PUEBLO LIBRE",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "MIRAFLORES",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "16",
      des_dis: "PACHACAMAC",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "18",
      des_dis: "PUCUSANA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "19",
      des_dis: "PUENTE PIEDRA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "20",
      des_dis: "PUNTA HERMOSA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "21",
      des_dis: "PUNTA NEGRA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "22",
      des_dis: "RIMAC",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "23",
      des_dis: "SAN BARTOLO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "40",
      des_dis: "SAN BORJA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "24",
      des_dis: "SAN ISIDRO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "37",
      des_dis: "SAN JUAN DE LURIGANCHO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "36",
      des_dis: "SAN JUAN DE MIRAFLORES",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "38",
      des_dis: "SAN LUIS",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "26",
      des_dis: "SAN MARTIN DE PORRES",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "27",
      des_dis: "SAN MIGUEL",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "43",
      des_dis: "SANTA ANITA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "28",
      des_dis: "SANTA MARIA DEL MAR",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "29",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "30",
      des_dis: "SANTIAGO DE SURCO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "31",
      des_dis: "SURQUILLO",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "41",
      des_dis: "VILLA EL SALVADOR",
    },
    {
      cod_dep: "14",
      cod_pro: "01",
      cod_dis: "32",
      des_dis: "VILLA MARIA DEL TRIUNFO",
    },
    {
      cod_dep: "14",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "BARRANCA",
    },
    {
      cod_dep: "14",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "PARAMONGA",
    },
    {
      cod_dep: "14",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "PATIVILCA",
    },
    {
      cod_dep: "14",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "SUPE",
    },
    {
      cod_dep: "14",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "SUPE PUERTO",
    },
    {
      cod_dep: "14",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "CAJATAMBO",
    },
    {
      cod_dep: "14",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "COPA",
    },
    {
      cod_dep: "14",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "GORGOR",
    },
    {
      cod_dep: "14",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "HUANCAPON",
    },
    {
      cod_dep: "14",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "MANAS",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "CANTA",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "ARAHUAY",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "HUAMANTANGA",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "HUAROS",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "LACHAQUI",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "SAN BUENAVENTURA",
    },
    {
      cod_dep: "14",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "SANTA ROSA DE QUIVES",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "SAN VICENTE DE CAÑETE",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "16",
      des_dis: "ASIA",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "CALANGO",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "CERRO AZUL",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "CHILCA",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "COAYLLO",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "IMPERIAL",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "LUNAHUANA",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "MALA",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "NUEVO IMPERIAL",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "PACARAN",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "QUILMANA",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "12",
      des_dis: "SAN ANTONIO",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "13",
      des_dis: "SAN LUIS",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "14",
      des_dis: "SANTA CRUZ DE FLORES",
    },
    {
      cod_dep: "14",
      cod_pro: "04",
      cod_dis: "15",
      des_dis: "ZUÑIGA",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "HUARAL",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "ATAVILLOS ALTO",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "ATAVILLOS BAJO",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "AUCALLAMA",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "CHANCAY",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "IHUARI",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "07",
      des_dis: "LAMPIAN",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "08",
      des_dis: "PACARAOS",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "09",
      des_dis: "SAN MIGUEL DE ACOS",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "11",
      des_dis: "SANTA CRUZ DE ANDAMARCA",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "12",
      des_dis: "SUMBILCA",
    },
    {
      cod_dep: "14",
      cod_pro: "08",
      cod_dis: "10",
      des_dis: "VEINTISIETE DE NOVIEMBRE",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "MATUCANA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "ANTIOQUIA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "CALLAHUANCA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "CARAMPOMA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "CHICLA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "CUENCA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "30",
      des_dis: "HUACHUPAMPA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "HUANZA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "HUAROCHIRI",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "LAHUAYTAMBO",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "11",
      des_dis: "LANGA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "31",
      des_dis: "LARAOS",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "12",
      des_dis: "MARIATANA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "13",
      des_dis: "RICARDO PALMA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "14",
      des_dis: "SAN ANDRES DE TUPICOCHA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "15",
      des_dis: "SAN ANTONIO",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "16",
      des_dis: "SAN BARTOLOME",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "17",
      des_dis: "SAN DAMIAN",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "32",
      des_dis: "SAN JUAN DE IRIS",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "19",
      des_dis: "SAN JUAN DE TANTARANCHE",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "20",
      des_dis: "SAN LORENZO DE QUINTI",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "21",
      des_dis: "SAN MATEO",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "22",
      des_dis: "SAN MATEO DE OTAO",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "SAN PEDRO DE CASTA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "23",
      des_dis: "SAN PEDRO DE HUANCAYRE",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "18",
      des_dis: "SANGALLAYA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "24",
      des_dis: "SANTA CRUZ DE COCACHACRA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "25",
      des_dis: "SANTA EULALIA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "26",
      des_dis: "SANTIAGO DE ANCHUCAYA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "27",
      des_dis: "SANTIAGO DE TUNA",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "28",
      des_dis: "SANTO DOMINGO DE LOS OLLEROS",
    },
    {
      cod_dep: "14",
      cod_pro: "06",
      cod_dis: "29",
      des_dis: "SURCO",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "HUACHO",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "AMBAR",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "CALETA DE CARQUIN",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "CHECRAS",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "HUALMAY",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "HUAURA",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "LEONCIO PRADO",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "PACCHO",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "11",
      des_dis: "SANTA LEONOR",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "12",
      des_dis: "SANTA MARIA",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "13",
      des_dis: "SAYAN",
    },
    {
      cod_dep: "14",
      cod_pro: "05",
      cod_dis: "16",
      des_dis: "VEGUETA",
    },
    {
      cod_dep: "14",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "OYON",
    },
    {
      cod_dep: "14",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "ANDAJES",
    },
    {
      cod_dep: "14",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "CAUJUL",
    },
    {
      cod_dep: "14",
      cod_pro: "10",
      cod_dis: "06",
      des_dis: "COCHAMARCA",
    },
    {
      cod_dep: "14",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "NAVAN",
    },
    {
      cod_dep: "14",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "PACHANGARA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "YAUYOS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "ALIS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "AYAUCA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "AYAVIRI",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "AZANGARO",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "CACRA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "CARANIA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "33",
      des_dis: "CATAHUASI",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "10",
      des_dis: "CHOCOS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "COCHAS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "09",
      des_dis: "COLONIA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "30",
      des_dis: "HONGOS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "11",
      des_dis: "HUAMPARA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "12",
      des_dis: "HUANCAYA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "13",
      des_dis: "HUANGASCAR",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "14",
      des_dis: "HUANTAN",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "15",
      des_dis: "HUAÑEC",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "16",
      des_dis: "LARAOS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "17",
      des_dis: "LINCHA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "31",
      des_dis: "MADEAN",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "18",
      des_dis: "MIRAFLORES",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "19",
      des_dis: "OMAS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "32",
      des_dis: "PUTINZA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "20",
      des_dis: "QUINCHES",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "21",
      des_dis: "QUINOCAY",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "22",
      des_dis: "SAN JOAQUIN",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "23",
      des_dis: "SAN PEDRO DE PILAS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "24",
      des_dis: "TANTA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "25",
      des_dis: "TAURIPAMPA",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "27",
      des_dis: "TOMAS",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "26",
      des_dis: "TUPE",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "28",
      des_dis: "VIÑAC",
    },
    {
      cod_dep: "14",
      cod_pro: "07",
      cod_dis: "29",
      des_dis: "VITIS",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "IQUITOS",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "ALTO NANAY",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "FERNANDO LORES",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "INDIANA",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "LAS AMAZONAS",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "MAZAN",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "NAPO",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "PUNCHANA",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "TORRES CAUSANA",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "BELEN",
    },
    {
      cod_dep: "15",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "SAN JUAN BAUTISTA",
    },
    {
      cod_dep: "15",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "YURIMAGUAS",
    },
    {
      cod_dep: "15",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "BALSAPUERTO",
    },
    {
      cod_dep: "15",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "JEBEROS",
    },
    {
      cod_dep: "15",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "LAGUNAS",
    },
    {
      cod_dep: "15",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "SANTA CRUZ",
    },
    {
      cod_dep: "15",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "TENIENTE CESAR LOPEZ ROJAS",
    },
    {
      cod_dep: "15",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "NAUTA",
    },
    {
      cod_dep: "15",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "PARINARI",
    },
    {
      cod_dep: "15",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "TIGRE",
    },
    {
      cod_dep: "15",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "TROMPETEROS",
    },
    {
      cod_dep: "15",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "URARINAS",
    },
    {
      cod_dep: "15",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "RAMON CASTILLA",
    },
    {
      cod_dep: "15",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "PEBAS",
    },
    {
      cod_dep: "15",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "YAVARI",
    },
    {
      cod_dep: "15",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "SAN PABLO",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "REQUENA",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "ALTO TAPICHE",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "CAPELO",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "EMILIO SAN MARTIN",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "MAQUIA",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "PUINAHUA",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "SAQUENA",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "SOPLIN",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "TAPICHE",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "JENARO HERRERA",
    },
    {
      cod_dep: "15",
      cod_pro: "04",
      cod_dis: "11",
      des_dis: "YAQUERANA",
    },
    {
      cod_dep: "15",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "CONTAMANA",
    },
    {
      cod_dep: "15",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "INAHUAYA",
    },
    {
      cod_dep: "15",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "PADRE MARQUEZ",
    },
    {
      cod_dep: "15",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "PAMPA HERMOSA",
    },
    {
      cod_dep: "15",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "SARAYACU",
    },
    {
      cod_dep: "15",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "VARGAS GUERRA",
    },
    {
      cod_dep: "15",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "BARRANCA",
    },
    {
      cod_dep: "15",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "CAHUAPANAS",
    },
    {
      cod_dep: "15",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "MANSERICHE",
    },
    {
      cod_dep: "15",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "MORONA",
    },
    {
      cod_dep: "15",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "PASTAZA",
    },
    {
      cod_dep: "15",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "ANDOAS",
    },
    {
      cod_dep: "15",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "PUTUMAYO",
    },
    {
      cod_dep: "15",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "ROSA PANDURO",
    },
    {
      cod_dep: "15",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "TENIENTE MANUEL CLAVERO",
    },
    {
      cod_dep: "15",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "YAGUAS",
    },
    {
      cod_dep: "16",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "TAMBOPATA",
    },
    {
      cod_dep: "16",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "INAMBARI",
    },
    {
      cod_dep: "16",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "LAS PIEDRAS",
    },
    {
      cod_dep: "16",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "LABERINTO",
    },
    {
      cod_dep: "16",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "MANU",
    },
    {
      cod_dep: "16",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "FITZCARRALD",
    },
    {
      cod_dep: "16",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "MADRE DE DIOS",
    },
    {
      cod_dep: "16",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "HUEPETUHE",
    },
    {
      cod_dep: "16",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "IÑAPARI",
    },
    {
      cod_dep: "16",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "IBERIA",
    },
    {
      cod_dep: "16",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "TAHUAMANU",
    },
    {
      cod_dep: "17",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "MOQUEGUA",
    },
    {
      cod_dep: "17",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CARUMAS",
    },
    {
      cod_dep: "17",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "CUCHUMBAYA",
    },
    {
      cod_dep: "17",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "SAMEGUA",
    },
    {
      cod_dep: "17",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "SAN CRISTOBAL",
    },
    {
      cod_dep: "17",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "TORATA",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "OMATE",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CHOJATA",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "COALAQUE",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "ICHUÑA",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "LA CAPILLA",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "LLOQUE",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "MATALAQUE",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "PUQUINA",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "09",
      des_dis: "QUINISTAQUILLAS",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "UBINAS",
    },
    {
      cod_dep: "17",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "YUNGA",
    },
    {
      cod_dep: "17",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "ILO",
    },
    {
      cod_dep: "17",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "EL ALGARROBAL",
    },
    {
      cod_dep: "17",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "PACOCHA",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "CHAUPIMARCA",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "HUACHON",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "HUARIACA",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "HUAYLLAY",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "NINACACA",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "PALLANCHACRA",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "PAUCARTAMBO",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "SAN FRANCISCO DE ASIS DE YARUSYACAN",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "SIMON BOLIVAR",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "TICLACAYAN",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "TINYAHUARCO",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "VICCO",
    },
    {
      cod_dep: "18",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "YANACANCHA",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "YANAHUANCA",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "CHACAYAN",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "GOYLLARISQUIZGA",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "PAUCAR",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "SAN PEDRO DE PILLAO",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "SANTA ANA DE TUSI",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "TAPUC",
    },
    {
      cod_dep: "18",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "VILCABAMBA",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "OXAPAMPA",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "CHONTABAMBA",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "HUANCABAMBA",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "PALCAZU",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "POZUZO",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "PUERTO BERMUDEZ",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "VILLA RICA",
    },
    {
      cod_dep: "18",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "CONSTITUCION",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "PIURA",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "CASTILLA",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CATACAOS",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "CURA MORI",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "EL TALLAN",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "LA ARENA",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "LA UNION",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "LAS LOMAS",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "TAMBO GRANDE",
    },
    {
      cod_dep: "19",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "VEINTISEIS DE OCTUBRE",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "AYABACA",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "FRIAS",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "09",
      des_dis: "JILILI",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "LAGUNAS",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "MONTERO",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "PACAIPAMPA",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "PAIMAS",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "SAPILLICA",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "SICCHEZ",
    },
    {
      cod_dep: "19",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "SUYO",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "HUANCABAMBA",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "CANCHAQUE",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "EL CARMEN DE LA FRONTERA",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "HUARMACA",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "LALAQUIZ",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "SAN MIGUEL DE EL FAIQUE",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "SONDOR",
    },
    {
      cod_dep: "19",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "SONDORILLO",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "CHULUCANAS",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "BUENOS AIRES",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "CHALACO",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "08",
      des_dis: "LA MATANZA",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "MORROPON",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "SALITRAL",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "SAN JUAN DE BIGOTE",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "SANTA CATALINA DE MOSSA",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "SANTO DOMINGO",
    },
    {
      cod_dep: "19",
      cod_pro: "04",
      cod_dis: "09",
      des_dis: "YAMANGO",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "PAITA",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "AMOTAPE",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "ARENAL",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "COLAN",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "LA HUACA",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "TAMARINDO",
    },
    {
      cod_dep: "19",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "VICHAYAL",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "SULLANA",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "BELLAVISTA",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "IGNACIO ESCUDERO",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "LANCONES",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "MARCAVELICA",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "MIGUEL CHECA",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "QUERECOTILLO",
    },
    {
      cod_dep: "19",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "SALITRAL",
    },
    {
      cod_dep: "19",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "PARIÑAS",
    },
    {
      cod_dep: "19",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "EL ALTO",
    },
    {
      cod_dep: "19",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "LA BREA",
    },
    {
      cod_dep: "19",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "LOBITOS",
    },
    {
      cod_dep: "19",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "LOS ORGANOS",
    },
    {
      cod_dep: "19",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "MANCORA",
    },
    {
      cod_dep: "19",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "SECHURA",
    },
    {
      cod_dep: "19",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "BELLAVISTA DE LA UNION",
    },
    {
      cod_dep: "19",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "BERNAL",
    },
    {
      cod_dep: "19",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "CRISTO NOS VALGA",
    },
    {
      cod_dep: "19",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "VICE",
    },
    {
      cod_dep: "19",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "RINCONADA LLICUAR",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "PUNO",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "ACORA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "15",
      des_dis: "AMANTANI",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "ATUNCOLLA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CAPACHICA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "CHUCUITO",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "COATA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "HUATA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "MAÑAZO",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "PAUCARCOLLA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "PICHACANI",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "PLATERIA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "SAN ANTONIO",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "TIQUILLACA",
    },
    {
      cod_dep: "20",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "VILQUE",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "AZANGARO",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "ACHAYA",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "ARAPA",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "ASILLO",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "CAMINACA",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "CHUPA",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "JOSE DOMINGO CHOQUEHUANCA",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "08",
      des_dis: "MUÑANI",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "POTONI",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "12",
      des_dis: "SAMAN",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "13",
      des_dis: "SAN ANTON",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "14",
      des_dis: "SAN JOSE",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "15",
      des_dis: "SAN JUAN DE SALINAS",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "16",
      des_dis: "SANTIAGO DE PUPUJA",
    },
    {
      cod_dep: "20",
      cod_pro: "02",
      cod_dis: "17",
      des_dis: "TIRAPATA",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "MACUSANI",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "AJOYANI",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "AYAPATA",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "COASA",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "CORANI",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "CRUCERO",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "ITUATA",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "08",
      des_dis: "OLLACHEA",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "09",
      des_dis: "SAN GABAN",
    },
    {
      cod_dep: "20",
      cod_pro: "03",
      cod_dis: "10",
      des_dis: "USICAYOS",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "JULI",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "DESAGUADERO",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "HUACULLANI",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      cod_dis: "12",
      des_dis: "KELLUYO",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "PISACOMA",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      cod_dis: "07",
      des_dis: "POMATA",
    },
    {
      cod_dep: "20",
      cod_pro: "04",
      cod_dis: "10",
      des_dis: "ZEPITA",
    },
    {
      cod_dep: "20",
      cod_pro: "12",
      cod_dis: "01",
      des_dis: "ILAVE",
    },
    {
      cod_dep: "20",
      cod_pro: "12",
      cod_dis: "04",
      des_dis: "CAPAZO",
    },
    {
      cod_dep: "20",
      cod_pro: "12",
      cod_dis: "02",
      des_dis: "PILCUYO",
    },
    {
      cod_dep: "20",
      cod_pro: "12",
      cod_dis: "03",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "20",
      cod_pro: "12",
      cod_dis: "05",
      des_dis: "CONDURIRI",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "HUANCANE",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "COJATA",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "11",
      des_dis: "HUATASANI",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "INCHUPALLA",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "PUSI",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "ROSASPATA",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "TARACO",
    },
    {
      cod_dep: "20",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "VILQUE CHICO",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "LAMPA",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "CABANILLA",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "03",
      des_dis: "CALAPUJA",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "NICASIO",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "05",
      des_dis: "OCUVIRI",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "PALCA",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "PARATIA",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "PUCARA",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "SANTA LUCIA",
    },
    {
      cod_dep: "20",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "VILAVILA",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "AYAVIRI",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "ANTAUTA",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "CUPI",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "LLALLI",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "MACARI",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "NUÑOA",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "07",
      des_dis: "ORURILLO",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "08",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "20",
      cod_pro: "07",
      cod_dis: "09",
      des_dis: "UMACHIRI",
    },
    {
      cod_dep: "20",
      cod_pro: "13",
      cod_dis: "01",
      des_dis: "MOHO",
    },
    {
      cod_dep: "20",
      cod_pro: "13",
      cod_dis: "02",
      des_dis: "CONIMA",
    },
    {
      cod_dep: "20",
      cod_pro: "13",
      cod_dis: "04",
      des_dis: "HUAYRAPATA",
    },
    {
      cod_dep: "20",
      cod_pro: "13",
      cod_dis: "03",
      des_dis: "TILALI",
    },
    {
      cod_dep: "20",
      cod_pro: "11",
      cod_dis: "01",
      des_dis: "PUTINA",
    },
    {
      cod_dep: "20",
      cod_pro: "11",
      cod_dis: "04",
      des_dis: "ANANEA",
    },
    {
      cod_dep: "20",
      cod_pro: "11",
      cod_dis: "02",
      des_dis: "PEDRO VILCA APAZA",
    },
    {
      cod_dep: "20",
      cod_pro: "11",
      cod_dis: "03",
      des_dis: "QUILCAPUNCU",
    },
    {
      cod_dep: "20",
      cod_pro: "11",
      cod_dis: "05",
      des_dis: "SINA",
    },
    {
      cod_dep: "20",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "JULIACA",
    },
    {
      cod_dep: "20",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "CABANA",
    },
    {
      cod_dep: "20",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "CABANILLAS",
    },
    {
      cod_dep: "20",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "CARACOTO",
    },
    {
      cod_dep: "20",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "SAN MIGUEL",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "SANDIA",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "CUYOCUYO",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "LIMBANI",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "06",
      des_dis: "PATAMBUCO",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "PHARA",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "07",
      des_dis: "QUIACA",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "08",
      des_dis: "SAN JUAN DEL ORO",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "10",
      des_dis: "YANAHUAYA",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "11",
      des_dis: "ALTO INAMBARI",
    },
    {
      cod_dep: "20",
      cod_pro: "08",
      cod_dis: "12",
      des_dis: "SAN PEDRO DE PUTINA PUNCO",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "YUNGUYO",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "ANAPIA",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "COPANI",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "CUTURAPI",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      cod_dis: "06",
      des_dis: "OLLARAYA",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      cod_dis: "07",
      des_dis: "TINICACHI",
    },
    {
      cod_dep: "20",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "UNICACHI",
    },
    {
      cod_dep: "21",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "MOYOBAMBA",
    },
    {
      cod_dep: "21",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CALZADA",
    },
    {
      cod_dep: "21",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "HABANA",
    },
    {
      cod_dep: "21",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "JEPELACIO",
    },
    {
      cod_dep: "21",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "SORITOR",
    },
    {
      cod_dep: "21",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "YANTALO",
    },
    {
      cod_dep: "21",
      cod_pro: "07",
      cod_dis: "01",
      des_dis: "BELLAVISTA",
    },
    {
      cod_dep: "21",
      cod_pro: "07",
      cod_dis: "04",
      des_dis: "ALTO BIAVO",
    },
    {
      cod_dep: "21",
      cod_pro: "07",
      cod_dis: "06",
      des_dis: "BAJO BIAVO",
    },
    {
      cod_dep: "21",
      cod_pro: "07",
      cod_dis: "05",
      des_dis: "HUALLAGA",
    },
    {
      cod_dep: "21",
      cod_pro: "07",
      cod_dis: "03",
      des_dis: "SAN PABLO",
    },
    {
      cod_dep: "21",
      cod_pro: "07",
      cod_dis: "02",
      des_dis: "SAN RAFAEL",
    },
    {
      cod_dep: "21",
      cod_pro: "10",
      cod_dis: "01",
      des_dis: "SAN JOSE DE SISA",
    },
    {
      cod_dep: "21",
      cod_pro: "10",
      cod_dis: "02",
      des_dis: "AGUA BLANCA",
    },
    {
      cod_dep: "21",
      cod_pro: "10",
      cod_dis: "04",
      des_dis: "SAN MARTIN",
    },
    {
      cod_dep: "21",
      cod_pro: "10",
      cod_dis: "05",
      des_dis: "SANTA ROSA",
    },
    {
      cod_dep: "21",
      cod_pro: "10",
      cod_dis: "03",
      des_dis: "SHATOJA",
    },
    {
      cod_dep: "21",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "SAPOSOA",
    },
    {
      cod_dep: "21",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "ALTO SAPOSOA",
    },
    {
      cod_dep: "21",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "EL ESLABON",
    },
    {
      cod_dep: "21",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "PISCOYACU",
    },
    {
      cod_dep: "21",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "SACANCHE",
    },
    {
      cod_dep: "21",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "TINGO DE SAPOSOA",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "LAMAS",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "15",
      des_dis: "ALONSO DE ALVARADO",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "BARRANQUITA",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "CAYNARACHI",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "05",
      des_dis: "CUÑUMBUQUI",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "06",
      des_dis: "PINTO RECODO",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "07",
      des_dis: "RUMISAPA",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "16",
      des_dis: "SAN ROQUE DE CUMBAZA",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "11",
      des_dis: "SHANAO",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "13",
      des_dis: "TABALOSOS",
    },
    {
      cod_dep: "21",
      cod_pro: "03",
      cod_dis: "14",
      des_dis: "ZAPATERO",
    },
    {
      cod_dep: "21",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "JUANJUI",
    },
    {
      cod_dep: "21",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "CAMPANILLA",
    },
    {
      cod_dep: "21",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "HUICUNGO",
    },
    {
      cod_dep: "21",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "PACHIZA",
    },
    {
      cod_dep: "21",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "PAJARILLO",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "01",
      des_dis: "PICOTA",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "02",
      des_dis: "BUENOS AIRES",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "03",
      des_dis: "CASPISAPA",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "04",
      des_dis: "PILLUANA",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "05",
      des_dis: "PUCACACA",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "06",
      des_dis: "SAN CRISTOBAL",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "07",
      des_dis: "SAN HILARION",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "10",
      des_dis: "SHAMBOYACU",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "08",
      des_dis: "TINGO DE PONASA",
    },
    {
      cod_dep: "21",
      cod_pro: "09",
      cod_dis: "09",
      des_dis: "TRES UNIDOS",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "01",
      des_dis: "RIOJA",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "09",
      des_dis: "AWAJUN",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "06",
      des_dis: "ELIAS SOPLIN VARGAS",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "05",
      des_dis: "NUEVA CAJAMARCA",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "08",
      des_dis: "PARDO MIGUEL",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "02",
      des_dis: "POSIC",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "07",
      des_dis: "SAN FERNANDO",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "03",
      des_dis: "YORONGOS",
    },
    {
      cod_dep: "21",
      cod_pro: "05",
      cod_dis: "04",
      des_dis: "YURACYACU",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "01",
      des_dis: "TARAPOTO",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "02",
      des_dis: "ALBERTO LEVEAU",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "04",
      des_dis: "CACATACHI",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "06",
      des_dis: "CHAZUTA",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "07",
      des_dis: "CHIPURANA",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "08",
      des_dis: "EL PORVENIR",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "09",
      des_dis: "HUIMBAYOC",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "10",
      des_dis: "JUAN GUERRA",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "21",
      des_dis: "LA BANDA DE SHILCAYO",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "11",
      des_dis: "MORALES",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "12",
      des_dis: "PAPAPLAYA",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "16",
      des_dis: "SAN ANTONIO",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "19",
      des_dis: "SAUCE",
    },
    {
      cod_dep: "21",
      cod_pro: "06",
      cod_dis: "20",
      des_dis: "SHAPAJA",
    },
    {
      cod_dep: "21",
      cod_pro: "08",
      cod_dis: "01",
      des_dis: "TOCACHE",
    },
    {
      cod_dep: "21",
      cod_pro: "08",
      cod_dis: "02",
      des_dis: "NUEVO PROGRESO",
    },
    {
      cod_dep: "21",
      cod_pro: "08",
      cod_dis: "03",
      des_dis: "POLVORA",
    },
    {
      cod_dep: "21",
      cod_pro: "08",
      cod_dis: "04",
      des_dis: "SHUNTE",
    },
    {
      cod_dep: "21",
      cod_pro: "08",
      cod_dis: "05",
      des_dis: "UCHIZA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "TACNA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "11",
      des_dis: "ALTO DE LA ALIANZA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CALANA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "12",
      des_dis: "CIUDAD NUEVA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "INCLAN",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "PACHIA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "08",
      des_dis: "PALCA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "09",
      des_dis: "POCOLLAY",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "10",
      des_dis: "SAMA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "13",
      des_dis: "CORONEL GREGORIO ALBARRACIN LANCHIPA",
    },
    {
      cod_dep: "22",
      cod_pro: "01",
      cod_dis: "14",
      des_dis: "LA YARADA LOS PALOS",
    },
    {
      cod_dep: "22",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "CANDARAVE",
    },
    {
      cod_dep: "22",
      cod_pro: "04",
      cod_dis: "02",
      des_dis: "CAIRANI",
    },
    {
      cod_dep: "22",
      cod_pro: "04",
      cod_dis: "06",
      des_dis: "CAMILACA",
    },
    {
      cod_dep: "22",
      cod_pro: "04",
      cod_dis: "03",
      des_dis: "CURIBAYA",
    },
    {
      cod_dep: "22",
      cod_pro: "04",
      cod_dis: "04",
      des_dis: "HUANUARA",
    },
    {
      cod_dep: "22",
      cod_pro: "04",
      cod_dis: "05",
      des_dis: "QUILAHUANI",
    },
    {
      cod_dep: "22",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "LOCUMBA",
    },
    {
      cod_dep: "22",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "ILABAYA",
    },
    {
      cod_dep: "22",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "ITE",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "TARATA",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "CHUCATAMANI",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "06",
      des_dis: "ESTIQUE",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "07",
      des_dis: "ESTIQUE-PAMPA",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "10",
      des_dis: "SITAJARA",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "11",
      des_dis: "SUSAPAYA",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "12",
      des_dis: "TARUCACHI",
    },
    {
      cod_dep: "22",
      cod_pro: "02",
      cod_dis: "13",
      des_dis: "TICACO",
    },
    {
      cod_dep: "23",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "TUMBES",
    },
    {
      cod_dep: "23",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "CORRALES",
    },
    {
      cod_dep: "23",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "LA CRUZ",
    },
    {
      cod_dep: "23",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "PAMPAS DE HOSPITAL",
    },
    {
      cod_dep: "23",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "SAN JACINTO",
    },
    {
      cod_dep: "23",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "SAN JUAN DE LA VIRGEN",
    },
    {
      cod_dep: "23",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "ZORRITOS",
    },
    {
      cod_dep: "23",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "CASITAS",
    },
    {
      cod_dep: "23",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CANOAS DE PUNTA SAL",
    },
    {
      cod_dep: "23",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "ZARUMILLA",
    },
    {
      cod_dep: "23",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "AGUAS VERDES",
    },
    {
      cod_dep: "23",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "MATAPALO",
    },
    {
      cod_dep: "23",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "PAPAYAL",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      cod_dis: "01",
      des_dis: "CALLERIA",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      cod_dis: "04",
      des_dis: "CAMPOVERDE",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      cod_dis: "05",
      des_dis: "IPARIA",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      cod_dis: "03",
      des_dis: "MASISEA",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      cod_dis: "02",
      des_dis: "YARINACOCHA",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      cod_dis: "06",
      des_dis: "NUEVA REQUENA",
    },
    {
      cod_dep: "25",
      cod_pro: "01",
      cod_dis: "07",
      des_dis: "MANANTAY",
    },
    {
      cod_dep: "25",
      cod_pro: "03",
      cod_dis: "01",
      des_dis: "RAYMONDI",
    },
    {
      cod_dep: "25",
      cod_pro: "03",
      cod_dis: "04",
      des_dis: "SEPAHUA",
    },
    {
      cod_dep: "25",
      cod_pro: "03",
      cod_dis: "02",
      des_dis: "TAHUANIA",
    },
    {
      cod_dep: "25",
      cod_pro: "03",
      cod_dis: "03",
      des_dis: "YURUA",
    },
    {
      cod_dep: "25",
      cod_pro: "02",
      cod_dis: "01",
      des_dis: "PADRE ABAD",
    },
    {
      cod_dep: "25",
      cod_pro: "02",
      cod_dis: "02",
      des_dis: "IRAZOLA",
    },
    {
      cod_dep: "25",
      cod_pro: "02",
      cod_dis: "03",
      des_dis: "CURIMANA",
    },
    {
      cod_dep: "25",
      cod_pro: "02",
      cod_dis: "04",
      des_dis: "NESHUYA",
    },
    {
      cod_dep: "25",
      cod_pro: "02",
      cod_dis: "05",
      des_dis: "ALEXANDER VON HUMBOLDT",
    },
    {
      cod_dep: "25",
      cod_pro: "04",
      cod_dis: "01",
      des_dis: "PURUS",
    },
  ]

  getDepartamento() {
    return this.departamento
  }

  getProvincia(cod_dep = "01") {
    return this.provincia.filter((provincia) => provincia.cod_dep == cod_dep)
  }

  getDistrito(cod_dep = "", cod_pro = "") {
    return this.distrito.filter(
      (distrito) => distrito.cod_dep == cod_dep && distrito.cod_pro == cod_pro
    )
  }
}

const ubigeo = new Ubigeo()
export default ubigeo
