<script>
  import { navigate } from "svelte-routing"
  export let empresas

  const cargarEmpresa = (ruc) => {
    // setTimeout(function () {
    //   navigate(`/empresa/${ruc}`, { replace: true })
    // }, 2000)
    navigate(`/empresa/${ruc}`, { replace: true })
  }
</script>

<div class="table-responsive small">
  <table class="table table-striped table-sm table-hover table-pointer">
    <thead>
      <tr>
        <th class="text-secondary fw-normal">Ruc</th>
        <th class="text-secondary fw-normal">Razon Social</th>
        <th class="text-secondary fw-normal" />
      </tr>
    </thead>
    <tbody>
      <tr on:click={() => cargarEmpresa(empresas.ruc)}>
        <td>{empresas.ruc}</td>
        <td>{empresas.razonsocial}</td>
        <td class="text-end text-primary">
          <i class="bi bi-search" />
        </td>
      </tr>
    </tbody>
  </table>
</div>
