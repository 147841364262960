<script>
  export let politicos = []
  const encabezados = [
    "Cargo",
    "Departamento",
    "Provincia",
    "Distrito",
    "Organización Política",
    "Elecciones",
    "Tipo",
  ]
</script>

<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        {#each encabezados as encabezado}
          <th class="text-secondary fw-normal">{encabezado}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each politicos as politico}
        <tr>
          <td>{politico.cargo}</td>
          <td>{politico.departamento}</td>
          <td>{politico.provincia}</td>
          <td>{politico.distrito}</td>
          <td>{politico.organizacionpolitica}</td>
          <td>{politico.elecciones}</td>
          <td>{politico.tipo}</td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
